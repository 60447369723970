import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useLocation } from "react-router-dom"
import Return from '../components/Forum/Return'
import SearchBar from '../components/Forum/SearchBar'
import { ForumContext } from './ForumData'
import AuthContext from '../context/AuthProvider'
import StarIcon from "../images/star_big.svg"
import StarMediumIcon from "../images/star_medium.svg"
import { Link } from "react-router-dom"
import Button from '../components/Button'
import Ranking from '../components/Ranking'
import { registerAction } from '../api';

export default function Reputation() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  const { notifications, reputation, ranking, rankingPosition } = React.useContext(ForumContext)
  const { cookies, auth } = React.useContext(AuthContext);

  const [showReputationText, setShowReputationText] = React.useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  
    React.useEffect(() => {
      if (!cookies.get("userId")) {
        navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
      }
      
      if (cookies.get("userId")) {
        registerAction(+cookies.get("userId"), "forumReputationPage", auth, cookies)
      }
    }, [])

  if (reputation===null || !ranking || ranking.length === 0) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }
  
  return (
    <div className="top-margin-s">
      <SearchBar tabletBreakpoint={tabletBreakpoint} notifications={notifications}/>
      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s">
          <Return to={(location?.state?.search) ? `${location.state.search}` : '/forum'}/>
        </div>
        
        <p className="florence-title">
          Reputação 
          &nbsp; 
          &nbsp; 
          <Link className="svg-btn" to="/forum/reputation">
            <img src={StarIcon} alt="Pontuação de reputação"/>
            <span className="heading2 sans-serif-typeface">{' ' + reputation}</span>
          </Link>
        </p>
        <p className="body secondary-color top-margin-s">
          A sua reputação mostra o quanto você contribuiu para nossa comunidade
        </p>

        <div className="top-margin-s">
          <Button type="tertiary" onClick={()=>{setShowReputationText(prev=>!prev)}}>
            Como ganhar pontos de reputação
          </Button>
          { (showReputationText) ?
          <p className="body secondary-color top-margin-xs">
            Cada vez que alguém acha útil uma pergunta ou resposta sua, você ganha um ponto de reputação
          </p> : null }
        </div>

        <div className="calendar-history top-margin-m bottom-margin-m">
          <Ranking 
            title="Ranking de Reputação" 
            icon={StarMediumIcon} 
            participants={ranking}
            userId={+cookies.get("userId")}
            userPosition={rankingPosition}
            userFirstName={cookies.get("userName")}
            userLastName={cookies.get("userLastName")}
            userScore={reputation}
          />
        </div>
      </div>
    </div>
  )
}
