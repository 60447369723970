import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Layout from './components/Layouts';
import Home from './pages/Home'
import HeadlessLayout from './pages/HeadlessLayout'
import ProductChoice from './pages/ProductChoice'
import About from './pages/About'
import Login from './pages/Login'
import Register from './pages/Register'
import RegisterPart2 from './pages/RegisterPart2'
import RegisterSuccessful from './pages/RegisterSuccessful'
import Testing from './pages/Testing'
import FreshTesting from './pages/FreshTesting'
import AuthenticatedUser from './AuthenticatedUser'
//import PortalLayout from './portal/PortalLayout'
//import UserPortal from './portal/UserPortal'

import LoginRequired from './pages/LoginRequired'
import Registration from './pages/Registration'
import Family from './portal/Family'
//import Profile from './portal/Profile'
//import Exercise from './portal/Exercise'
//import Eating from './portal/Eating'
//import Health from './portal/Health'

import UserProfile from './portal/UserProfile'
import UserEating from './portal/UserEating'
import UserExercise from './portal/UserExercise'
import UserHealth from './portal/UserHealth'
import UserAdherence from './portal/HealthAdherence'

import ForumData from './forum/ForumData'
import Forum from './forum/Forum'
import Post from './forum/Post'
import Saved from './forum/Saved'
import SavedForLater from './forum/SavedForLater'
import Write from './forum/Write'
import LogUserOut from './portal/LogUserOut';
import AddFamilyMember from './portal/AddFamilyMember';
import ManageFamily from './portal/ManageFamily';
import ExamPreview from './portal/ExamPreview'
import Policy from './pages/Policy';
import NotFoundPage from './pages/NotFoundPage';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import DocumentCheckPage from './portal/DocumentCheckPage';
import DocumentView from './portal/DocumentView'
import HealthCardPreview from './portal/HealthCardPreview';
import SubmitImage from './portal/SubmitImage';
import AccessShared from './pages/AccessShared';

import Post122 from './forum/post/122-diabetes'
import Post134 from './forum/post/134-diabetes'
import Post87 from './forum/post/87-diabetes'
import Post116 from './forum/post/116-diabetes'
import Post99 from './forum/post/99-diabetes'
import Post100 from './forum/post/100-diabetes'
import Post138 from './forum/post/138-diabetes'
import Post93 from './forum/post/93-diabetes'
import Post11 from './forum/post/11-diabetes'
import Post142 from './forum/post/142-diabetes'
import Post137 from './forum/post/137-diabetes'
import Post83 from './forum/post/83-diabetes'
import Post118 from './forum/post/118-diabetes'
import Post114 from './forum/post/114-diabetes'
import Post72 from './forum/post/72-diabetes'
import Post56 from './forum/post/56-diabetes'
import Post71 from './forum/post/71-diabetes'
import Post112 from './forum/post/112-diabetes'
import Post60 from './forum/post/60-diabetes'
import Post144 from './forum/post/144-diabetes'
import Post132 from './forum/post/132-diabetes'
import Post141 from './forum/post/141-diabetes'
import Post10 from './forum/post/10-diabetes'
import Post108 from './forum/post/108-diabetes'
import Post135 from './forum/post/135-diabetes'
import Post41 from './forum/post/41-diabetes'
import Post125 from './forum/post/125-diabetes'
import Post126 from './forum/post/126-diabetes'
import Post148 from './forum/post/148-diabetes'
import Post117 from './forum/post/117-diabetes'
import Post131 from './forum/post/131-diabetes'
import Post151 from './forum/post/151-diabetes'
import Post146 from './forum/post/146-diabetes'
import Post22 from './forum/post/22-diabetes'
import Post18 from './forum/post/18-diabetes'
import Post140 from './forum/post/140-diabetes'
import Post96 from './forum/post/96-diabetes'
import Post21 from './forum/post/21-diabetes'
import Post77 from './forum/post/77-diabetes'
import Post70 from './forum/post/70-diabetes'
import Post27 from './forum/post/27-diabetes'
import Post97 from './forum/post/97-diabetes'
import Post28 from './forum/post/28-diabetes'
import Post98 from './forum/post/98-diabetes'
import Post20 from './forum/post/20-diabetes'
import Post76 from './forum/post/76-diabetes'
import Post150 from './forum/post/150-diabetes'
import Post115 from './forum/post/115-diabetes'
import Post89 from './forum/post/89-diabetes'
import Post45 from './forum/post/45-diabetes'
import Post127 from './forum/post/127-diabetes'
import Post147 from './forum/post/147-diabetes'
import Post80 from './forum/post/80-diabetes'
import Post24 from './forum/post/24-diabetes'
import Post92 from './forum/post/92-diabetes'
import Post101 from './forum/post/101-diabetes'
import Post25 from './forum/post/25-diabetes'
import Post23 from './forum/post/23-diabetes'
import Post94 from './forum/post/94-diabetes'
import Post17 from './forum/post/17-diabetes'
import Post58 from './forum/post/58-diabetes'
import Post19 from './forum/post/19-diabetes'
import Post121 from './forum/post/121-diabetes'
import Post81 from './forum/post/81-diabetes'
import Post38 from './forum/post/38-pressao'
import Post40 from './forum/post/40-pressao'
import Post35 from './forum/post/35-pressao'
import Post59 from './forum/post/59-pressao'
import Post73 from './forum/post/73-pressao'
import Post105 from './forum/post/105-pressao'
import Post106 from './forum/post/106-pressao'
import Post36 from './forum/post/36-pressao'
import Post133 from './forum/post/133-pressao'
import Post79 from './forum/post/79-pressao'
import Post33 from './forum/post/33-pressao'
import Post128 from './forum/post/128-pressao'
import Post55 from './forum/post/55-pressao'
import Post37 from './forum/post/37-pressao'
import Post31 from './forum/post/31-colesterol'
import Post34 from './forum/post/34-colesterol'
import Post65 from './forum/post/65-colesterol'
import Post103 from './forum/post/103-colesterol'
import Post63 from './forum/post/63-colesterol'
import Post30 from './forum/post/30-colesterol'
import Post61 from './forum/post/61-colesterol'
import Post74 from './forum/post/74-colesterol'
import Post78 from './forum/post/78-colesterol'
import Post32 from './forum/post/32-colesterol'
import Post62 from './forum/post/62-colesterol'
import Post68 from './forum/post/68-colesterol'
import Post104 from './forum/post/104-colesterol'
import Post66 from './forum/post/66-colesterol'
import Post46 from './forum/post/46-colesterol'
import Post111 from './forum/post/111-cancer'
import Post129 from './forum/post/129-cancer'
import Post149 from './forum/post/149-cancer'
import Post54 from './forum/post/54-cancer'
import Post52 from './forum/post/52-cancer'
import Post136 from './forum/post/136-cancer'
import Post124 from './forum/post/124-cancer'
import Post143 from './forum/post/143-cancer'
import Post130 from './forum/post/130-cancer'
import Post86 from './forum/post/86-cancer'
import Post51 from './forum/post/51-cancer'
import Post50 from './forum/post/50-cancer'
import Post48 from './forum/post/48-cancer'
import Post67 from './forum/post/67-cancer'
import Post95 from './forum/post/95-cancer'
import Post145 from './forum/post/145-cancer'
import Post109 from './forum/post/109-cancer'
import Post49 from './forum/post/49-cancer'
import Post84 from './forum/post/84-cancer'
import Post82 from './forum/post/82-cancer'
import Post47 from './forum/post/47-cancer'
import Post91 from './forum/post/91-cancer'
import Post53 from './forum/post/53-cancer'
import Post110 from './forum/post/110-cancer'
import Post6 from './forum/post/6-geral'
import Post102 from './forum/post/102-geral'
import Post7 from './forum/post/7-geral'
import Post139 from './forum/post/139-geral'
import Post13 from './forum/post/13-geral'
import Post3 from './forum/post/3-geral'
import Post5 from './forum/post/5-geral'
import Post4 from './forum/post/4-geral'
import Post9 from './forum/post/9-geral'
import Post12 from './forum/post/12-geral'
import Post2 from './forum/post/2-geral'
import SharedHealth from './portal/SharedHealth';
import LogSharedUserOut from './portal/LogSharedUserOut';
import SubmitExercise from './portal/SubmitExercise';
import EatingHighlight from './portal/EatingHighlight';
import ExerciseHighlight from './portal/ExerciseHighlight';
import UserExerciseHistory from './portal/UserExerciseHistory';
import UserEatingHistory from './portal/UserEatingHistory';
import WeightLossObjective from './components/Eating/WeightLossObjective';
import CholesterolControlObjective from './components/Eating/CholesterolControlObjective';
import HypertensionControlObjective from './components/Eating/HypertensionControlObjective';
import HypertrophyObjective from './components/Eating/HypetrophyObjective';
import HeartHealthObjective from './components/Eating/HeartHealthObjective';
import UserAccount from './portal/UserAccount';
import Tutorial from './portal/Tutorial';
import Logo from './pages/Logo';
import LogoTitle from './pages/LogoTitle';
import Reputation from './forum/Reputation';
import Help from './forum/Help';
import UserHistory from './portal/UserHistory';
import UserObjectives from './portal/UserObjectives';
import WaitRecalculate from './portal/WaitRecalculate';
import UserLeague from './portal/UserLeague';
import LeagueUpdate from './portal/LeagueUpdate';
import WeeklyChallenge from './portal/WeeklyChallenge';
import UserSavedMessages from './portal/UserSavedMessages';

function App() {

//          <Route path="familytoshow" element={<Family />} />
//          <Route path="exercisetoshow" element={<Exercise />} />
//          <Route path='eatingtoshow' element={<Eating />} />
//          <Route path='healthtoshow' element={<Health />} />
//         <Route path="profiletoshow" element={<Profile />} />

//          <Route element={<HeadlessLayout />} >
//            <Route path="productchoice" element={<ProductChoice />} />
//            <Route path="register" element={<Register />} />
//            <Route path="register/2" element={<RegisterPart2 />} />
//            <Route path="register/success" element={<RegisterSuccessful />} />
//            <Route path="login" element={<Login />} />
//          </Route>

//            <Route path="exercise" element={<Exercise />} />
//            <Route path='eating' element={<Eating />} />
//            <Route path='health' element={<Health />} />
//            <Route path="profile" element={<Profile />} />

//            <Route path="testing" element={<Testing />} />
//            <Route path="freshtesting" element={<FreshTesting />} />
//            <Route element={<PortalLayout />}>
//              <Route path="userportal" element={<UserPortal />} />
//            </Route>

  const TRACKING_ID = "AW-16672392563"
  ReactGA.initialize(TRACKING_ID);

  //            <Route path='eating/objective/cholesterolcontrol' element={<CholesterolControlObjective />} />
  //            <Route path='eating/objective/hypertensioncontrol' element={<HypertensionControlObjective />} />
  //            <Route path='eating/objective/hearthealth' element={<HeartHealthObjective />} />

  return (
      <>
      <Helmet>
        <title>Florence Health</title>
        <meta 
          name="description" 
          content="Desenvolva hábitos saudáveis, acompanhe sua prática de alimentação e exercícios físicos, e cuide de sua saúde com a Florence"/>
        <meta name="keywords" content="alimentação, exercícios físicos, hábitos, objetivos, saúde, comunidade, fórum"/>
      </Helmet>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="loginrequired" element={<LoginRequired />} />
          <Route path="accessshared" element={<AccessShared />} />
          <Route path="about" element={<About />} />
          <Route path="policy" element={<Policy />} />
          <Route path="registration" element={<Registration />} />
          <Route path="loguserout" element={<LogUserOut />} />

          <Route path="sharedhealth" element={<SharedHealth />} />
          <Route path="logshareduserout" element={<LogSharedUserOut />} />

          <Route path="logotext" element={<Home />} />
          <Route path="logo" element={<Logo />} />
          <Route path="logotitle" element={<LogoTitle />} />
          
          <Route element={<ForumData />} >
            <Route path='forum' element={<Forum />} />
            <Route path='forum/:id' element={<Post />} />
            <Route path='forum/saved' element={<Saved />} >
              <Route index element={<SavedForLater type="saved_post"/>} />
              <Route path='useful' element={<SavedForLater type="found_useful"/>} />
              <Route path='commented' element={<SavedForLater type="commented"/>} />
            </Route>
            <Route path='forum/myposts' element={<Saved myPosts={true}/>} > 
              <Route index element={<SavedForLater type="myposts"/>} />
            </Route>
            <Route path='forum/write' element={<Write />} />
            <Route path='forum/reputation' element={<Reputation />} />

            <Route element={<AuthenticatedUser />}>
              <Route path="healthcard" element={<HealthCardPreview />} />
              <Route path="family" element={<Family />} />
              <Route path="family/add" element={<AddFamilyMember />} />
              <Route path="family/manage" element={<ManageFamily />} />
              <Route path="tutorial" element={<Tutorial />} />
              <Route path="profile" element={<UserProfile />} />
              <Route path="messages" element={<UserSavedMessages />} />
              <Route path="profile/history" element={<UserHistory />} />
              <Route path="profile/league" element={<UserLeague />} />
              <Route path="profile/objectives" element={<UserObjectives />} />
              <Route path="account" element={<UserAccount />} />
              <Route path="rankingupdate" element={<LeagueUpdate />} />
              <Route path="weeklychallenge" element={<WeeklyChallenge />} />
              <Route path="exercise" element={<UserExercise />} />
              <Route path="exercise/history" element={<UserExerciseHistory />} />
              <Route path="exercise/submit" element={<SubmitExercise />} />
              <Route path="exercise/highlight" element={<ExerciseHighlight />} />
              <Route path='eating' element={<UserEating />} />
              <Route path='eating/history' element={<UserEatingHistory/>} />
              <Route path='eating/submit' element={<SubmitImage /> } />
              <Route path='eating/recalculate' element={<WaitRecalculate /> } />
              <Route path='eating/highlight' element={<EatingHighlight /> } />
              <Route path='eating/objective/weigthloss' element={<WeightLossObjective />} />
              <Route path='eating/objective/hypertrophy' element={<HypertrophyObjective />} />
              <Route path='health' element={<UserHealth />} />
              <Route path='health/adherence' element={<UserAdherence/>} />
              <Route path='exam/check/:id' element={<DocumentCheckPage documentType="exam" />} />
              <Route path='exam/:id' element={<ExamPreview />} />
              <Route path='prescription/check/:id' element={<DocumentCheckPage documentType="prescription" />} />
              <Route path='prescription/:id' element={<DocumentView documentType="prescription" />} />
              <Route path='vaccine/:id' element={<DocumentView documentType="vaccine" />} />
              <Route path='help' element={<Help />} />
            </Route> 
          </Route>
        
          <Route path="*" element={<NotFoundPage />} />

          <Route path="post">
            <Route path='122-diabetes' element={<Post122/>} />
            <Route path='134-diabetes' element={<Post134/>} />
            <Route path='87-diabetes' element={<Post87/>} />
            <Route path='116-diabetes' element={<Post116/>} />
            <Route path='99-diabetes' element={<Post99/>} />
            <Route path='100-diabetes' element={<Post100/>} />
            <Route path='138-diabetes' element={<Post138/>} />
            <Route path='93-diabetes' element={<Post93/>} />
            <Route path='11-diabetes' element={<Post11/>} />
            <Route path='142-diabetes' element={<Post142/>} />
            <Route path='137-diabetes' element={<Post137/>} />
            <Route path='83-diabetes' element={<Post83/>} />
            <Route path='118-diabetes' element={<Post118/>} />
            <Route path='114-diabetes' element={<Post114/>} />
            <Route path='72-diabetes' element={<Post72/>} />
            <Route path='56-diabetes' element={<Post56/>} />
            <Route path='71-diabetes' element={<Post71/>} />
            <Route path='112-diabetes' element={<Post112/>} />
            <Route path='60-diabetes' element={<Post60/>} />
            <Route path='144-diabetes' element={<Post144/>} />
            <Route path='132-diabetes' element={<Post132/>} />
            <Route path='141-diabetes' element={<Post141/>} />
            <Route path='10-diabetes' element={<Post10/>} />
            <Route path='108-diabetes' element={<Post108/>} />
            <Route path='135-diabetes' element={<Post135/>} />
            <Route path='41-diabetes' element={<Post41/>} />
            <Route path='125-diabetes' element={<Post125/>} />
            <Route path='126-diabetes' element={<Post126/>} />
            <Route path='148-diabetes' element={<Post148/>} />
            <Route path='117-diabetes' element={<Post117/>} />
            <Route path='131-diabetes' element={<Post131/>} />
            <Route path='151-diabetes' element={<Post151/>} />
            <Route path='146-diabetes' element={<Post146/>} />
            <Route path='22-diabetes' element={<Post22/>} />
            <Route path='18-diabetes' element={<Post18/>} />
            <Route path='140-diabetes' element={<Post140/>} />
            <Route path='96-diabetes' element={<Post96/>} />
            <Route path='21-diabetes' element={<Post21/>} />
            <Route path='77-diabetes' element={<Post77/>} />
            <Route path='70-diabetes' element={<Post70/>} />
            <Route path='27-diabetes' element={<Post27/>} />
            <Route path='97-diabetes' element={<Post97/>} />
            <Route path='28-diabetes' element={<Post28/>} />
            <Route path='98-diabetes' element={<Post98/>} />
            <Route path='20-diabetes' element={<Post20/>} />
            <Route path='76-diabetes' element={<Post76/>} />
            <Route path='150-diabetes' element={<Post150/>} />
            <Route path='115-diabetes' element={<Post115/>} />
            <Route path='89-diabetes' element={<Post89/>} />
            <Route path='45-diabetes' element={<Post45/>} />
            <Route path='127-diabetes' element={<Post127/>} />
            <Route path='147-diabetes' element={<Post147/>} />
            <Route path='80-diabetes' element={<Post80/>} />
            <Route path='24-diabetes' element={<Post24/>} />
            <Route path='92-diabetes' element={<Post92/>} />
            <Route path='101-diabetes' element={<Post101/>} />
            <Route path='25-diabetes' element={<Post25/>} />
            <Route path='23-diabetes' element={<Post23/>} />
            <Route path='94-diabetes' element={<Post94/>} />
            <Route path='17-diabetes' element={<Post17/>} />
            <Route path='58-diabetes' element={<Post58/>} />
            <Route path='19-diabetes' element={<Post19/>} />
            <Route path='121-diabetes' element={<Post121/>} />
            <Route path='81-diabetes' element={<Post81/>} />
            <Route path='38-pressao' element={<Post38/>} />
            <Route path='40-pressao' element={<Post40/>} />
            <Route path='35-pressao' element={<Post35/>} />
            <Route path='59-pressao' element={<Post59/>} />
            <Route path='73-pressao' element={<Post73/>} />
            <Route path='105-pressao' element={<Post105/>} />
            <Route path='106-pressao' element={<Post106/>} />
            <Route path='36-pressao' element={<Post36/>} />
            <Route path='133-pressao' element={<Post133/>} />
            <Route path='79-pressao' element={<Post79/>} />
            <Route path='33-pressao' element={<Post33/>} />
            <Route path='128-pressao' element={<Post128/>} />
            <Route path='55-pressao' element={<Post55/>} />
            <Route path='37-pressao' element={<Post37/>} />
            <Route path='31-colesterol' element={<Post31/>} />
            <Route path='34-colesterol' element={<Post34/>} />
            <Route path='65-colesterol' element={<Post65/>} />
            <Route path='103-colesterol' element={<Post103/>} />
            <Route path='63-colesterol' element={<Post63/>} />
            <Route path='30-colesterol' element={<Post30/>} />
            <Route path='61-colesterol' element={<Post61/>} />
            <Route path='74-colesterol' element={<Post74/>} />
            <Route path='78-colesterol' element={<Post78/>} />
            <Route path='32-colesterol' element={<Post32/>} />
            <Route path='62-colesterol' element={<Post62/>} />
            <Route path='68-colesterol' element={<Post68/>} />
            <Route path='104-colesterol' element={<Post104/>} />
            <Route path='66-colesterol' element={<Post66/>} />
            <Route path='46-colesterol' element={<Post46/>} />
            <Route path='111-cancer' element={<Post111/>} />
            <Route path='129-cancer' element={<Post129/>} />
            <Route path='149-cancer' element={<Post149/>} />
            <Route path='54-cancer' element={<Post54/>} />
            <Route path='52-cancer' element={<Post52/>} />
            <Route path='136-cancer' element={<Post136/>} />
            <Route path='124-cancer' element={<Post124/>} />
            <Route path='143-cancer' element={<Post143/>} />
            <Route path='130-cancer' element={<Post130/>} />
            <Route path='86-cancer' element={<Post86/>} />
            <Route path='51-cancer' element={<Post51/>} />
            <Route path='50-cancer' element={<Post50/>} />
            <Route path='48-cancer' element={<Post48/>} />
            <Route path='67-cancer' element={<Post67/>} />
            <Route path='95-cancer' element={<Post95/>} />
            <Route path='145-cancer' element={<Post145/>} />
            <Route path='109-cancer' element={<Post109/>} />
            <Route path='49-cancer' element={<Post49/>} />
            <Route path='84-cancer' element={<Post84/>} />
            <Route path='82-cancer' element={<Post82/>} />
            <Route path='47-cancer' element={<Post47/>} />
            <Route path='91-cancer' element={<Post91/>} />
            <Route path='53-cancer' element={<Post53/>} />
            <Route path='110-cancer' element={<Post110/>} />
            <Route path='6-geral' element={<Post6/>} />
            <Route path='102-geral' element={<Post102/>} />
            <Route path='7-geral' element={<Post7/>} />
            <Route path='139-geral' element={<Post139/>} />
            <Route path='13-geral' element={<Post13/>} />
            <Route path='3-geral' element={<Post3/>} />
            <Route path='5-geral' element={<Post5/>} />
            <Route path='4-geral' element={<Post4/>} />
            <Route path='9-geral' element={<Post9/>} />
            <Route path='12-geral' element={<Post12/>} />
            <Route path='2-geral' element={<Post2/>} />

          </Route>
        </Route>
      </Routes>
      </>

  )
}

export default App;
