import { format, getDay } from 'date-fns';
import React from 'react'
import { registerAction } from '../api';
import AuthContext from '../context/AuthProvider';

const WEEKDAYS = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]

function createDate(days) {
        var date = new Date(); 
        date.setDate(date.getDate() - days);
        //date.setMonth(date.getMonth() + months);
        //date.setFullYear(date.getFullYear() + years);
        return date;    
}

export default function ChooseDateBar({activityDate, setActivityDate}) {
  
  const { auth, cookies } = React.useContext(AuthContext);

  const weekdays = []
  const monthdays = []
  const completedays = []
  const completedaysUS = []
  let startDay = new Date()

   // seven days prior than the finish day
  for (let i=0; i<7; i+=1) {
    //startDay.setDate(currentDay.getDate() - i)
    startDay = createDate(i)

    weekdays.unshift(getDay(startDay))
    monthdays.unshift(format(startDay, "dd"))
    completedays.unshift(format(startDay, "dd/MM/yyyy"))
    completedaysUS.unshift(format(startDay, "MM/dd/yyyy"))
  }
  
  const filterDay = (day, i) => {
    setActivityDate(new Date(completedaysUS.at(i)))

    registerAction(+cookies.get("userId"), "changeSubmitionDate", auth, cookies)
  }

  const daysOptions = weekdays.map((day, i) => {
    return (
      <button key={day} className={(getDay(activityDate) === day) ? 
        "last-activity-day-selected top-margin-xs" : "last-activity-day top-margin-xs"}
        onClick={()=>{filterDay(day, i)}} type="button"
      >
        <p className={(getDay(activityDate) === day) ? 
            "body last-activity-day-name-selected" : "body last-activity-day-name"}>{WEEKDAYS[day]}</p>
        <p className={(getDay(activityDate) === day) ? 
            "body last-activity-day-number-selected bottom-margin-xs" :
            "body last-activity-day-number bottom-margin-xs"}>{monthdays.at(i)}</p>
      </button>
  )})

  return (
    <div className="datepicker-calendar">
      {daysOptions}
    </div>
  )
}
