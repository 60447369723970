import React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import MessageBroker from '../components/Submission/MessageBroker'
import { format } from "date-fns"
import { postRequestToApi, putRequestToApi } from '../api'

export default function WaitRecalculate() {
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const { 
    setMealsHistory,
    mealsHistory,
    setHighlightedMeal,
    highlightedMeal,
    setEating,
    evolutionBar,
    setEvolutionBar
  } = React.useContext(PortalContext)
  
  const [status, setStatus] = React.useState("submitting")

  React.useEffect(() => {
    if (!cookies.get("userId")) {
      navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
    }
    submitRecalculateRequest()
    askForUpdatedMessage()
  }, [])

  const navigate = useNavigate()
  const location = useLocation()
 
  
  async function askForUpdatedMessage() {

    try {
      // call backend to get fresh message
      const requestBody = { id: +cookies.get("userId"), name: "diet" }
      const data = await postRequestToApi(
        requestBody, 
        `/message`, 
        auth, 
        cookies
      )

      setEating(prev => ({...prev, "message": data.message, "message_url": data.image_url }))
      
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

    } catch (err) {
      console.log("Error asking for updating message")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    }
  }

  async function submitRecalculateRequest() {

    try {
      
      const requestBody = {
        'id': highlightedMeal.id,
        'name': highlightedMeal.name
      }

      const data = await putRequestToApi(
        requestBody, 
        `food/${cookies.get("userId")}`, 
        auth, 
        cookies
      )

      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
      
      const updatedCount = evolutionBar[format(highlightedMeal.date, "MM/dd/yyyy")]
      
      if (highlightedMeal.grade.toUpperCase() === "HEALTHY") {
        updatedCount.healthy -= 1
      } else if (highlightedMeal.grade.toUpperCase() === "ATTENTION") {
        updatedCount.attention -= 1
      } else if (highlightedMeal.grade.toUpperCase() === "SUPER HEALTHY") {
        updatedCount.super_healthy -= 1
      } 

      if (data.grade.toUpperCase() === "HEALTHY") {
        updatedCount.healthy += 1
      } else if (data.grade.toUpperCase() === "ATTENTION") {
        updatedCount.attention += 1
      } else if (data.grade.toUpperCase() === "SUPER HEALTHY") {
        updatedCount.super_healthy += 1
      } 
      
      setEvolutionBar(prev => ({...prev, [format(highlightedMeal.date, "MM/dd/yyyy")]: updatedCount }))

      setHighlightedMeal(prev => ({...prev, ...data}))

      const mealsHistoryUpdated = mealsHistory.filter(item => {
        if (item.id !== highlightedMeal.id) {
          return true
        } else {
          return false
        }
      })

      mealsHistoryUpdated.push({...highlightedMeal, ...data})
      setMealsHistory(mealsHistoryUpdated)

    } catch (err) {
      console.log("Error submitting food")
      setStatus("error")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    } finally {

      setStatus("complete")
      //navigate('/eating/highlight', { state: { message: "Sua refeição foi submetida com sucesso, e em alguns instantes ela aparecerá nessa página" }})
    }
  }

  return (
    <MessageBroker status={status} submitionItem="refeição" itemDate={highlightedMeal.date} ranking={false} />
  )

}
