import React from "react"
import { registerAction } from "../../api";
import { PortalContext } from "../../AuthenticatedUser";

export default function SimpleWaitMessage({ submitionItem }) {

  const { auth, cookies } = React.useContext(PortalContext);
  
  const [textIndex, setImageIndex] = React.useState(0)

  React.useEffect(() => {
    setImageIndex(Math.floor(Math.random() * 2))
    registerAction(+cookies.get("userId"), "simpleWaitMessagePage", auth, cookies)
  }, []);

  let waitTextMessage = [
    "Adicione sua refeição logo após se sentar a mesa ou preparar seu prato",
    "Adicione seu exercício logo antes de começar a fazê-lo (quando colocar a roupa de academia ou o tênis de caminhada)",  
    null
  ]

  if (waitTextMessage[textIndex]) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p className="secondary-color">{`Submetendo ${submitionItem}...`}</p> 
        <p className="body" style={{textAlign: "center", padding: "0 0.5rem", maxWidth: "500px"}}>
          <strong>Dica para criação de hábito:</strong> {waitTextMessage[textIndex]}
        </p> 
      </div>
    )
  } else {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>{`Submetendo ${submitionItem}...`}</p> 
      </div>
    )
  }
}
