import React from 'react'
import { PortalContext } from '../AuthenticatedUser'
import { format } from "date-fns"

function createDate(days) {
    var date = new Date(); 
    date.setDate(date.getDate() - days);
    //date.setMonth(date.getMonth() + months);
    //date.setFullYear(date.getFullYear() + years);
    return date;    
  }

export default function EvolutionBar() {

  const { evolutionBar } = React.useContext(PortalContext);

  const evolutionBarPercentages = []

  let overallAttention = 0
  let overallSuperHealthy = 0
  let overallHealthy = 0
  let overallTotal = 0 

  let startDay = new Date()

  if (!evolutionBar) {
    return
  }

   // seven days prior than the finish day
  for (let i=0; i<18; i+=1) {
    startDay = createDate(i)
    
    let dayUSFormat = format(startDay, "MM/dd/yyyy")

    let records = evolutionBar[dayUSFormat]

    let attention = 0
    let healthy = 0
    let superHealthy = 0
    let empty = 0


    if (!records?.total || records.total === 0) {
      attention = 0
      healthy = 0
      superHealthy = 0
      empty = 100
    } else {
      empty = 0
      if (records.attention === 0) {
        attention = 0
      } else {
        attention = Math.round(100*(records.attention / records.total))
      }
      if (records.super_healthy === 0) {
        superHealthy = 0
      } else {
        superHealthy = Math.round(100*(records.super_healthy / records.total))
      }
      healthy = 100 - attention - superHealthy
      
      overallAttention += records.attention
      overallSuperHealthy += records.super_healthy 
      overallHealthy += records.healthy
      overallTotal += records.total
    }

    evolutionBarPercentages.unshift({
      attention,
      healthy,
      superHealthy,
      empty,
      dayBRFormat: format(startDay, "dd/MM"),
      dayUSFormat
    })
  }


  return (
    <div className="div-no-shadow">
      <div className="spaced-around-text-row bottom-margin-xs">
        <div>
          <p className="secondary-text secondary-color">ATENÇÃO</p>
          <p className="body secondary-color">
            <strong>
            {(overallAttention > 0 && overallTotal > 0) ?
            `${Math.round(100*(overallAttention/overallTotal))}%` : '-' }
            </strong>
          </p>
        </div>
        <div>
          <p className="secondary-text secondary-color">SUPER SAUDÁVEL</p>
          <p className="body secondary-color">
            <strong>
            {(overallSuperHealthy > 0 && overallTotal > 0) ?
            `${Math.round(100*(overallSuperHealthy/overallTotal))}%` : '-' }
            </strong>
          </p>
        </div>
        <div>
          <p className="secondary-text secondary-color">SAUDÁVEL</p>
          <p className="body secondary-color">
            <strong>
            {(overallHealthy > 0 && overallTotal > 0) ?
            `${Math.round(100*(overallHealthy/overallTotal))}%` : '-' }
            </strong>
          </p>
        </div>
      </div>
      <div className="evolution-columns">
        {evolutionBarPercentages.map((item, index) => (
          <div key={index}>
            <div className="evolution-strip-empty" style={{height: `${item.empty}%`}}/>
            <div className="evolution-strip-red"  style={{height: `${item.attention}%`}}/>
            <div className="evolution-strip-green-weak"  style={{height: `${item.healthy}%`}}/>
            <div className="evolution-strip-green-stark" style={{height: `${item.superHealthy}%`}} />
          </div>
        ))}
      </div>
      <div className="spaced-between-text-row">
        <p className="secondary-text secondary-color">{evolutionBarPercentages.at(0).dayBRFormat}</p>
        <p className="secondary-text secondary-color">{evolutionBarPercentages.at(8).dayBRFormat}</p>
        <p className="secondary-text secondary-color">{evolutionBarPercentages.at(17).dayBRFormat}</p>
      </div>
    </div>
  )
}
