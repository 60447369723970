import React from "react"
import { Link } from "react-router-dom"
import Avatar from "../components/Avatar"

import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'

export default function LargeAvatar({user, lastName, sex, to, id, stateValue}) { 

  const { cookies } = React.useContext(AuthContext);
  const { 
    loadEatingDataFromAPI,
    loadExerciseDataFromAPI,
    setMealsHistory,
    setExerciseHistory
  } = React.useContext(PortalContext);

  const selectingUser = () => {
    cookies.set("userId", id, { path: '/' })
    cookies.set("userName", user, { path: '/' })
    cookies.set("userLastName", lastName, { path: '/' })
    cookies.set("userSex", sex, { path: '/' })
    
    // refresh user data
    setMealsHistory([])
    setExerciseHistory([])
    loadEatingDataFromAPI()
    loadExerciseDataFromAPI()
  }

  return (
    <Link className="avatar" onClick={()=>selectingUser()} to={to} state={(stateValue) ? {search: stateValue} : null }>
      <Avatar user={user} size={"large"} />
      <p className="avatar-name heading3">{user}</p>
    </Link>
  )
}
