import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Return from '../components/Forum/Return'
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { deleteDataFromApi, registerAction } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import EditableItemsCard2 from '../components/EditableItemsCard2'
import FoodThumbnail from '../components/Eating/FoodThumbnail'
import EatingHistory from '../components/EatingHistory'
import Button from '../components/Button'

export default function EatingHighlight() {
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  
  const {
    highlightedMeal,
    setHighlightedMeal,
    eating,
    setEating, 
    mealsHistory,
    setMealsHistory,
  } = React.useContext(PortalContext);
  
  const navigate = useNavigate()
  const location = useLocation()
  const [textIndex, setTextIndex] = React.useState(8)
  const [attentionIndex, setAttentionIndex] = React.useState(0)
  
  React.useEffect(() => {
    setTextIndex(Math.floor(Math.random() * 17))
    setAttentionIndex(Math.floor(Math.random() * 7))
  }, [])

  React.useEffect(() => {

    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else if (!highlightedMeal) {
      navigate("/profile")
    } //else {
      //loadEatingDataFromAPI()
      //
    //}
    registerAction(+cookies.get("userId"), "eatinghighlight", auth, cookies)
  }, []);

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const removeFood = (id) => {
    console.log(`removing food ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'food', auth, cookies)
    setMealsHistory(prev => prev.filter(item => item.id !== id))
    
    const historyUpdated = eating.history.filter(item => item.id !== id)
    setEating(prev => ({...prev, history: historyUpdated}))
    
    setHighlightedMeal({error: true, error_message: "A refeição foi excluída com sucesso"})
  }
  
  const mealsHistoryFiltered = mealsHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
 
  let score = 5

  if (!highlightedMeal.error) {
    if (highlightedMeal.grade.toUpperCase() === "HEALTHY") {
      score += 5
    } else if (highlightedMeal.grade.toUpperCase() === "SUPER HEALTHY") {
      score += 10
    }
  }
  
  const successPhrases = [
    "Parabéns!",
    "Você conseguiu!",
    "Bom trabalho!",
    "Arrasou!",
    "Que sucesso!",
    "Muito bom!",
    "Excelente!",
    "Estamos orgulhosos!",
    "Objetivos, lá vamos nós!",
    "Ninguém te segura!",
    "Que fenômeno!",
    "Maravilha!",
    "Muito bem!",
    "Estamos avançando!",
    "Demais!",
    "Ótimo trabalho!",
    "Bom apetite!"
  ]
  const attentionPhrases = [
    "Bom apetite!",
    "Você está no caminho!",
    "Estamos avançando!",
    "Bom trabalho!",
    "Que bom que você está de olho!",
    "Segue firme!",
    "Objetivos, lá vamos nós!",
    "Objetivos, lá vamos nós!"
  ]

  if (!highlightedMeal) {
      return <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando refeição submetida...</p> 
      </div>
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
     
      {(!highlightedMeal.error) ?
      <div className="icon-center">
        <img className="medium-logo" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        {(highlightedMeal.grade.toLowerCase() === "attention") ?
          <p class="florence-title">{attentionPhrases.at(attentionIndex)}</p> : 
          <p class="florence-title">{successPhrases.at(textIndex)}</p> } 
      </div> : null }

      <div className="top-margin-l">
        {(highlightedMeal.error) ?
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={highlightedMeal.error_message} /> :
          <EditableItemsCard2 
            title="Refeição adicionada"
            icons={false}
          >
            <div>
              <FoodThumbnail food={highlightedMeal} onRemove={() => removeFood(highlightedMeal.id)}/>
            </div> 
          </EditableItemsCard2>
        }
      </div>

      <div style={{textAlign: 'right'}}>
        <Button 
          type="primary top-margin-s" 
          link={true} 
          to={(location?.state?.ranking && !highlightedMeal.error) ? "/rankingupdate" : "/profile"}
          stateValue={{lastGain: score, activity: highlightedMeal.grade}}
        >
          Continuar
        </Button>
      </div>

  </div>
  )
}
