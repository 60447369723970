import React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import CommentaryFeed from './CommentaryFeed'
import Button from '../Button'
import { ForumContext } from '../../forum/ForumData'
import AuthContext from '../../context/AuthProvider'
import { postRequestToApi, deleteDataFromApi } from '../../api'

export default function CommentarySection({ postId, comments, profileForumData, allowCommentFeed=true, allowComment=true }) {

  const { loggedIn, loadForumData, forumData, setForumData, setProfileForumData, reputation} = React.useContext(ForumContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [ seeMoreButton, setSeeMoreButton ] = React.useState(false);
  const [ seeMore, setSeeMore ] = React.useState(true);

  const [formData, setFormData] = React.useState({comment: ""})
  const [status, setStatus] = React.useState("idle")
  const [deleteStatus, setDeleteStatus] = React.useState("idle")

  const navigate = useNavigate()
  const location = useLocation()

  async function submitComment() {

    console.log(formData.comment)
    if (formData.comment !== "") {
      setStatus("submitting")
      try {
        const data = await postRequestToApi({
          "id": cookies.get("userId"),
          "post_id": +postId,
          "comment": formData.comment
        }, "forum/comment", auth, cookies)
        //setResponse(data)
        setAuth(prev => ({
          ...prev, 
          accessToken: data.tokens.accessToken,
          refreshToken: data.tokens.refreshToken
        })) 
      } catch (err) {
        console.log("Error loading forum data")
        navigate('/loginrequired')
      } finally {
        //loadForumData(true) // true: user is loggedin
        let postDict = null
        if (profileForumData) {
          postDict = profileForumData.posts.filter((post) => post.id === +postId)[0]
        } else {
          postDict = forumData.posts.filter((post) => post.id === +postId)[0]
        }

        //const postDict = postData[postId]
        postDict.comments.unshift({
          author: cookies.get("userId"),
          content: formData.comment,
          created_at: new Date().toDateString(),
          first_name: cookies.get("userName"),
          last_name: cookies.get("userLastName"),
          id: postDict.comments.length + 1,
          notRemove: true,
          reputation: reputation 
        })

        let posts = null
        if (profileForumData) {
          posts = profileForumData.posts
          posts = posts.map(item => {
            if (+item.id === +postId) {
              return postDict
            } else {
              return item
            }
          })
          setProfileForumData(prev => ({...prev, posts: posts }))
        } else {
          posts = forumData.posts
          posts = posts.map(item => {
            if (+item.id === +postId) {
              return postDict
            } else {
              return item
            }
          })
          setForumData(prev => ({...prev, posts: posts }))
        }

        //setPostData(prev => ({...prev, [postId]: postDict }))
        setFormData({comment: ""})
        setStatus("idle")

      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    
    if (formData.comment === "") {
      return
    }

    //console.log('submitting')
    submitComment()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  async function deletePost(comment_id) {
    setDeleteStatus("submitting")
    try {
      const data = await deleteDataFromApi({ 
        id: +cookies.get("userId"),
        comment_id: +comment_id 
      }, 'forum/comment', auth, cookies)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/forum')
    } finally {
      comments = comments.filter(comment => comment.id !== comment_id)

      const postDict = forumData.posts.filter((post) => post.id === +postId)[0]
      postDict.comments = comments

      let posts = forumData.posts
      posts = posts.map(item => {
        if (+item.id === +postId) {
          return postDict
        } else {
          return item
        }
      })

      //setPostData(prev => ({...prev, [postId]: postDict }))
      setForumData(prev => ({...prev, posts: posts }))

      setDeleteStatus("idle")
      //console.log("comment deleted")
    }
  }

  const comment_limit = 3

  React.useEffect(() => {
    if (comments.length > comment_limit) {
      setSeeMoreButton(true)
      setSeeMore(false)
      //console.log(comments.length)
      //console.log('limit number of comments')
    }
  }, [])

  function seeMoreButtonClicked() {
    setSeeMoreButton(false)
    setSeeMore(true)
  }

  return (
    <div>
      {(comments.length > 0 && allowCommentFeed) ?
        <p className="body bottom-margin-xs">Respostas</p> : null
      }
      {(comments.length > 0 && allowCommentFeed) ?
        comments.map((comment, index) => {
          if ((index <= comment_limit - 1) || seeMore) {
            return <CommentaryFeed 
              key={comment.id} 
              postId={postId}
              comment={comment} 
              onDelete={(comment_id)=>deletePost(comment_id)} 
              deleteStatus={deleteStatus}
            />
          }    
          else {
            return null
          }
        }) 
        : null
      }
      {
        (seeMoreButton) ?
        <Button type="tertiary" onClick={()=>seeMoreButtonClicked()}>Ver mais comentários</Button> :
        null
      }
      { (loggedIn && allowComment) ? 
      <form onSubmit={handleSubmit}>
        <div>
          <div className="bottom-margin-xs top-margin-xs">
            <textarea id="answer" name="comment" className="comment-form" type="text" 
                placeholder="Digite sua resposta" onChange={handleChange} value={formData.comment}/>
          </div>
          <Button type="primary bottom-margin-s">{(status==="idle") ? "Responder" : "Enviando..."}</Button>
        </div>
      </form> : null }
      { (!loggedIn && allowComment) ?
      <div className="bottom-margin-s">
        <Button type="secondary" 
        onClick={()=> { navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})}}>
         Responder 
        </Button>
      </div> : null}
    </div>
  )
}
