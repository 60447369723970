import React from 'react'
import ClickableItemForCard from "../components/ClickableItemForCard"
import EditableItemsCard2 from "../components/EditableItemsCard2"
import Calendar2 from "../components/Calendar2"
import Tag from "../components/Tag"
import { format, subDays, differenceInHours } from "date-fns"
import { registerAction } from '../api'
import AuthContext from '../context/AuthProvider'
import Button from './Button'

const WEEKDAYS = ["", "Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"]

export default function CalendarHistory( {medicationReminders, title, onCardConfirmation, showCalendar=true, accessButtons=false} ) {

  const { auth, cookies } = React.useContext(AuthContext);

  const [filter, setFilter] = React.useState([format(new Date, "dd/MM/yyyy")])
  const [filterDay, setFilterDay] = React.useState(new Date)
  const [showReminders, setShowReminders] = React.useState(true)

  if (filter.length > 1) {
    title += "ontem e hoje"
  } else if (filter.at(0) == format(new Date, "dd/MM/yyyy")) {
    title += "hoje"
  } else {
    title += `- ${filter.at(0)}` 
  }

  const onDayClick = (clickedDay) => {
    setFilter([format(clickedDay, "dd/MM/yyyy")])
    setFilterDay(clickedDay)
    registerAction(+cookies.get("userId"), `historyfilterdaymedication`, auth, cookies)
  }

  let medicationAfterFilter = null
  if (medicationReminders) {
    medicationAfterFilter = medicationReminders.filter(item => {
      return filter.includes(format(item.date, "dd/MM/yyyy"))
    })
  }

  const reminders = (medicationAfterFilter) ? medicationAfterFilter.map(item => {
    
    let tagColor = "blue"
    if (item.status === "Pendente") {
      if (differenceInHours(new Date(), item.date) > -1) { 
        tagColor = "red"
      }
    } else if (item.status === "Confirmado") {
      tagColor = "green"
    }

    const hour = +format(item.date, "H")
    let period = ""
    if (hour <= 12) {
      period = "manhã"
    } else if (hour <= 18) {
      period = "tarde"
    } else {
      period = "noite"
    }
    
    const weekDay = +format(item.date, "e")

    const showButton = (item.status === "Pendente" 
      && differenceInHours(new Date(), item.date) < 48 
      && differenceInHours(new Date(), item.date) > -1)   
    
    return (
      <ClickableItemForCard key={item.id} colour={tagColor} button={showButton} 
        onClick={onCardConfirmation} listId={item.id}> 
        <div>
          <div className="editable-item-and-tag"> 
              <Tag color={tagColor}>{(tagColor==="red") ? item.status : "Confirmado"}</Tag>
              <p className="body vertical-margin-xxs">{item.medication}</p>
              {(item.frequency) ?
                <p className="secondary-text">{`${format(item.date, "d/MM")}, ${WEEKDAYS[weekDay]} de ${period}`}</p> :
                <p className="secondary-text">{`${format(item.date, "d/MM")}, ${WEEKDAYS[weekDay]} de ${period}`}</p>
              }
          </div>
        </div>
      </ClickableItemForCard>
    )
  }) : null

  return (
    <div>
      <div className="container-simple calendar-history">
        {(showCalendar) ?
          <Calendar2 medicationReminders={medicationReminders} dayClicked={filterDay} onDayClick={onDayClick}/>
          : null
        }

        <EditableItemsCard2 title={title} icons={false} userAdd={false}>
          {(showReminders) ? reminders : null}
          {(accessButtons) ? 
            <div className="top-margin-s">
              <Button type="primary left-margin-s" link={true} to="/health/adherence">Acessar</Button>
              <Button type="tertiary left-margin-xs  card-background" onClick={()=>{setShowReminders(prev=>!prev)}}>
                {(showReminders) ? "Esconder lembretes" : "Mostrar lembretes"}
              </Button>
            </div> 
            : null 
          }
        </EditableItemsCard2>

      </div>
    </div>
  )
}

