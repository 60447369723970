import React from 'react'
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import SearchBar from '../components/Forum/SearchBar'
import Feed from '../components/Forum/Feed'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import { ForumContext } from './ForumData'
import AuthContext from '../context/AuthProvider'
import ReactGA from "react-ga4";
import { registerAction } from '../api';
import StarIcon from "../images/star_big.svg"

export default function Forum() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { loggedIn, setPostGroup, notifications, reputation, pageNumber, setPageNumber } = React.useContext(ForumContext)
  const { cookies, auth } = React.useContext(AuthContext);

  //const [pageNumber, setPageNumber] = React.useState(0)

  const [searchParams, setSearchParams] = useSearchParams({})
  const postGroup = searchParams.get("group")

  React.useEffect(()=>{
    setPostGroup(postGroup)
    setPageNumber(0)
  }, [postGroup])

  React.useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: "/forum", title: "Forum visit"})
    if (cookies.get("userId")) {
      registerAction(+cookies.get("userId"), "forumpage", auth, cookies)
    }
  }, [])

  return (
    <div className="top-margin-s">

      <Helmet>
        <title>Florence Health - Fórum</title>
        <meta 
          name="description" 
          content="Tire dúvidas sobre saúde e compartilhe sua experiência com outras pessoas" />
        <meta name="keywords" content="alimentação, exercícios físicos, hábitos, objetivos, saúde, comunidade, fórum"/>
      </Helmet>

      <SearchBar tabletBreakpoint={tabletBreakpoint} notifications={notifications}/>

      <div className={(tabletBreakpoint) ?
        "container-simple-tablet forum-main top-padding-s": 
        "container-simple forum-main top-padding-s"}>
        <Return to={(cookies.get("userId")) ? `/profile` : '/'}/>
        { (loggedIn && reputation !== null) ?
          <p className="florence-title">
            Fórum
            &nbsp; 
            &nbsp; 
            <Link className="svg-btn" to="/forum/reputation">
              <img src={StarIcon} alt="Pontuação de reputação"/>
              <span className="heading2 sans-serif-typeface">{' ' + reputation}</span>
            </Link>
          </p>
          :
          <p className="florence-title">Fórum</p>
        }
        <p className="body secondary-color top-margin-s">
          Tire suas dúvidas sobre saúde e ajude a responder as dúvidas de outras pessoas
        </p>
        <div className= {(tabletBreakpoint) ? 
          "forum-main-horizontal-menu-tablet top-margin-s bottom-margin-s" : 
          "forum-main-horizontal-menu top-margin-s bottom-margin-s"}>
          <Button type="primary" link={true} to='write'>Escrever post</Button>
          { (loggedIn) ?
            <>
              <Button type="tertiary" link={true} to='myposts'>Meus posts</Button>
              <Button type="tertiary" link={true} to='saved'>Posts salvos</Button>
            </>:
            null
          }
        </div>
        <p className="body secondary-color top-margin-s">Selecione um assunto</p>
        <div className="tags-horizontal-menu top-margin-xs bottom-margin-s">
          <button className={(postGroup === null) ? "tag-selected":"tag-filter"}
            onClick={()=>setSearchParams({})}>Geral</button>
          <button className={(postGroup === "exercicio") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "exercicio"})}>Exercício Físico</button>
          <button className={(postGroup === "alimentacao") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "alimentacao"})}>Alimentação</button>
          <button className={(postGroup === "diabetes") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "diabetes"})}>Diabetes</button>
          <button className={(postGroup === "pressao") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "pressao"})}>Pressão alta</button>
          <button className={(postGroup === "colesterol") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "colesterol"})}>Colesterol alto</button>
          <button className={(postGroup === "cancer") ? "tag-selected" : "tag-filter"} 
            onClick={()=>setSearchParams({group: "cancer"})}>Câncer</button>
        </div>
      </div>

      <Feed tabletBreakpoint={tabletBreakpoint} 
        pageNumber={pageNumber} setPageNumber={setPageNumber}
        searchTerm={searchParams.toString()}/>

    </div>
  )
}
