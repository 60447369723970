import React from 'react'
import CommentIcon from '../../images/comment.svg'

export default function PostComments({ qtyComments, onClick=()=>{} }) {
  
  return (
    (qtyComments > 0) ?
      <button onClick={()=>onClick()} className="svg-btn">
        <div className="post-useful-info"> 
          <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
          <p className="secondary-text">
          { (qtyComments === 1) ? "1 resposta" : `${qtyComments} respostas` }
          </p>
        </div> 
      </button>
    : <div className="post-useful-info"> 
        <img src={CommentIcon} alt="Icon indicating quantity of comments in post" />
        <p className="secondary-text">Nenhuma resposta</p>
      </div>  
  )
}
