import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Eating() {

  const navigate = useNavigate()

  // go to the top of the page
  React.useEffect(() => {
    navigate("/profile")
  }, []);

  return null

}
