import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import Button from "../Button"
import { deleteDataFromApi, putRequestToApi, registerAction } from "../../api"
import EditableItemsCard2 from "../EditableItemsCard2"
import EditableItemForCard2 from "../EditableItemForCard2"
import HighlightedMessage from "../HighlightedMessage"
import Attention from "../../images/sun.svg"
import RemoveIcon from '../../images/remove.svg'
import { format } from "date-fns"

export default function WeightEvolutionMessage({ clickNext }) {
  
  const { eating, setEating, auth, cookies } = React.useContext(PortalContext)

  const [errorWeight, setErrorWeight] = React.useState(null)
  const [addWeight, setAddWeight] = React.useState(true) 

  console.log(eating)

  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "weightEvolutionMessagePage", auth, cookies)
  }, []);

  const addWeightManually = (content) => {

    if (+content.weight && content.weight >= 400) {
      setErrorWeight("Insira um número válido para o peso. Ele precisa ser um valor menor que 400 kg")
      return
    } else if (+content.weight && content.weight <= 20) {
      setErrorWeight("Insira um número válido para o peso. Ele precisa ser um valor maior que 20 kg")
      return
    } else if (+content.weight) {
      setErrorWeight(null)

      const requestBody = {
        "id": +cookies.get("userId"),
        "name": content.weight
      }
      putRequestToApi(requestBody, 'weights', auth, cookies)

      let updatedWeight = eating.weights
      updatedWeight.unshift({
        value: content.weight, date: new Date()
      }) // push to the front of the array
      setEating(prev => ({...prev, weights: updatedWeight}))
    } else {
      setErrorWeight("Insira um número válido para o peso. Utilize ponto ao invés de vírgula. Por exemplo: 70.5")
      return
    }
  }

  const removeWeight = (id) => {
    console.log(`removing weight ${id}`)
    deleteDataFromApi({"id": +cookies.get("userId"), "item_id": +id}, 'weights', auth, cookies)
    const updatedWeights = eating.weights.filter(item => item.id !== id)
    setEating(prev => ({...prev, weights: updatedWeights}))
  }

  return (
    <div className={"container-simple"}>
      <div className="top-margin-m bottom-margin-s icon-center">
        <img className="medium-logo" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4 bottom-margin-xs">Veja sua evolução</p> 
      </div>

      <EditableItemsCard2 
        clsName="bottom-margin-l" 
        title="Acompanhamento de Peso"
        icons={true}
        addCard={()=>setAddWeight(prev=>!prev)}
      >
        { (errorWeight) ?
          <div className="container-simple">
            <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={errorWeight} />
          </div> : null
        }
        {(addWeight) ? 
            <EditableItemForCard2 additionCard={true} setAddCard={()=>setAddWeight(prev=>!prev)} 
              addItemSubmit={(name, value) => addWeightManually(value)}
              dictFields={["weight"]} userField={["weight"]} 
              placeholder={["Adicione o valor do seu peso, em kg. Exemplo: 75.5"]}/>
          : null}
        {eating.weights.map(item => (
          <div>
            <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p className="body">{`Peso: ${item.value} kg`}</p>
                {(item.id) ? <div className="row-svg-icons left-margin-xs">
                  <button onClick={()=>{removeWeight(item.id)}} className='carousel-btn'>
                    <img className="" src={RemoveIcon}/>
                  </button>
                </div> : null }
              </div>
              <p className="secondary-text">{`Registrado em ${format(item.date,"d/M/yyyy")}`}</p>
            </div>
          </div>
        ))}
      </EditableItemsCard2>
        
      <div className="top-margin-s bottom-margin-s" style={{maxWidth: "450px", textAlign: 'right'}}>
        <Button type="primary" onClick={()=>{clickNext()}}>Próximo</Button>
      </div>
    </div>
  )
}
