import React from 'react'
import { useNavigate } from "react-router-dom"
import RemoveIcon from '../../images/remove.svg'
import EditIcon from '../../images/edit.svg'
import { format } from "date-fns"
import Button from '../Button'
import { putRequestToApi, registerAction } from '../../api'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import HighlightedMessage from '../HighlightedMessage'
import Attention from "../../images/sun.svg"
import Tag from '../Tag'
import ChooseDateBar from '../../portal/ChooseDateBar'

export default function ExerciseThumbnail({ exercise, onRemove, onEdit=true, icons=true }) {

  const [edit, setEdit] = React.useState(false)
  const [status, setStatus] = React.useState('idle')
  const [formData, setFormData] = React.useState(
    {
      description: exercise.description,
      date: exercise.created_at ? exercise.created_at : exercise.date
    })
  const [error, setError] = React.useState(null)
  const [activityDate, setActivityDate] = React.useState(new Date((exercise.created_at) ? exercise.created_at : exercise.date))

  const [breakComment, setBreakComment] = React.useState(false)
  const [breakCommentButton, setBreakCommentButton] = React.useState(false)
  
  const { 
    exerciseHistory, 
    setExerciseHistory, 
    //exerciseWeek, 
    //setExerciseWeek, 
    highlightedExercise, 
    setHighlightedExercise } = React.useContext(PortalContext);
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
 
  const navigate = useNavigate()

  React.useEffect(()=>{
    if (exercise.description.length > 100) {
      setBreakComment(true)
      setBreakCommentButton(true)
    }
  }, [])


  function breakCommentButtonClicked() {
    setBreakComment(prev=>!prev)
  }
  
  let imageUrl = null
  if (exercise.hasImage) {
    imageUrl = window.URL.createObjectURL(exercise.image)
  }
  
  async function submitEdition() {

    setError(null)

    if (formData.description !== "") {

      setStatus("submitting")
      try {
        const requestBody = {
          record_id: exercise.id,
          id: +cookies.get("userId"), 
          item: {
            name: formData.description,
            date: activityDate 
          }
        }

        const data = await putRequestToApi(requestBody, 'exercise', auth, cookies)
        
        setAuth(prev => ({
          ...prev, 
          accessToken: data.tokens.accessToken,
          refreshToken: data.tokens.refreshToken
        })) 


      } catch (err) {
        console.log("Error loading forum data")
        navigate('/loginrequired')
      } finally {

        const exerciseUpdated = { 
          ...exercise, 
          description: formData.description,
          created_at: activityDate,
          date: activityDate
        }

        const exerciseHistoryUpdated = exerciseHistory.filter(item => item.id !== exercise.id)
        setExerciseHistory([...exerciseHistoryUpdated, exerciseUpdated])
  
        //if (exerciseWeek.filter(item => item.id === exercise.id).length > 0) {
        //  const exerciseWeekUpdated = exerciseWeek.filter(item => item.id !== exercise.id)
        //  setExerciseWeek([...exerciseWeekUpdated, exerciseUpdated])
        //}
        
        if (highlightedExercise && highlightedExercise.id === exercise.id) {
          setHighlightedExercise(prev => (
            {
              ...prev, 
              description: exerciseUpdated.description,
              created_at: activityDate,
              date: activityDate
            }))
        }
        
        setEdit(false)
        setStatus("idle")
      }
    } else {
      setError("Por favor, preencha todos os campos")
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    submitEdition()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  const toggleEdit = () => {
    setEdit(prev => !prev)
    registerAction(+cookies.get("userId"), "toggleExerciseEdit", auth, cookies)
  }

  if (edit) {
    return (
      <div className="editable-item-for-card-green top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">     
          {(error) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />
            </div> :
            null
          }
          
          <form onSubmit={handleSubmit}>
            <div>
              <div className="bottom-margin-xs top-margin-xs">
                <textarea id="answer" name="description" className="comment-form" type="text" 
                    placeholder="Descrição do seu exercício" onChange={handleChange} value={formData.description}/>
              </div>

              <div className="bottom-margin-xs top-margin-xs">
                <p className="body">Data da atividade</p>
                <ChooseDateBar activityDate={activityDate} setActivityDate={setActivityDate} />
              </div>

              <Button type="primary">{(status==="idle") ? "Salvar" : "Enviando..."}</Button>
              <Button type="secondary left-margin-xs" onClick={()=>toggleEdit()}>{"Cancelar"}</Button>
            </div>
          </form>
      </div>
    )
  }

  return (
    <div className="editable-item-for-card-green-stark top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">     
      <div className='editable-item-for-card-header'>
        <div className='food-image-title-row'>
          {(exercise.hasImage) ?
          <img className="food-image" src={imageUrl} alt="Exercise image"/> : null }
          <div>

            <div className="bottom-margin-xxs">
              <Tag color="green-stark">Exercício</Tag>
            </div>

            <p className="body content">{(breakComment) ? `${exercise.description.slice(0,100)}...` : exercise.description }</p>

            {(breakCommentButton) ? 
              <Button type="tertiary top-margin-xs bottom-margin-xs" onClick={()=>breakCommentButtonClicked()}>
                {(breakComment) ? 'Mostrar mais' : 'Mostrar menos' }
              </Button> : null
            }

            <p className="secondary-text">Adicionado em {(exercise.created_at) ? format(exercise.created_at,"d/M/yyyy") : format(exercise.date, "d/M/yyyy")}</p>
          </div>
        </div>
        { (icons) ?
        <div className="row-svg-icons left-margin-xs">
          {(onEdit) ? 
          <button onClick={()=>{toggleEdit()}} className='carousel-btn'>
            <img className="" src={EditIcon}/>
          </button> : null }

          <button onClick={()=>{onRemove()}} className='carousel-btn'>
            <img className="" src={RemoveIcon}/>
          </button>
        </div> : null
        }
      </div>
    </div>
  )
}
