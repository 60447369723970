import React from 'react'
import { useNavigate } from "react-router-dom"
import AuthContext from "../context/AuthProvider"
import Button from '../components/Button'
import HighlightedMessage from './HighlightedMessage';
import Attention from "../images/sun.svg"
import registerUser from "../api"
import { insertDependent } from "../api"

export default function RegistrationBlock({ formDataCache, setFormDataCache, setShowPolicy, newFamilyMember=false }) {

  const userRef = React.useRef()

  const navigate = useNavigate()

  const { auth, setAuth, cookies } = React.useContext(AuthContext)

  const [loginFormData, setLoginFormData] = React.useState({
    firstName: "", lastName: "", month: "", day: "", year: "", sex: "", email: "", password: ""
  })

  const [status, setStatus] = React.useState("idle")
  const [error, setError] = React.useState(null)
  const [apiData, setApiData] = React.useState(null)

  // go to the top of the page
  React.useEffect(() => {
    userRef.current.focus()
    window.scrollTo(0, 0)

    if (formDataCache && setFormDataCache) {
      setLoginFormData(formDataCache)
    }
  }, []);

  React.useEffect(() => {
    if (apiData) {
      
      console.log(apiData)

      if (newFamilyMember) {
        navigate("/family", {state: {message: "Novo membro da família criado com sucesso"}}) 

      } else {
        navigate("/profile", {state: "new user"}) 
        //navigate("/tutorial") 
      }
    }
  }, [apiData])

  const handleSubmit = (e) => {
    e.preventDefault()

    console.log(loginFormData)

    // check if day is a number
    if (!(+loginFormData.day)) {
      setError("Dia deve ser um número entre 1 e 31")
      return
    } else {
      if (+loginFormData.day < 0) {
        setError("Dia deve ser um número entre 1 e 31")
        return
      }
      if (+loginFormData.day > 31) {
        setError("Dia deve ser um número entre 1 e 31")
        return
      }
    }

    if (!(+loginFormData.year)) {
      setError("Ano deve ser um número válido. Por exemplo: 1990")
      return
    } else {
      if (+loginFormData.year < 1900 || +loginFormData.year > 2100) {
        setError("Ano deve ser um número válido. Por exemplo: 1990")
        return
      }
    }

    if (loginFormData.firstName && loginFormData.lastName && loginFormData.day && loginFormData.year && loginFormData.month && loginFormData.sex && loginFormData.email && loginFormData.password) {
      setError(null)

      async function tryRegisteringUser(loginFormData) {
        try {
          setStatus("submitting")

          if (!newFamilyMember) {

            const data = await registerUser(loginFormData)
    
            cookies.set("userId", data?.id, { path: '/' })
            cookies.set("userName", data?.first_name, { path: '/' })
            cookies.set("userLastName", data?.last_name, { path: '/' })
            cookies.set("userSex", data?.sex, { path: '/' })

            const accessToken = data?.access_token
            const refreshToken = data?.refresh_token
            setAuth({ accessToken, refreshToken })
            
            setApiData(data)
          } else {
            console.log("insert dependent")
            const data = await insertDependent(loginFormData, auth, cookies)
            setAuth(prev => ({...prev, ...data.tokens}))
            setApiData(data)
          }

        } catch(err) {
          setError(err.message)
        } finally {         
          setStatus("idle")
        }
      }
      tryRegisteringUser(loginFormData)

    } else {
      setError("Complete todos os campos do formulário")
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target

    setLoginFormData(prev => ({
      ...prev,
      [name]: value
    }))

    if (setFormDataCache) {
      setFormDataCache(prev => ({
        ...prev,
        [name]: value
      }))
    }
  }


  return (
    <div className="login-container horizontal-padding-l">
      {(error) ?
        <div className="top-padding-xs">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }

      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-s">Nome</p>
        <input
          className="input-text bottom-margin-xs"
          ref = {userRef}
          name="firstName"
          onChange={handleChange}
          type="text"
          placeholder="Digite seu nome"
          value={loginFormData.firstName}
        />
        <p className="body">Sobrenome</p>
        <input
          className="input-text bottom-margin-xs"
          name="lastName"
          onChange={handleChange}
          type="text"
          placeholder="Digite seu sobrenome"
          value={loginFormData.lastName}
        />    
        <p className="body">Data de nascimento</p>

        <div className="row-select-birthdate">

          <select 
            className="birthday-input bottom-margin-xs"
            name="month"
            onChange={handleChange}
            value={loginFormData.month}
          >
            <option name="month" value="">Mês</option>
            <option name="month" value="1">Janeiro</option>
            <option name="month" value="2">Fevereiro</option>
            <option name="month" value="3">Março</option>
            <option name="month" value="4">Abril</option>
            <option name="month" value="5">Maio</option>
            <option name="month" value="6">Junho</option>
            <option name="month" value="7">Julho</option>
            <option name="month" value="8">Agosto</option>
            <option name="month" value="9">Setembro</option>
            <option name="month" value="10">Outubro</option>
            <option name="month" value="11">Novembro</option>
            <option name="month" value="12">Dezembro</option>
          </select>

          <input
            className="birthday-input-text bottom-margin-xs"
            name="day"
            onChange={handleChange}
            type="text"
            placeholder="Dia"
            value={loginFormData.day}
          />
          <input
            className="birthday-input-text bottom-margin-xs"
            name="year"
            onChange={handleChange}
            type="text"
            placeholder="Ano"
            value={loginFormData.year}
          />

        </div>

        <p className="body">Sexo</p>

        <div className="row-radio-buttons bottom-margin-xs">
          <div className="closed-radio-button">
            <label className="body" htmlFor="male">Masculino</label>
            <input
              name="sex"
              id = "male"
              onChange={handleChange}
              type="radio"
              value="Masculino"
              checked={loginFormData.sex === "Masculino"}
            />
          </div>
          <div className="closed-radio-button">
            <label className="body" htmlFor="female">Feminino</label>
            <input
              className="radio"
              name="sex"
              id = "female"
              onChange={handleChange}
              type="radio"
              value="Feminino"
              checked={loginFormData.sex === "Feminino"}
            />
          </div>
          <div className="closed-radio-button">
            <label className="body" htmlFor="Outro">Outro</label>
            <input
              className="radio"
              name="sex"
              id = "undefined"
              onChange={handleChange}
              type="radio"
              value="Outro"
              checked={loginFormData.sex === "Outro"}
            />
          </div>
        </div>

        <p className="secondary-text top-margin-xs">
          {(newFamilyMember) ? "O novo membro da família poderá acessar sua conta utilizando seu próprio e-mail e senha.": 
          null}
        </p>

        <p className="body">E-mail</p>
        <input
          className="input-text bottom-margin-xs"
          name="email"
          onChange={handleChange}
          type="email"
          placeholder="Digite seu e-mail"
          value={loginFormData.email}
        />
        <p className="body">Senha</p>
        <input
          className="input-text bottom-margin-s"
          name="password"
          onChange={handleChange}
          type="password"
          placeholder="Digite sua senha"
          value={loginFormData.password}
        />
       
        {(newFamilyMember) ? null : 
        <div className="bottom-margin-s"><span className="secondary-text bottom-margin-s">
          Finalizando seu cadastro você aceita receber e-mails com lembretes e concorda com nossos </span> 
          <a onClick={()=>{setShowPolicy(true)}} className="btn-smaller"> termos de uso, política de privacidade e cookies.</a>
        </div>
        }
        
        <div>
          { (newFamilyMember) ?
          <Button type="primary bottom-margin-s">
            {(status==="idle")? "Adicionar novo familiar" : "Adicionando..."}
          </Button> :
          <Button type="primary bottom-margin-s">
            {(status==="idle")? "Finalizar cadastro" : "Registrando..."}
          </Button>
          }
        </div>
      </form>
    </div>
  )
}
