import React from "react"
import { isToday } from "date-fns"
import Button from "../components/Button"
import TrophyBackBlue from "../images/trophy-blue.svg"
import { PortalContext } from "../AuthenticatedUser"
import { useLocation, useNavigate } from "react-router-dom"
import WeeklyRanking from "../components/WeeklyRanking"
import { putRequestToApi, registerAction } from "../api"
import AuthContext from "../context/AuthProvider"

export default function LeagueUpdate() {
  
  const {
    league, setLeague, eating, loadEatingDataFromAPI 
  } = React.useContext(PortalContext)
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const sequence = { length: 10 }
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (!league || !league?.is_in_current_league) {
      navigate('/profile')
    }
    if (!eating) {
      loadEatingDataFromAPI()
    }

    registerAction(+cookies.get("userId"), "updateLeagueMessagePage", auth, cookies)

  }, [])

  async function sendCheckCall() {

    try {

      // call backend to get fresh message
      const data = await putRequestToApi(
        null, 
        `/sequence/${+cookies.get("userId")}`, 
        auth, 
        cookies
      )

      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

    } catch (err) {
      console.log("Error asking for updating message")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    }
  }

  const checkResultAsSeen = () => {
    setLeague(prev => ({...prev, show_result: false }))

    sendCheckCall()
    
    registerAction(+cookies.get("userId"), "checkLeagueResultAsSeen", auth, cookies)
  }

  let scoreGained = 5
  if (league?.last_gain) {
    scoreGained = league.last_gain
  } else if (location?.state?.search?.lastGain) {
    scoreGained = location.state.search.lastGain
  }
 
  if (!league || !league?.is_in_current_league) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>{`Carregando página...`}</p> 
      </div>
    )
  }

  if (league.show_result) {
    return (
      <div className="background-hot">
        <div className="container-column">
          <div className="icon-loading-middle-page">
            <div className="centered-column-text color-hot">
              <div className="nav-icon-red-background horizontal-padding-s">
                <div className="icon-column vertical-padding-xs">
                  <img className="big-icon-page" src={TrophyBackBlue} alt="Florence logo"/>
                </div>
              </div>

                <p className="heading2 top-margin-s bottom-margin-xxs">
                  <strong>{league.last_league_position}&#186; LUGAR</strong>
                </p>
                <p className="heading4 bottom-margin-xxs">
                  no ranking semanal
                </p>
                <p className="body bottom-margin-m">Seu recorde pessoal são {league.personal_best_score} pontos</p>

              <Button type="primary" onClick={()=>{checkResultAsSeen()}}>Próximo</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }



  return (
    <div className="background-hot">
      <div className="container-column">
          <div className="centered-column-text color-hot top-margin-m">
            <div className="nav-icon-red-background right-padding-xs">
              <div className="icon-row">
                <img className="big-icon-page icon-nav-left-padding right-margin-xxs" src={TrophyBackBlue} alt="Florence logo"/>
                <p className="typography-big-icon sans-serif-typeface typeface-no-margin-red right-padding-xxs">
                  +{scoreGained} 
                </p>
              </div>
            </div>
          </div>

        <p className="heading4 bottom-margin-xs" style={{textAlign: "center", padding: "0 10px"}}>
          Você está subindo no ranking da semana!
        </p>


        <div className="calendar-history top-margin-s bottom-margin-s">
          <WeeklyRanking
            participants={league.league_rank}
            userId={+cookies.get("userId")}
          />
        </div>

        {(!eating?.weekly_challenge || !location?.state?.search?.activity || eating.weekly_challenge.complete) ?
          <Button type="primary top-margin-s" link={true} to={"/profile"}>
            Próximo
          </Button> :
          <Button 
            type="primary top-margin-s" 
            link={true} 
            to={"/weeklychallenge"}
            stateValue={{activity: location.state.search.activity}}
          >
            Próximo
          </Button>
        }

      </div>
    </div>
  )
}

