import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import { useNavigate } from "react-router-dom"
import Button from "../Button"
import { deleteDataFromApi, postRequestToApi, registerAction } from "../../api"
import AuthContext from "../../context/AuthProvider"

export default function JunkFood({ clickNext }) {
  
  const navigate = useNavigate()

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { eating, setEating } = React.useContext(PortalContext)

  const [status, setStatus] = React.useState("idle")

  const [formData, setFormData] = React.useState({text: ''})
  const [alternative, setAlternative] = React.useState(null)
  
  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "junkFoodMessagePage", auth, cookies)
  }, [])

  const alternatives = [
    "Comer uma fruta ou algumas nozes que vou levar comigo",
    "Beber um copo de água, porque a sede pode ser confundida com fome",
    "Esperar 10 minutos para ver se a vontade passa",
    "Me distrair com uma caminhada, ou fazendo algo que gosto, como ler as notícias"
  ]

  async function addPlan() {

    if (formData === "" && alternative === null) {
      return
    }

    try {

      setStatus("submitting")

      let bodyRequest = null
      let newPlan = "Quando der vontade de comer algo não saudável, eu vou: "

      if (formData.text !== '') {

        newPlan += formData.text
        bodyRequest = {
          "id": +cookies.get("userId"),
          "name": newPlan
        }

      } else {
        
        newPlan += alternatives.at(alternative)
        bodyRequest = {
          "id": +cookies.get("userId"),
          "name": newPlan
        }

      }

      if (eating.plan.filter(prev => prev.title === newPlan).length > 0) {
        return
      }

      const data = await postRequestToApi(
        bodyRequest, 
        `/food/plan`, 
        auth, 
        cookies
      )

      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

      const updatedPlan = eating.plan
      updatedPlan.push({
        title: newPlan
      })
      setEating(prev => ({...prev, plan: updatedPlan}))

    } catch (err) {

      console.log("Error asking for updating message")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    
    } finally {
      setStatus("idle")
    }
  }

  const clickToContinue = () => {

    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    addPlan()

    registerAction(+cookies.get("userId"), "JunkFoodContinue", auth, cookies)
    clickNext()
  }
  
  const clickToContinueWithoutAction = () => {

    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    registerAction(+cookies.get("userId"), "JunkFoodContinueWithoutAction", auth, cookies)
    clickNext()
  }

  const setAlternativeChoice = (id) => {
    setFormData({text: ""})
    setAlternative(prev => (prev !== id) ? id : null)
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target

    setAlternative(null)

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
 
  return (
    <div className={"container-simple"}>
      <div className="top-margin-m icon-center">
        <img className="medium-logo" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4 bottom-margin-xs">Plano em mente</p> 
        <p class="body bottom-margin-m">
          Quando der vontade de comer algo não saudável, eu vou...
        </p>
      </div>

      <div className="tags-vertical-menu-stretch top-margin-xs bottom-margin-s">
        <button className={(alternative === 0) ? "tag-selected":"tag-filter"}
          onClick={()=>setAlternativeChoice(0)}>{alternatives.at(0)}</button>

        <button className={(alternative === 1) ? "tag-selected":"tag-filter"}
          onClick={()=>setAlternativeChoice(1)}>{alternatives.at(1)}</button>

        <button className={(alternative === 2) ? "tag-selected":"tag-filter"}
          onClick={()=>setAlternativeChoice(2)}>{alternatives.at(2)}</button>
      
        <button className={(alternative === 3) ? "tag-selected":"tag-filter"}
          onClick={()=>setAlternativeChoice(3)}>{alternatives.at(3)}</button>
      
        <textarea name="text" className="profile-question-form-rounded form-full-width shorter-form" 
          onChange={handleChange} type="text" placeholder="Outra coisa"
          value={formData.text}/> 

      </div>



      <div className="top-margin-m bottom-margin-l" style={{textAlign: 'right'}}>
        <Button type="primary" onClick={()=>{clickToContinue()}}>{(status==="idle") ? "Próximo" : "Enviando"}</Button>
        <Button type="tertiary left-margin-xs" onClick={()=>{clickToContinueWithoutAction()}}>Agora não</Button>
      </div>
      
    </div>
  )
}
