import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js'
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter } from "react-router-dom"
import ScrollToTop from './ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </AuthProvider>
)
