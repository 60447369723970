import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { registerAction } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import Button from '../components/Button'
import ProgressBar from '../components/ProgressBar'
import AuthContext from '../context/AuthProvider'

export default function WeeklyChallenge() {
  
  const { eating, setEating } = React.useContext(PortalContext)
  const { auth, cookies } = React.useContext(AuthContext)

  const [addExercise, setAddExercise] = React.useState(false)
  const [addSuperHealthy, setAddSuperHealthy] = React.useState(false)
  const [addHealthy, setAddHealthy] = React.useState(false)
 
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (!location?.state?.search?.activity) {
      navigate('/profile')
    }
    if (!eating) {
      navigate('/profile')
    } else {

      let updatedWeeklyChallenge = eating.weekly_challenge

      if (location?.state?.search?.activity.toLowerCase() === 'exercise') {
        setAddExercise(true)
        updatedWeeklyChallenge = {...updatedWeeklyChallenge, exercise_executed: updatedWeeklyChallenge.exercise_executed+1}
      } else if (location?.state?.search?.activity.toLowerCase() === 'super healthy') {
        setAddSuperHealthy(true)
        updatedWeeklyChallenge = {...updatedWeeklyChallenge, super_healthy_executed: updatedWeeklyChallenge.super_healthy_executed+1}
      } else if (location?.state?.search?.activity.toLowerCase() === 'healthy') {
        setAddHealthy(true)
        updatedWeeklyChallenge = {...updatedWeeklyChallenge, healthy_executed: updatedWeeklyChallenge.healthy_executed+1}
      }

      const challengeCompleted = (updatedWeeklyChallenge.exercise_executed >= updatedWeeklyChallenge.exercise &&
                updatedWeeklyChallenge.super_healthy_executed >= updatedWeeklyChallenge.super_healthy &&
                updatedWeeklyChallenge.healthy_executed >= updatedWeeklyChallenge.healthy)

      updatedWeeklyChallenge = {...updatedWeeklyChallenge, complete: challengeCompleted }

      setEating(prev => ({...prev, weekly_challenge: updatedWeeklyChallenge}))
    }

    registerAction(+cookies.get("userId"), "updateWeeklyChallenge", auth, cookies)
  }, [])

  if (eating === null || eating?.weekly_challenge === null) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }

  return (
    <div className={"container-simple"}>
      <div className="top-margin-m bottom-margin-s icon-center">
        <img className="medium-logo" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4">Desafio semanal</p>
      </div>
      <div className="bottom-margin-s">
        <p className="body bottom-margin-xs">
          <strong>Exercícios:</strong> {eating.weekly_challenge.exercise_executed}/{eating.weekly_challenge.exercise}
        </p>
        <ProgressBar 
          executed={eating.weekly_challenge.exercise_executed} 
          planned={eating.weekly_challenge.exercise} 
          add={addExercise}/>
      </div>
      
      <div className="bottom-margin-m">
        <p className="body bottom-margin-xs">
          <strong>Comidas saudáveis:</strong> {eating.weekly_challenge.healthy_executed}/{eating.weekly_challenge.healthy}
        </p>
        <ProgressBar 
          executed={eating.weekly_challenge.healthy_executed} 
          planned={eating.weekly_challenge.healthy} 
          add={addHealthy}/>
      </div>

      <div className="bottom-margin-s">
        <p className="body bottom-margin-xs">
          <strong>Comidas super-saudáveis:</strong> {eating.weekly_challenge.super_healthy_executed}/{eating.weekly_challenge.super_healthy}
        </p>
        <ProgressBar 
          executed={eating.weekly_challenge.super_healthy_executed} 
          planned={eating.weekly_challenge.super_healthy} 
          add={addSuperHealthy}/>
      </div>

      <div style={{textAlign: 'right'}}>
        <Button type="primary top-margin-s" link={true} to={"/profile"}>
          Próximo
        </Button>
      </div>

    </div>
  )
}
