import React from 'react'

export default function ProgressBar({executed, planned, add}) {

  const [progress, setProgress] = React.useState((executed/planned)*100);
  const [progressColor, setProgressColor] = React.useState('#ff0000');
  
  React.useEffect(()=>{
    if ((progress + 20) < 40) {
      setProgressColor('#F1A90C')
    } else if ((progress + 20) < 70) {
      setProgressColor('#53A700')
    } else {
      setProgressColor('#00573F')
    }

  }, [progress])
  
  React.useEffect(()=>{

    if (add) {
      setProgress(100*((executed+1)/planned))
    }

  })

  return (
    <div style={{
      display: "flex", 
      flexDirection: "column", 
      justifyContent: "center", 
      alignItems: "center",
      maxWidth: "600px",
    }}>
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{
          width: `${progress}%`,
          backgroundColor: progressColor
        }}>
        </div>
      </div>
    </div>


  )
}
