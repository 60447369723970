import React from 'react'
import { useNavigate } from "react-router-dom"
import RemoveIcon from '../../images/remove.svg'
import EditIcon from '../../images/edit.svg'
import Button from '../Button'
import { putRequestToApi } from '../../api'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import HighlightedMessage from '../HighlightedMessage'
import Attention from "../../images/sun.svg"

export default function PlanThumbnail({ item, i, deletePlan, icons=true }) {

  const [edit, setEdit] = React.useState(false)
  const [status, setStatus] = React.useState('idle')
  const [formData, setFormData] = React.useState({title: item.title})
  const [error, setError] = React.useState(null)

  const [breakComment, setBreakComment] = React.useState(false)
  const [breakCommentButton, setBreakCommentButton] = React.useState(false)
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  
  const { 
    eating, 
    setEating
  } = React.useContext(PortalContext);
 
  const navigate = useNavigate()
  
  React.useEffect(()=>{
    if (item.title.length > 200) {
      setBreakComment(true)
      setBreakCommentButton(true)
    }
  }, [])
  
  function breakCommentButtonClicked() {
    setBreakComment(prev=>!prev)
  }
  
  async function submitEdition() {

    setError(null)
    if (formData.title !== "") {
      setStatus("submitting")
      try {
        const requestBody = {
          record_id: item.id,
          id: +cookies.get("userId"), 
          name: formData.title
        }
        const data = await putRequestToApi(requestBody, 'food/plan', auth, cookies)
        
        setAuth(prev => ({
          ...prev, 
          accessToken: data.tokens.accessToken,
          refreshToken: data.tokens.refreshToken
        })) 

      } catch (err) {
        console.log("Error loading forum data")
        navigate('/loginrequired')

      } finally {

        const planUpdated = { ...item, title: formData.title }
        const eatingPlan = eating.plan.filter(plan => plan.id !== item.id)
        eatingPlan.push(planUpdated)
        setEating(prev => ({...prev, plan: eatingPlan}))

        setEdit(false)
        setStatus("idle")
      }
    } else {
      setError("Por favor, preencha todos os campos")
    }
  }
  
  function handleSubmit(e) {
    e.preventDefault()

    submitEdition()
  }
  
  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  if (edit) {
    return (
      <div className="editable-item-for-card-green top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">     
          {(error) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />
            </div> :
            null
          }
          
          <form onSubmit={handleSubmit}>
            <div>
              <div className="bottom-margin-xs top-margin-xs">
                <textarea id="answer" name="title" className="comment-form" type="text" 
                    placeholder="Seu planejamento" onChange={handleChange} value={formData.title}/>
              </div>
              <Button type="primary">{(status==="idle") ? "Editar" : "Enviando..."}</Button>
              <Button type="secondary left-margin-xs" onClick={()=>setEdit(prev=>!prev)}>{"Cancelar"}</Button>
            </div>
          </form>
      </div>
    )
  }

  return (
    <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
      <div className='editable-item-for-card-header'>
        <div>
          <p className="body content">{(breakComment) ? `${item.title.slice(0,100)}...` : item.title }</p>
          {(breakCommentButton) ? 
            <Button type="tertiary top-margin-xs bottom-margin-xs" onClick={()=>breakCommentButtonClicked()}>
              {(breakComment) ? 'Mostrar mais' : 'Mostrar menos' }
            </Button> : null
          }
        </div>
        {(item.id && icons) ?
        <div className="row-svg-icons left-margin-xs">
          <button onClick={()=>{setEdit(prev=>!prev)}} className='carousel-btn'>
            <img className="" src={EditIcon}/>
          </button>
          <button onClick={()=>{deletePlan(item.id)}} className='carousel-btn'>
            <img className="" src={RemoveIcon}/>
          </button>
        </div> : null }
      </div>
    </div>
  )
}
