import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import Button from "../Button"
import { postRequestToApi, registerAction } from "../../api"
import AuthContext from "../../context/AuthProvider"
import EditableItemsCard2 from "../EditableItemsCard2"
import EnergyExpenditureCard from "../Eating/EnergyExpenditureCard"
import { useNavigate } from "react-router-dom"

export default function WeightLossMessage({ clickNext }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { eating, setEating } = React.useContext(PortalContext)

  const [status, setStatus] = React.useState('idle')

  const navigate = useNavigate()

  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "weightLossMessagePage", auth, cookies)
  }, [])
  
  const clickToContinue = () => {
    // update objective check in flag
    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    registerAction(+cookies.get("userId"), "weightLossMessageContinue", auth, cookies)

    clickNext()
  }
  
  const clickToContinueWithoutAction = () => {
    // update objective check in flag
    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    registerAction(+cookies.get("userId"), "weightLossMessageContinueWithouAction", auth, cookies)

    clickNext()
  }
  
  async function submitUpdatedData(bodyRequest) {
    try {
      setStatus("submitting")
      const data = await postRequestToApi(bodyRequest, '/food/goals', auth, cookies)

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      let prevGoals = eating.goals
      prevGoals = prevGoals.filter(item => (item.manually_added === true))
      const updatedGoals = prevGoals.concat(goals);
      setEating(prev => ({ ...prev, goals: updatedGoals }))

      registerAction(+cookies.get("userId"), `addweightlossobjectivefrommessagepage`, auth, cookies)
      setStatus("idle")
    }
  }

  const addObjectives = () => {
    const requestBody = {
        "proteinTitle": "Consumo diário de proteínas",
        "caloryTitle": "Consumo diário de calorias",
        "proteinContent": proteinsGoal,
        "caloryContent": tdeeGoal
    }

    submitUpdatedData({
      id: +cookies.get("userId"), 
      exam_data: requestBody
    })

    registerAction(+cookies.get("userId"), `addweightlossobjectiveAdd`, auth, cookies)
    clickToContinue()
  }

  let tdeeGoal = null
  let proteinsGoal = null
  let goals = null

  let objectiveProposal = null
  if (eating?.tdee || eating.tdee !== null) {

    tdeeGoal = Math.round(0.8 * +eating.tdee)

    const healthyWeightInKg = 25 * (+eating.height) ** 2 // BMI of 25
    const healthyWeightInPounds = healthyWeightInKg * 2.205

    proteinsGoal = Math.round(healthyWeightInPounds)
    
    goals = [
      {
        "title": "Consumo diário de calorias",
        "content": `Até ${Math.round(0.8 * +eating.tdee)} calorias`,
        "manually_added": false,
      },
      {
        "title": "Consumo diário de proteínas",
        "content": `Cerca de ${Math.round(healthyWeightInPounds)} gramas`,
        "manually_added": false,
      }
    ]

    objectiveProposal = (
      <div>
        <EditableItemsCard2 
          clsName="bottom-margin-s" 
          title="Objetivos para perda de peso"
          icons={false}
        >
          {goals.map((item,i) => (
            <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p>
              </div>
            </div>
          ))}
        </EditableItemsCard2>
 
        <div className="top-margin-m bottom-margin-l" style={{textAlign: 'right'}}>
          <Button type="primary" onClick={()=>{addObjectives()}}>
            {(status==="idle") ? "Adicionar objetivos" : "Adicionando..." }
          </Button>
          <Button type="tertiary left-margin-xs" onClick={()=>{clickToContinueWithoutAction()}}>Agora não</Button>
        </div>
      </div> 
    )
  }

  return (
    <div className={"container-simple"}>
      <div className="top-margin-m icon-center">
        <img className="medium-logo" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4 bottom-margin-xs">Quantas calorias por dia?</p> 
        <p class="body bottom-margin-m">
          Vamos calcular quanto você pode comer para perder peso
        </p>
      </div>


      <div className="bottom-margin-s">
        <EnergyExpenditureCard />
      </div>

      {(eating?.tdee || eating.tdee !== null) ? 
        objectiveProposal : 
        <div className="top-margin-m bottom-margin-l" style={{textAlign: 'right'}}>
          <Button type="tertiary left-margin-xs" onClick={()=>{clickToContinueWithoutAction()}}>Agora não</Button>
        </div>
      }

    </div>
  )
}
