import React from 'react';
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import { PortalContext } from '../AuthenticatedUser'
import AuthContext from "../context/AuthProvider"
import { useNavigate } from 'react-router-dom';
import { deleteDataFromApi, registerAction } from '../api';
import Bulb from "../images/bulbDark.svg"
import HighlightedMessage from '../components/HighlightedMessage';

export default function UserSavedMessages() {

  const { auth, cookies } = React.useContext(AuthContext);
  const {
    loadEatingDataFromAPI,
    sequenceLength,
    league,
    eating,
    savedMessages, 
    setSavedMessages,
    loadingSavedMessages,
    hasMoreSavedMessages,
    setSavedMessagePage,
    setLoadingSavedMessages,
    setHasMoreSavedMessages,
    loadSavedMessages
  } = React.useContext(PortalContext);
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    if (!cookies.get("userId")) {
      navigate("/family")
    } else {
      if (!eating) {
        loadEatingDataFromAPI()
      }

      resetMessages()
    }
    registerAction(+cookies.get("userId"), "savedmessagespage", auth, cookies)
  }, [])

  const resetMessages = async () => {

    if (savedMessages.length === 0) {
      // reset saved page to 0
      setSavedMessagePage(0)
      // reset saved messages to []
      setSavedMessages([])
      // reset hasMore and loadingSave to true
      setLoadingSavedMessages(true)
      setHasMoreSavedMessages(true)

      // ask to load message 
      await loadSavedMessages(0)
    }
  }
  
  const observer = React.useRef()
  const lastMessageElementRef = React.useCallback(node =>
    {
      if (loadingSavedMessages) return
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreSavedMessages) {
          setSavedMessagePage(prevPageNumber => prevPageNumber + 1)
          console.log('message visible')
        }
      })
      if (node) observer.current.observe(node)

    }, [loadingSavedMessages, hasMoreSavedMessages])
 
  async function deleteMessageFromBackend(requestBody) {

    const response = await deleteDataFromApi(requestBody, "/message", auth, cookies)

  }

  const messageRemove = (id) => {
    
    setSavedMessages(prev => [...prev.filter(item => item.id !== id)])

    const requestBody = {
        id: +cookies.get("userId"),
        item_id: id 
    }

    deleteMessageFromBackend(requestBody)
  }
  
  if (!savedMessages) {
    return (
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}
        style={{height: "100vh"}}
      >
        <p>Carregando mensagens...</p>
      </div>
    )
  }

  // avoid duplicates
  const visibleItemsIds = []
  const messagesToRender = []

  savedMessages.forEach(message => {
    if (visibleItemsIds.includes(message.id)) {
      return null
    } else {
      visibleItemsIds.push(message.id)
      messagesToRender.push(message)
    }
  })

  return (
    (!eating || !eating?.history) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
      :
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={false} 
          menuItems="profile" 
          id={+cookies.get("userId")} 
          menuShow={true}
          selected="user-home"
          sequenceLength={sequenceLength}
          hasObjectives={(eating && eating?.goals) ? eating.goals.length > 0 : false}
          league={(league?.is_in_current_league) ? league.is_in_current_league : false}
        />
      </div>

      <div className={"container-simple"} style={{height: "100vh"}}>
        <p className="florence-title top-margin-s bottom-margin-m"> 
          Mensagens salvas 
        </p>

        { (messagesToRender.length === 0 && !hasMoreSavedMessages) ?
             (
              <div className="icon-loading-partial top-margin-m"
              >
                <p className="body secondary-color top-padding-l">Nenhuma mensagem salva ainda</p> 
              </div>
            ) : null
        }

        {(messagesToRender.length === 0 && loadingSavedMessages) ? 
          <div className="icon-loading-partial top-margin-m">
            <img className="small-logo-nav loading-icon" 
                src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
            <p>Carregando mensagens...</p> 
          </div>
          : null
        }
      
        { 
          messagesToRender.map((message, index) => {
            if (messagesToRender.length === index + 1) { // last message
              return (
                <>
                  <div ref={lastMessageElementRef} key={message.id}>
                    <HighlightedMessage
                      icon={Bulb} type="message" 
                      header="Mensagem da Florence" 
                      body={ message.message } 
                      messageId={ message.id }
                      allowRemove={true}
                      onRemove={() => messageRemove(message.id)}
                      highlightedImage = { message.image }
                    />
                  </div>
                  {(loadingSavedMessages) ? 
                    <div className="icon-loading-partial top-margin-m">
                      <img className="small-logo-nav loading-icon" 
                          src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
                      <p>Carregando mensagem...</p> 
                    </div>
                    : null
                  }
                </>
              )
            } else {
              return (
                <HighlightedMessage
                  icon={Bulb} type="message" 
                  header="Mensagem da Florence" 
                  body={ message.message } 
                  messageId={ message.id }
                  allowRemove={true}
                  onRemove={() => messageRemove(message.id)}
                  highlightedImage = { message.image }
                />
              )
            }
          }) 
        }

      </div>
    </>
  )
}
