import React from 'react'
import { useNavigate } from "react-router-dom"
import EnergyExpenditureCard from './EnergyExpenditureCard'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import EditableItemsCard2 from '../EditableItemsCard2'
import Button from '../Button'
import { postRequestToApi, registerAction } from '../../api'

import Pilates1 from '../../images/pilates-1.svg'
import Pilates2 from '../../images/pilates-2.svg'
import Pilates3 from '../../images/pilates-3.svg'
import Pilates4 from '../../images/pilates-4.svg'
import Pilates5 from '../../images/pilates-5.svg'
import Pilates6 from '../../images/pilates-6.svg'
import Pilates7 from '../../images/pilates-7.svg'
import Pilates8 from '../../images/pilates-8.svg'
import Pilates9 from '../../images/pilates-9.svg'
import Pilates10 from '../../images/pilates-10.svg'
import Pilates11 from '../../images/pilates-11.svg'

export default function WeightLossThumbnail() {
  
  const { 
    eating,
    setEating
  } = React.useContext(PortalContext);

  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [imageIndex1, setImageIndex1] = React.useState(0)
  const [status, setStatus] = React.useState('idle')
  
  const navigate = useNavigate()

  React.useEffect(() => {
    setImageIndex1(Math.floor(Math.random() * 11))
  }, []);
  
  async function submitUpdatedData(bodyRequest) {
    try {
      setStatus("submitting")
      const data = await postRequestToApi(bodyRequest, '/food/goals', auth, cookies)

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      let prevGoals = eating.goals
      prevGoals = prevGoals.filter(item => (item.manually_added === true))
      const updatedGoals = prevGoals.concat(goals);
      setEating(prev => ({ ...prev, goals: updatedGoals }))

      registerAction(+cookies.get("userId"), `addweightlossobjectivefromthumbnail`, auth, cookies)
      setStatus("idle")
    }
  }

  const addObjectives = () => {
    const requestBody = {
        "proteinTitle": "Consumo diário de proteínas",
        "caloryTitle": "Consumo diário de calorias",
        "proteinContent": proteinsGoal,
        "caloryContent": tdeeGoal
    }

    //const goalsUpdated = eating.goals
    //goalsUpdated.push(...goals)
    //setEating(prev => ({...prev, goals: goalsUpdated})) 

    submitUpdatedData({
      id: +cookies.get("userId"), 
      exam_data: requestBody
    })

  }

  let tdeeGoal = null
  let proteinsGoal = null
  let goals = null

  let objectiveProposal = null
  if (eating?.tdee || eating.tdee !== null) {
    const id = +cookies.get("userId")

    tdeeGoal = Math.round(0.8 * +eating.tdee)

    const healthyWeightInKg = 25 * (+eating.height) ** 2 // BMI of 25
    const healthyWeightInPounds = healthyWeightInKg * 2.205

    proteinsGoal = Math.round(healthyWeightInPounds)
    
    goals = [
      {
        "title": "Consumo diário de calorias",
        "content": `Até ${Math.round(0.8 * +eating.tdee)} calorias`,
        "manually_added": false,
      },
      {
        "title": "Consumo diário de proteínas",
        "content": `Cerca de ${Math.round(healthyWeightInPounds)} gramas`,
        "manually_added": false,
      }
    ]

    objectiveProposal = (
      <div>
        <EditableItemsCard2 
          clsName="bottom-margin-s" 
          title="Objetivos para perda de peso"
          icons={false}
        >
          {goals.map((item,i) => (
            <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p>
              </div>
            </div>
          ))}
          <Button type="tertiary secondary-background-color left-margin-s top-margin-xs" 
            link={true} to='/eating/objective/weigthloss'>
            Saber mais
          </Button>
        </EditableItemsCard2>

        <Button type="primary" onClick={()=>{addObjectives()}}>
          {(status==="idle") ? "Adicionar objetivos" : "Adicionando..." }
        </Button>
      </div> 
    )
  }
  
  const waitImage = (index) => (
    <div className="submit-column top-margin-s horizontal-padding-s">
      {(index === 0) ? <img className="submission-image" src={Pilates1} alt="Submission image"/> :
      (index === 1) ? <img className="submission-image" src={Pilates2} alt="Submission image"/> :
      (index === 2) ? <img className="submission-image" src={Pilates3} alt="Submission image"/> :
      (index === 3) ? <img className="submission-image" src={Pilates4} alt="Submission image"/> :
      (index === 4) ? <img className="submission-image" src={Pilates5} alt="submission image"/> :
      (index === 5) ? <img className="submission-image" src={Pilates6} alt="submission image"/> :
      (index === 6) ? <img className="submission-image" src={Pilates7} alt="submission image"/> :
      (index === 7) ? <img className="submission-image" src={Pilates8} alt="submission image"/> :
      (index === 8) ? <img className="submission-image" src={Pilates9} alt="submission image"/> :
      (index === 9) ? <img className="submission-image" src={Pilates10} alt="submission image"/> :
      <img className="submission-image" src={Pilates11} alt="submission image"/> }
      <p className="heading5 secondary-color top-margin-xs">Objetivo já foi adicionado!</p>
      <p className="body secondary-color top-margin-xs bottom-margin-s" style={{textAlign: "center"}}>Mas aproveite que você tem um minuto para esticar o corpo e alongar</p>
    </div>
  )

  let weightLossObjectives = eating.goals.filter(item => {
      if (item?.title) {
        return item.content.toLowerCase().includes(`${Math.round(0.8 * +eating.tdee)}`)
      } else {
        return false
      }
    })
  if (weightLossObjectives.length > 0) {
    objectiveProposal = (
      <div>
        <EditableItemsCard2 
          clsName="bottom-margin-s" 
          title="Objetivos para perda de peso"
          icons={false}
        >
          {waitImage(imageIndex1)}
        </EditableItemsCard2>
      </div> 
    )
  }

  return (
    <div className="top-margin-m bottom-margin-m">
      <p className="heading4 bottom-margin-xs"><strong>Perda de peso</strong></p>

      <div className="bottom-margin-s">
        <EnergyExpenditureCard />
      </div>

      {(eating?.tdee || eating.tdee !== null) ? 
        objectiveProposal : 
        null 
      }
    </div>
  )
}
