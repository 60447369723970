import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import forumIcon from "../images/forum-black.svg"
import pillIcon from "../images/pill-black.svg"
import FoodIcon from "../images/bowl-black.svg"
import ExerciseIcon from "../images/awards-black.svg"
import Button from '../components/Button';
import { postRequestToApi, registerAction } from '../api';
import AuthContext from "../context/AuthProvider"

export default function Tutorial() {

  const { cookies, auth } = React.useContext(AuthContext);

  const [slide, setSlide] = React.useState(0)

  //const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()
  //const location = useLocation()

  const name = cookies.get("userName") 

  cookies.set('pageOfInterest', null, { path: '/' })

  React.useEffect(() => { 
    if (!cookies.get("userId")) {
      navigate("/family")
    }
    registerAction(+cookies.get("userId"), "tutorial", auth, cookies)
  }, [])

  const userSex = cookies.get("userSex")
  
  const forum = (
    <div>
      <div className="large-button-link-no-border">
        <img src={forumIcon} alt="botão para fórum"/>
        <p className="body bottom-margin-xs">Fórum</p>
        <p className="body secondary-color">Tire suas dúvidas sobre saúde 
          e ajude a responder as dúvidas de outras pessoas em nossa comunidade</p> 
        <img className="carousel-screenshot top-margin-m" 
          src={(tabletBreakpoint) ? require(`../images/forum-okt.png`) : require(`../images/tutorial-forum.png`)} 
          alt="Screenshot demonstrating functionality of the software"/>
      </div>
    </div>
  )
  const food = (
    <div>
      <div className="large-button-link-no-border">
        <img src={FoodIcon} alt="botão para fórum"/>
        <p className="body bottom-margin-xs">Alimentação</p>
        <p className="body secondary-color">Acompanhe sua alimentação e atinja seus objetivos 
        com a ajuda da Florence. Adicionar um alimento é tão simples quanto tirar uma foto!</p> 
        <img className="carousel-screenshot top-margin-m" 
          src={(tabletBreakpoint) ? require(`../images/food-okt.png`) : require(`../images/tutorial-food.png`)} 
          alt="Screenshot demonstrating functionality of the software"/>
      </div>
    </div>
  )
  const exercise = (
    <div>
      <div className="large-button-link-no-border">
        <img src={FoodIcon} alt="botão para fórum"/>
        <p className="body bottom-margin-xs">Exercício</p>
        <p className="body secondary-color">Acompanhe seus exercícios e sua evolução. Você pode 
        tirar foto da folha de exercícios da academia, de você praticando a atividade, ou descrevê-la. É super fácil!</p> 
        <img className="carousel-screenshot top-margin-m" 
          src={(tabletBreakpoint) ? require(`../images/exercise-okt.png`) : require(`../images/tutorial-exercise.png`)} 
          alt="Screenshot demonstrating functionality of the software"/>
      </div>
    </div>
  )
  const health = (
    <div>
      <div className="large-button-link-no-border">
        <img src={FoodIcon} alt="botão para fórum"/>
        <p className="body bottom-margin-xs">Saúde</p>
        <p className="body secondary-color">Se você toma algum medicamento, a Florence te ajuda a lembrar!
        Basta adicionar seu medicamento ou prescrição médica</p> 
        <img className="carousel-screenshot top-margin-m" 
          src={(tabletBreakpoint) ? require(`../images/tutorial-medication-l.png`) : require(`../images/tutorial-medication-s.png`)} 
          alt="Screenshot demonstrating functionality of the software"/>
      </div>
    </div>
  )
  const options = (
    <div>
      <div className="large-button-link-no-border">
        <p className="body secondary-color bottom-margin-s">Adicione sua primeira refeição ou atividade física, 
        isso vai fazer você começar com o pé direito em direção aos seus objetivos</p>
        <div className="bottom-margin-s">
          <Button type="primary" link={true} to='/eating/submit'>Adicionar refeição</Button>

          <Button type="primary left-margin-xs" link={true} to='/exercise/submit'>Adicionar exercício</Button>
        </div>
        <Button type="tertiary" link={true} to='/profile'>Ir para perfil</Button>
      </div>
    </div>
    
  )

  const goToProfileAndUpdateTutorialFlag = () => {
    //const requestBody = {
    //  id: +cookies.get("userId")
    //}
    //postRequestToApi(requestBody, '/tutorialseen', auth, cookies)
    navigate('/profile')
  }

  const nextSlide = () => {
    setSlide(prev => prev + 1)
  }

  const content = (
    <section>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        {(slide !== 4) ?
        <>
          <h1 className="florence-title top-margin-xl bottom-margin-xs">
            {(userSex === "Feminino" || userSex === "feminino") ? `Seja bem vinda, ${name}!` : `Seja bem vindo, ${name}!`}
          </h1>
          <p className="body secondary-color bottom-margin-xl">Veja como aproveitar ao máximo nosso portal</p>
        </> :
        <h1 className="florence-title top-margin-xl bottom-margin-s">
          O que você quer fazer primeiro?
        </h1>
        }
        {(slide === 0) ? 
          forum : (slide === 1) ? 
            food : (slide === 2) ? 
              exercise : (slide === 3) ? 
                health : (slide === 4) ?
                  options : null }
        {(slide !== 4) ?
        <div className="top-margin-m">
          <Button type='primary' onClick={()=>nextSlide()}>Legal! Próximo</Button>
          <Button type='tertiary left-margin-s bottom-margin-xl' onClick={()=>{goToProfileAndUpdateTutorialFlag()}}>Já sei, pular</Button>
        </div> : null }
      </div>
    </section>
  )

  return (
    <>
      <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={false} menuItems="profile" id={+cookies.get("userId")} menuShow={false}/>
      </div>

      {content}
      </>
    </>
  )
}
