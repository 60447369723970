import React from 'react'

export default function Tag({ color, children, remove }) {
  // correcting the writing in the tag
  if (children[0]==="pressao") {
    children = "Pressão Alta"
  } else if (children[0]==="cancer") {
    children = "Câncer"
  } else if (children[0]==="exercicio") {
    children = "Exercício"
  } else if (children[0]==="alimentacao") {
    children = "Alimentação"
  }
  return (
    <div>
      <div className='tag-container'>
        <p className={`small-title tag-${color}`}>{children}</p>
      </div>
      {(remove) ? 
        <div>
          <button className='secondary-text svg-btn' type="button" onClick={remove}>Excluir</button>
        </div> 
        : null
      }
    </div>
  )
}
