import React from "react"
import { isToday } from "date-fns"
import Button from "../Button"
import FireWhite from "../../images/fire-white.svg"
import { registerAction } from "../../api";
import { PortalContext } from "../../AuthenticatedUser";

export default function SequenceMessage({ itemDate, clickNext, sequence }) {
  
  const { auth, cookies } = React.useContext(PortalContext);

  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "sequenceMessagePage", auth, cookies)
  }, []);

  if ((!itemDate || isToday(itemDate)) 
    && sequence.length > 0 && sequence.today === false) {

    let sequenceText1 = `${sequence.length+1} dias em sequência`

    return (
      <div className="background-hot">
        <div className="container-column">
          <div className="icon-loading-middle-page">
            <div className="centered-column-text color-hot">
                <div className="nav-icon-red-background right-padding-xs">
                  <div className="icon-row">
                    <img className="big-icon-page icon-nav-left-padding" src={FireWhite} alt="Florence logo"/>
                    <p className="typography-big-icon sans-serif-typeface typeface-no-margin-red right-padding-xxs">{sequence.length+1}</p>
                  </div>
                </div>

                <p className="florence-title top-margin-s bottom-margin-xxs">
                  Você conseguiu! 
                </p>
                <p className="heading4 bottom-margin-m">
                  {sequenceText1} 
                </p>

              <Button type="primary" onClick={()=>{clickNext()}}>Próximo</Button>
            </div>
          </div>
        </div>
      </div>
    )
  } else if ((!itemDate || isToday(itemDate)) 
    && sequence.length === 0) {
    let sequenceText1 = `Esse é seu primeiro dia na sequência`


    return (
      <div className="background-hot">
        <div className="container-column">
          <div className="icon-loading-middle-page">
            <div className="centered-column-text color-hot">
                <div className="nav-icon-red-background right-padding-xs">
                  <div className="icon-row">
                    <img className="big-icon-page icon-nav-left-padding" src={FireWhite} alt="Florence logo"/>
                    <p className="typography-big-icon sans-serif-typeface typeface-no-margin-red">1</p>
                  </div>
                </div>
                
                <p className="florence-title top-margin-s bottom-margin-xs">
                  Muito bom! 
                </p>
                <p className="heading4 bottom-margin-xs">
                  {sequenceText1} 
                </p>
                <p className="body bottom-margin-m">A cada dia que você adiciona refeições ou atividades físicas, sua sequência aumenta</p> 

              <Button type="primary" onClick={()=>{clickNext()}}>Próximo</Button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {

    clickNext()
    return null

  }
}
