import React from 'react'
import HighlightedMessage from "../HighlightedMessage"
import Button from "../Button"
import Attention from "../../images/sun.svg"
import { useMediaQuery } from 'react-responsive'

export default function MedicationForm({ setAddMedication, submitNewItem }) {

  const [formData, setFormData] = React.useState({
    name: "", frequency: "", frequencyManual: "", 
    morning: false, afternoon: false, night: false, otherTime: ""})

  const [otherFrequency, setOtherFrequency] = React.useState(false)
  const [otherTime, setOtherTime] = React.useState(false)
  const [error, setError] = React.useState(null)
  
  const smallerBreakpoint = useMediaQuery({ query: '(min-width: 300px)' })
  const smallBreakpoint = useMediaQuery({ query: '(min-width: 350px)' })

  const userRef = React.useRef()

  // go to the top of the page
  //React.useEffect(() => {
  //  userRef.current.focus()
  //}, []);

  const handleSubmit = (e) => {
    e.preventDefault()

    if (formData.name !== "" && 
      (formData.frequency || formData.frequencyManual) && 
      (formData.morning || formData.afternoon || formData.night || formData.otherTime !== "")
    ) {
      setError(null)

      const newMedication = {
        name: formData.name,
        frequency: (formData.frequency) ? formData.frequency : formData.frequencyManual,
        morning: formData.morning,
        afternoon: formData.afternoon,
        night: formData.night,
        otherTime: formData.otherTime
      }

      submitNewItem('medication', newMedication)
      setAddMedication() // close add card
    } else {
      setError("Por favor, preencha todos os dados para adicionar o medicamento.")
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  const handleChangeOnOff = (e) => {
    const { name, value } = e.target
    
    const oldValue = formData[name]
    console.log(oldValue)

    setFormData(prev => ({
      ...prev,
      [name]: !oldValue
    }))
  }

  const changeFrequencyInputForm = () => {
    if (!otherFrequency) {
      setFormData(prev => ({...prev, frequency: ""}))
    } else {
      setFormData(prev => ({...prev, frequencyManual: ""}))
    }
    setOtherFrequency(prev=>!prev)
  }

  const changeTimeInputForm = () => {
    if (!otherTime) {
      setFormData(prev => ({...prev, morning: false, afternoon: false, night: false}))
    } else {
      setFormData(prev => ({...prev, otherTime: ""}))
    }
    setOtherTime(prev=>!prev)
  }

  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
      {(error) ?
        <div className="">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }
      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-xs">Nome do medicamento</p>
        <input
          className="input-text bottom-margin-xs"
          ref = {userRef}
          name="name"
          onChange={handleChange}
          type="text"
          value={formData.name}
        />

        <p className="body">Frequência</p>
        {(!otherFrequency) ?
          <>
            <div className={(smallerBreakpoint) ? 
            "row-many-radio-buttons bottom-margin-xs" : "row-radio-buttons bottom-margin-xs"}>
              <div className="closed-radio-button">
                <label className="body" htmlFor="male">1x por dia</label>
                <input
                  name="frequency"
                  id = "1x"
                  onChange={handleChange}
                  type="radio"
                  value="1x por dia"
                />
              </div>
              <div className="closed-radio-button">
                <label className="body" htmlFor="female">2x por dia</label>
                <input
                  className="radio"
                  name="frequency"
                  id = "2x"
                  onChange={handleChange}
                  type="radio"
                  value="2x por dia"
                />
              </div>
              <div className="closed-radio-button">
                <label className="body" htmlFor="undefined">3x por dia</label>
                <input
                  className="radio"
                  name="frequency"
                  id = "3x"
                  onChange={handleChange}
                  type="radio"
                  value="3x por dia"
                />
              </div>
              <div className="closed-radio-button radio-large">
                <label className="body" htmlFor="undefined">Tomo eventualmente</label>
                <input
                  className="radio"
                  name="frequency"
                  id = "eventualmente"
                  onChange={handleChange}
                  type="radio"
                  value="Tomo eventualmente"
                />
              </div>
            </div>
          </> :
          <>
            <input
              className="input-text bottom-margin-xs"
              ref = {userRef}
              name="frequencyManual"
              onChange={handleChange}
              type="text"
              value={formData.frequencyManual}
            />
            <Button buttonType="button" onClick={()=>changeFrequencyInputForm()} type="tertiary bottom-margin-xs">Retornar aos botões</Button>
          </>
        }

        <p className="body">Em quais horários</p>
        {(!otherTime) ?
          <>
            <div className={(smallBreakpoint) ? 
            "row-many-radio-buttons bottom-margin-xs" : "row-radio-buttons bottom-margin-xs"}>
              <div className="closed-radio-button">
                <label className="body" htmlFor="manha">Manhã</label>
                <input
                  name="morning"
                  id = "manha"
                  onClick={handleChangeOnOff}
                  type="radio"
                  checked={formData.morning === true}
                />
              </div>
              <div className="closed-radio-button">
                <label className="body" htmlFor="tarde">Tarde</label>
                <input
                  name="afternoon"
                  id = "tarde"
                  onClick={handleChangeOnOff}
                  type="radio"
                  checked={formData.afternoon === true}
                />
              </div>
              <div className="closed-radio-button">
                <label className="body" htmlFor="noite">Noite</label>
                <input
                  name="night"
                  id = "noite"
                  onClick={handleChangeOnOff}
                  type="radio"
                  checked={formData.night === true}
                />
              </div>
            </div>
          </> :
          <>
            <input
              className="input-text bottom-margin-xs"
              ref = {userRef}
              name="otherTime"
              onChange={handleChange}
              type="text"
              value={formData.otherTime}
            />
            <Button buttonType="button" onClick={()=>changeTimeInputForm()} type="tertiary bottom-margin-xs">
              Retornar aos botões
            </Button>
          </>
        }

        <div className="top-margin-s">
          <Button type="primary right-margin-s bottom-margin-s">
            Criar
          </Button>
          <Button buttonType="button" onClick={()=>setAddMedication()}type="tertiary right-margin-s bottom-margin-s">
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  )
}
