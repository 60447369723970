import React from 'react'
import Button from "./Button.js"

export default function HeaderLinks({menuItems, id}) {

  let menuOptions = null
  if (!menuItems) {
    menuOptions = (
      <div className="row-links">
        <Button type="tertiary" link={true} to='/about'>Sobre nós</Button>
        <Button type="tertiary" link={true} to='/forum'>Fórum</Button> 
        <Button type="tertiary" link={true} to='/accessshared'>Dados compartilhados</Button> 

      </div>
    )
  } else if (menuItems === "family") {
    menuOptions = (
      <div className="row-links">
        <Button type="tertiary" navlink={true} navlinkend='/family' to='/family'>Família</Button>
        <Button type="tertiary" navlink={true} to='/family/manage'>Alterar Família</Button>
        <Button type="tertiary" navlink={true} to='/loguserout'>Sair</Button>
      </div>
    )
  } else if (menuItems === "profile") {
    menuOptions = (
      <div className="row-links">
        <Button type="tertiary" navlink={true} to={`/profile`}>Meu perfil</Button>
        <Button type="tertiary" navlink={true} to='/forum'>Fórum</Button>
        <Button type="tertiary" navlink={true} to='/family'>Família</Button>
        <Button type="tertiary" navlink={true} to='/account'>Conta</Button>
        <Button type="tertiary" navlink={true} to='/loguserout'>Sair</Button>
      </div>
    )
  } else if (menuItems === "portal") {
    menuOptions = (
      <div className="row-links">
        <Button type="tertiary" navlink={true} to={`/health`}>Saúde</Button>
        <Button type="tertiary" navlink={true} to={`/exercise`}>Exercícios</Button>
        <Button type="tertiary" navlink={true} to={`/eating`}>Alimentação</Button>
        <Button type="tertiary" navlink={true} to={`/forum`}>Fórum</Button>
        <Button type="tertiary" navlink={true} to={`/family`}>Família</Button>
        <Button type="tertiary" navlink={true} to={`/account`}>Conta</Button>
        <Button type="tertiary" navlink={true} to='/loguserout'>Sair</Button>
      </div>
    )
  } else if (menuItems === "shared") {
    menuOptions = (
      <div className="row-links">
        <Button type="tertiary" navlink={true} to={`/logshareduserout`}>Sair</Button>
      </div>
    )
  }
  return menuOptions
}
