import React from "react"
import { Link, NavLink } from "react-router-dom"

export default function Button({type, onClick, children, navlinkend, navlink=false, link=false, to, stateValue, id, 
  buttonType}) {
  if (navlink) {
    return ((to===navlinkend) ?
      <NavLink 
        className={({isActive}) => isActive ? 
          `btn-${type} menu-link-selected` : `btn-${type}`
        } 
        to={to} end
        state={(stateValue) ? {search: stateValue} : null }>{children}</NavLink>
      :
      <NavLink
        className={({isActive}) => isActive ? 
          `btn-${type} menu-link-selected` : `btn-${type}`
        } 
        to={to}
        state={(stateValue) ? {search: stateValue} : null }>{children}</NavLink>
    )
  }

  return (
    (!link) ?
    <button type={buttonType} onClick={onClick} className={`btn-${type}`}>{children}</button> :
    <Link 
      to={to} 
      className={`btn-${type}`}
      state={(stateValue) ? {search: stateValue} : null }>{children}</Link>
  )
}
