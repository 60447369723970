import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import EditableItemsCard2 from "../components/EditableItemsCard2"
import Calendar2 from "../components/Calendar2"
import Tag from "../components/Tag"
import Button from "../components/Button"
import { format } from "date-fns"
import RemoveIcon from '../images/remove.svg'
import ExerciseThumbnail from './Exercise/ExerciseThumbnail'
import FoodThumbnail from './Eating/FoodThumbnail'
import { registerAction } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import EatingEvolution from './EatingEvolution'

export default function EatingHistory( {calendarItems, title, addItemtoArray, 
  removeExerciseItemFromArray, removeFoodItemFromArray, removeItemFromArray,
  tagged, grade=true, type='eating', setType, icons=true, filters=true, userAdd=true, highlightToday=true,
  confirmedColor="confirmed"
} ) {
  
  // filter initially for today
  const [filter, setFilter] = React.useState(null)

  return (
    <div>
      <div className="container-simple vertical-padding-s calendar-history">

        <Calendar2 
          medicationReminders={calendarItems} 
          dayClicked={filter} 
          onDayClick={()=>{}} 
          type={type} 
          filters={filters}
          category={type}
          setCategory={setType}
          highlightToday={highlightToday}
          confirmedColor={confirmedColor}
        />

      </div>
    </div>
  )
}

