import React from 'react';

export default function WeeklyRanking({ participants, userId }) {

  participants.sort((a, b) => b.score - a.score)

  return(
    <div className="datepicker datepicker-low-padding">

      {participants.map((participant, i) => {
        if (participant.user_id === userId) {
          return (
            <div className="ranking-row-tall row-user" id={participant.user_id} key={participant.user_id}>
              <div className="ranking-position-name">
                <p className={(i>2) ? "body ranking-pos month-name" : `body ranking-pos-${i}`}>
                  {i+1}
                </p>
                <p className="body month-name">
                  {`${participant.first_name} ${participant.last_name[0]}.`}  
                </p>
              </div>
              <p className="body month-name">
                {participant.score}
              </p>
            </div>  
        )

        } else {
          return (
            <div className="ranking-row-tall" id={participant.user_id} key={participant.user_id}>
              <div className="ranking-position-name">
                <p className={(i>2) ? "body ranking-pos" : `body ranking-pos-${i}`}>
                  {i+1}
                </p>
                <p className="body">
                  {`${participant.first_name} ${participant.last_name[0]}.`}  
                </p>
              </div>
              <p className="body">
                {participant.score}
              </p>
            </div>  
        )
      }
      })
    }
    </div>
  )
}
