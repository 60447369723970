import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Header from '../components/Header'
import RegistrationBlock from '../components/RegistrationBlock'
import Policy from './Policy'

export default function Registration() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const [showPolicy, setShowPolicy] = React.useState(false) 
  
  // this cache is in case the user goes to see the policy, when coming back have the form still filled in
  const [formDataCache, setFormDataCache] = React.useState({
    firstName: "", lastName: "", month: "", day: "", year: "", sex: "", email: "", password: ""
  })

  return (
    (showPolicy) ?
      <Policy setShowPolicy={setShowPolicy} />
    :
      <>
        <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
          <Header tabletBreakpoint={false} menuShow={true} loggedIn={false}/>
        </div>

        <div className="container-simple">
          
          <section>
            <h1 className="florence-title top-margin-xl">Crie sua conta</h1>
          </section>
        </div>

        <div className="top-margin-m bottom-margin-l">
          <div className={(tabletBreakpoint) ? 
            "colorful-background-tablet vertical-padding-m" : "colorful-background vertical-padding-m"}>
            <div className="container-simple">
              <RegistrationBlock 
                formDataCache={formDataCache}
                setFormDataCache={setFormDataCache} 
                setShowPolicy={setShowPolicy} 
              />
            </div>
          </div>
        </div>
      </>
  )
}
