import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import Button from "../Button"
import RemoveIcon from '../../images/remove.svg'
import Fisherman from '../../images/sport-fisherman.svg'
import { deleteDataFromApi, postRequestToApi, registerAction } from "../../api"
import AuthContext from "../../context/AuthProvider"
import EditableItemsCard2 from "../EditableItemsCard2"
import EatingObjectiveCard from "../Eating/EatingObjectiveCard"

export default function ObjectiveMessage({ clickNext }) {

  const { auth, cookies } = React.useContext(AuthContext);
  const { eating, setEating } = React.useContext(PortalContext)

  const [textMessage, setTextMessage] = React.useState(null)
  const [addObjective, setAddObjective] = React.useState(false)

  React.useEffect(() => {
    if (eating.goals.length === 0) {
      setAddObjective(true)
      setTextMessage("Quais são seus objetivos?")
    } else {
      setTextMessage("Esse é o momento de lembrar e ajustar seus objetivos")
    }

    registerAction(+cookies.get("userId"), "objectiveMessagePage", auth, cookies)
  }, [])

  const deleteObjective = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'food/goals', auth, cookies)

    const updatedGoals = eating.goals.filter(item => item.id !== id)  
    setEating(prev => ({...prev, goals: updatedGoals}))
  }
  
  const clickToContinue = () => {
    // update objective check in flag
    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    registerAction(+cookies.get("userId"), "objectiveMessageContinue", auth, cookies)

    clickNext()
  }
  
  const clickToContinueWithoutAction = () => {
    // update objective check in flag
    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    registerAction(+cookies.get("userId"), "objectiveMessageContinueWithouAction", auth, cookies)

    clickNext()
  }

  return (
    <div className={"container-simple"}>
      <div className="top-margin-m icon-center">
        <img className="medium-logo" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4 bottom-margin-xs">Hora do foco!</p> 
        <p class="body bottom-margin-m">
          { textMessage }
        </p>
      </div>

      <EditableItemsCard2
        clsName="bottom-margin-m" 
        title="Objetivos"
        icons={true}
        addCard={()=>setAddObjective(prev=>!prev)}
      >
        <div>
          {(addObjective) ? 
              <EatingObjectiveCard setAddCard={()=>setAddObjective(prev=>!prev)} />
            : null}
          {
            (eating && eating.goals.length > 0) ? eating.goals.map((item, i) => (
              <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  {(item.title && item.title !== "") ? 
                  <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p> : 
                  <p key={i} className="body">{item.content}</p> } 
                  {(item.id) ?
                  <div className="row-svg-icons left-margin-xs">
                    <button onClick={()=>{deleteObjective(item.id)}} className='carousel-btn'>
                      <img className="" src={RemoveIcon}/>
                    </button>
                  </div> : null }
                </div>
              </div>
            )) : null 
          }
          { (eating && (!addObjective && eating.goals.length === 0)) ?
            (
              <div className="submit-column vertical-margin-m">
                <img className="submission-image" src={Fisherman} alt="No goals yet"/>
                <p className="body secondary-color top-margin-s">Nenhum objetivo por aqui</p>
              </div>
            ) : null
          }
        </div>
      </EditableItemsCard2>

      <div className="top-margin-m bottom-margin-l" style={{textAlign: 'right'}}>
        <Button type="primary" onClick={()=>{clickToContinue()}}>Próximo</Button>
        <Button type="tertiary left-margin-xs" onClick={()=>{clickToContinueWithoutAction()}}>Agora não</Button>
      </div>
    </div>
  )
}
