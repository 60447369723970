import React from "react"
import SimpleWaitMessage from "./SimpleWaitMessage"
import { Navigate } from "react-router-dom"
import SequenceMessage from "./SequenceMessage"
import FlorenceMessage from "./FlorenceMessage"
import { PortalContext } from "../../AuthenticatedUser"
import ObjectiveMessage from "./ObjectiveMessage"
import WeeklyPlanMessage from "./WeeklyPlanMessage"
import ExerciseLazy from "./ExerciseLazy"
import JunkFood from "./JunkFood"
import WeeklyPlanReview from "./WeeklyPlanReview"
import WeightLossMessage from "./WeightLossMessage"
import WeightGainMessage from "./WeightGainMessage"
import { postRequestToApi } from "../../api"
import AuthContext from "../../context/AuthProvider"
import WeightEvolutionMessage from "./WeightEvolutionMessage"

export default function MessageBroker({ status, submitionItem, itemDate, ranking=true }) {

  const { auth, cookies } = React.useContext(AuthContext);

  const [messageState, setMessageState] = React.useState(0)
  const [localSequenceLength, setLocalSequenceLength] = React.useState({length: 0, today: true})

  const { sequenceLength, eating } = React.useContext(PortalContext)

  React.useEffect(() => {
    setLocalSequenceLength({...sequenceLength})
  }, [])

  const goToNextScreen = () => {
    setMessageState(prev => prev + 1)
  }
  
  if (status === "error") {
    return (
      <Navigate to="/loginrequired" />
    )
  }


  if (messageState === 0) {
    return (
      //<WeightEvolutionMessage clickNext={()=>{goToNextScreen()}} /> 
      <SequenceMessage itemDate={itemDate} clickNext={()=>{goToNextScreen()}} sequence={localSequenceLength} />
    )
  } else if (messageState === 1) {
    return (
      <FlorenceMessage 
        clickNext={()=>{goToNextScreen()}} 
        submitionItem={submitionItem} />
    )
  } else if (messageState === 2) {
    if (eating.submission_pages.objective_check_in === false) {
      goToNextScreen()

      return null
    } else {
      if (eating.submission_pages.objective_check_in_id === 0) {
        return (
          <ObjectiveMessage clickNext={()=>{goToNextScreen()}} />
        )
      } else if (eating.submission_pages.objective_check_in_id === 1) {
        if ((eating.goals.filter(item => (item.content === 'Perder peso')).length > 0)) {
          return (
            <WeightLossMessage clickNext={()=>{goToNextScreen()}} />
          )
        } else if ((eating.goals.filter(item => (item.content === 'Ganhar massa muscular')).length > 0)) {
          return (
            <WeightGainMessage clickNext={()=>{goToNextScreen()}} />
          )
        } else {
          const bodyRequest = {id: +cookies.get("userId")}
          postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
          goToNextScreen()
          return null
        }
      } else if (eating.submission_pages.objective_check_in_id === 2) {
        return (
          <WeeklyPlanMessage clickNext={()=>{goToNextScreen()}} />
        )
      } else if (eating.submission_pages.objective_check_in_id === 3) {
        return (
          <ExerciseLazy clickNext={()=>{goToNextScreen()}} />
        )
      } else if (eating.submission_pages.objective_check_in_id === 4) {
        return (
          <JunkFood clickNext={()=>{goToNextScreen()}} />
        )
      } else if (eating.submission_pages.objective_check_in_id === 5) {
        return (
          <WeeklyPlanReview clickNext={()=>{goToNextScreen()}} />
        )
      } else if (eating.submission_pages.objective_check_in_id === 6) {
        return (
          <WeightEvolutionMessage clickNext={()=>{goToNextScreen()}} /> 
        )
      } else {
        goToNextScreen()
        
        return null
      }
    }
  } else if (messageState === 3) {
    goToNextScreen()
    return null
  } else {
    console.log('here')
    if (status === "complete" && submitionItem === "refeição") {
      console.log('here1')
      return (
        <Navigate 
          to="/eating/highlight" 
          state={
            { 
              message: "Sua refeição foi submetida com sucesso, e em alguns instantes ela aparecerá nessa página",
              ranking: ranking 
            }
          } 
        />
      )
    } else if (status === "complete" && submitionItem === "exercício") {
      console.log('here2')
      return (
        <Navigate 
          to="/exercise/highlight" 
          state={
            { 
              message: "Seu exercício físico foi submetida com sucesso, e em alguns instantes ele aparecerá nessa página",
              ranking: ranking 
            }
          } 
        />
      )
    } else if (status !== "complete" ) {
      console.log('here3')
      return (
        <SimpleWaitMessage submitionItem={submitionItem} />
      )
    }
  }
}
