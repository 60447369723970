import React from 'react';
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Return from "../components/Forum/Return"
import Button from '../components/Button';

export default function Policy({ setShowPolicy }) {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  // go to the top of the page
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={false} menuShow={true} loggedIn={false}/>
      </div>

      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>

        <section className="top-margin-m bottom-padding-xl">

          {(setShowPolicy) ?  
            <Return 
              button={true}
              onClick={()=>{setShowPolicy(false)}} 
              backTo="Voltar para página de cadastro"/>
            : 
            <Return to="/registration" backTo="Voltar para página de cadastro"/>
          }

          <h1 className="florence-title top-margin-l">Política de Privacidade e Termos de Uso</h1>

          <p className="secondary-text">Atualizada em 17 de Julho, 2024</p>

          <p className="body top-margin-s">
A Florence Health (“Florence”) é uma comunidade on-line de rastreamento e acompanhamento de dados de saúde que permite que seus membros compartilhem seus dados e experiências. A plataforma é operada pela Florence Inteligência Artificial LTDA (“Florence”). Esta Política de Privacidade descreve como as informações coletadas dos usuários da Plataforma, incluindo indivíduos que se registraram para aderir (“Membros”), podem ser utilizadas.
          </p> 
          
          <p className="body top-margin-s">
Reservamo-nos o direito de modificar esta política a qualquer momento e sem aviso prévio, publicando uma Política de Privacidade alterada na Plataforma. Notificaremos os Membros sobre alterações que fizermos nesta Política de Privacidade. Também encorajamos os Membros a rever esta política periodicamente para quaisquer atualizações.
          </p>

          <p className="heading3 top-margin-s">
O que é a Florence?
          </p>

          <p className="body top-margin-s">
Florence oferece esta plataforma para pacientes que desejam:
          </p>

          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>Rastrear seus dados de saúde para que possam ser participantes ativos em seus próprios cuidados de saúde e gerenciamento de sintomas.</li>
              <li>Criar conhecimento coletivo sobre doenças, saúde e tratamentos.</li>
              <li>Contribuindo com seus dados de saúde para pesquisas.</li>
              <li>Compartilhando suas informações de saúde com outros membros da comunidade on-line da Florence.</li>
              <li>Conectar-se com outros membros e fornecer apoio de pares e ser capaz de compartilhar suas próprias experiências com condições que afetam a saúde e o bem-estar</li>
              <li>Proporcionar maior conscientização e acesso aos serviços de saúde.</li>
            </ul>
          </div>

          <p className="heading3 top-margin-s">
Quem pode ter acesso aos dados que coletamos
          </p>

          <p className="body top-margin-s">
Se você se tornar Membro da Plataforma, existem quatro grandes grupos de pessoas que poderão ter acesso aos seus dados.
          </p>
          <p className="body top-margin-s">
A Comunidade – Refere-se aos seus colegas membros na plataforma. Você pode compartilhar seus dados através do seu perfil e dos diversos componentes sociais do site. Ao compartilhar seus dados, outras pessoas podem aprender com eles. Você pode marcar alguns dados (como uma condição) para serem reservados apenas para sua Visualização Pessoal e que não ficarão visíveis para a Comunidade.
          </p>

          <p className="body top-margin-s">
Florence – Usamos os dados que você fornece internamente, tanto para melhorar nossos serviços quanto para conduzir nossas próprias pesquisas.
          </p>

          <p className="body top-margin-s">
Nossos parceiros – A Florence frequentemente faz parceria com outras instituições para conduzir pesquisas e gerenciar comunidades de pacientes. Esses parceiros podem incluir, mas não estão limitados a: universidades, empresas farmacêuticas, sistemas hospitalares, prestadores de cuidados baseados em valor, companhias de seguros, órgãos reguladores e outras entidades.
          </p>

          <p className="body top-margin-s">
Fornecedores – Também contratamos vários provedores de serviços para serviços comerciais e técnicos, como entrega de e-mail, hospedagem de sites, marketing, publicidade, suporte de help desk e outros.
          </p>

          <p className="heading3 top-margin-s">
Que tipos de informação coletamos
          </p>

          <p className="heading4 top-margin-s">
Dados de identificação
          </p>
          <p className="body top-margin-s">
Os dados que identificam ou potencialmente identificam os usuários são tratados como “Dados de identificação”. Esses dados incluem:
          </p>

          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>userID atribuído pela plataforma.</li>
              <li>
  Senha da plataforma (é coletada como parte do registro e armazenada como um hash unidirecional para que ninguém, além de você, saiba qual é a sua senha).
              </li>
              <li>
  Nome (o membro pode fornecer como parte do registro ou nas informações da conta do membro).
              </li>
              <li>
  Data de nascimento.
              </li>
              <li>
  Endereço de email.
              </li>
              <li>
  Endereços IP.
              </li>
              <li>
  Dados de geolocalização.
              </li>
              <li>
  Conteúdo de mensagens privadas para mensagens privadas entre membros.
              </li>
              <li>
  Qualquer um dos itens acima inserido como texto livre.
              </li>
            </ul>
          </div>

          <p className="body top-margin-s">
A Florence pode agregar ou analisar estatisticamente Dados de Identificação de mais de um membro, caso em que tais dados agregados ou analisados ​​estatisticamente resultantes não serão tratados como Dados de Identificação.
          </p>

          <p className="heading4 top-margin-s">
Dados não identificáveis
          </p>
          <p className="body top-margin-s">
“Dados não identificáveis” são todas as informações, exceto Dados de Identificação, que os Membros fornecem sobre si mesmos ao usar a Plataforma ou em outras comunicações com a Florence. Exemplos de dados não identificáveis ​​que os Membros podem enviar incluem:
          </p>
          
          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>
  Fotografias não identificáveis.
              </li>
              <li>
  Idade (a menos que tenha mais de 89 anos).
              </li>
              <li>
  Localização (cidade, estado/província, país).
              </li>
              <li>
  Sexo.
              </li>
              <li>
  Identidade de gênero.
              </li>
              <li>
  Informações sobre condição/doença (por exemplo, primeiro sintoma, histórico familiar).
              </li>
              <li>
  Informações sobre o tratamento (por exemplo, motivos de interrupção do tratamento, dosagens, efeitos colaterais, avaliações do tratamento).
              </li>
              <li>
  Informações sobre sintomas (por exemplo, gravidade, duração).
              </li>
              <li>
  Pontuações de resultados (por exemplo, medidas ALSFRS-R, MSRS, PDRS, FVC, PFRS).
              </li>
              <li>
  Medidas de saúde (por exemplo, peso, pressão arterial, sono, atividade).
              </li>
              <li>
  Resultados laboratoriais e biomarcadores (por exemplo, contagem de CD4, carga viral, creatinina, imagens).
              </li>
              <li>
  Respostas estruturadas da pesquisa.
              </li>
              <li>
  Informações não identificáveis compartilhadas por meio de campos de texto livre (por exemplo, fóruns, avaliações de tratamento, pesquisas, anotações, diários, feeds).
              </li>
              <li>
  Conexões com outras pessoas na Plataforma (por exemplo, Seguidores, Líderes e Grupos).
              </li>
            </ul>
          </div>

          <p className="body top-margin-s">
A Florence pode agregar ou analisar estatisticamente dados, inclusive de mais de um Membro. Os dados agregados ou analisados estatisticamente resultantes serão tratados como Dados Não Identificadores pela Florence.
          </p>

          <p className="heading3 top-margin-s">
Dados de uso da plataforma
          </p>
          <p className="body top-margin-s">
Nós, nossos Parceiros e Fornecedores, usamos código analítico e podemos usar tecnologias de rastreamento da web, como cookies e pixel tags, para entender como os Membros usam nossa plataforma e para melhorar produtos e serviços. Esses dados coletados (“Dados de Uso da Plataforma”) podem incluir a URL dos sites que você visitou antes e depois de visitar nossa Plataforma, o tipo de navegador que você está usando, seu Provedor de Serviços de Internet, quais páginas de nossa Plataforma você visita, quais links você clica, data e hora da sua visita e duração, se você abre comunicações por e-mail que lhe enviamos e se você interage com publicidade ou conteúdo exibido no site e em sites de terceiros. O código analítico também coleta informações sobre você, como geolocalização, idade, sexo, categorias de afinidade e interesses, que podem ser usadas pela Florence. Você pode modificar as configurações do seu navegador para alterar quais tecnologias de rastreamento da web são permitidas quando você usa a Plataforma, mas isso pode limitar o seu uso da Plataforma.
          </p>
          <p className="body top-margin-s">
Os Dados de Uso da Plataforma normalmente são usados apenas pela Florence e nossos Fornecedores. No entanto, quando desidentificado, poderá ser partilhado com os nossos parceiros de investigação para os ajudar a compreender como os membros utilizam e beneficiam do site.
          </p>
          
          <p className="heading3 top-margin-s">
Anúncio
          </p>
          <p className="body top-margin-s">
Alguns conteúdos e aplicativos, incluindo anúncios, no site da Florence são servidos por terceiros, incluindo anunciantes, redes e servidores de anúncios, provedores de conteúdo e provedores de aplicativos. Esses terceiros podem usar cookies, isoladamente ou em conjunto com web beacons ou outras tecnologias de rastreamento, para coletar informações sobre você quando você usa nosso site. As informações que eles coletam podem estar associadas às suas informações pessoais ou podem coletar informações, incluindo informações pessoais, sobre suas atividades online ao longo do tempo e em diferentes sites e outros serviços online. Eles podem usar essas informações para fornecer publicidade (comportamental) baseada em interesses ou outro conteúdo direcionado.
          </p>
          <p className="body top-margin-s">
Não controlamos as tecnologias de rastreamento desses terceiros ou como elas podem ser usadas. Se tiver alguma dúvida sobre um anúncio ou outro conteúdo direcionado, entre em contato diretamente com o fornecedor responsável. Para obter informações sobre como você pode optar por não receber publicidade direcionada de vários provedores, consulte Opções sobre como usamos e divulgamos suas informações.
          </p>

          <p className="heading3 top-margin-s">
Como seus dados são usados e compartilhados
          </p>
          <p className="body top-margin-s">
Você deve esperar que todos os dados não identificáveis que você enviar a Florence possam ser compartilhados com Parceiros.
Dados não identificáveis podem ser exibidos para a Comunidade/Fórum, a menos que você especifique que sejam reservados para sua Visualização Pessoal e não para serem exibidos para a Comunidade. 
Os membros são incentivados a compartilhar informações de saúde, mas devem considerar que quanto mais informações forem inseridas, maior será a probabilidade de você ser localizado ou identificado.
          </p>
          
          <p className="heading4 top-margin-s">
Como os dados de identificação são usados
          </p>
          <p className="body top-margin-s">
Existem apenas três maneiras pelas quais os dados de identificação são compartilhados com a comunidade.
          </p>

          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>
  O nome de usuário que você criou na Florence é usado em todo o site para representar você e seu perfil.
              </li>
              <li>
  Sua imagem de avatar, identificativa ou não, também é utilizada para representar você e seu perfil no site.
              </li>
              <li>
  Qualquer informação de identificação que você decidir compartilhar como texto livre nos vários recursos sociais do site será compartilhada com todos no site que decidirem lê-la.
              </li>
              <li>
  Se você estiver usando a Plataforma com um tipo de conta especial diferente do tipo de conta normal de Paciente, informações adicionais de identificação sobre você poderão ser compartilhadas. 
  Por exemplo:    
  Médicos ou Pesquisadores que obtiverem uma conta oficial de médico ou de pesquisa terão seu nome completo e afiliação visíveis pela Comunidade através de seu perfil.
              </li>
            </ul>
          </div>

          <p className="body top-margin-s">
Nunca venderemos suas informações de identificação para fins publicitários que não sejam da Florence.
          </p>
          <p className="body top-margin-s">
A Florence usa Dados de Identificação internamente, conforme necessário, para pesquisa, manutenção e operação da Plataforma e para criar melhores ferramentas e experiências mais personalizadas para você. Tomamos medidas para proteger esses Dados de Identificação e limitar o acesso apenas àqueles que precisam deles para seu trabalho.
          </p>
          <p className="body top-margin-s">
Se tivermos a permissão de um Membro, o seu endereço de e-mail será usado pela Florence para enviar-lhes uma variedade de notificações, incluindo notificações de mensagens privadas, boletins informativos, convites para estudos e conteúdo promocional da Florence e de alguns de nossos Parceiros. Você pode alterar essa configuração no momento da inscrição, na página da sua conta ou clicando no link de cancelamento de inscrição na parte inferior de qualquer e-mail que receber da Florence. No entanto, todos os Membros recebem e-mails administrativos (por exemplo, redefinição de senha), dos quais você não pode cancelar enquanto permanecer registrado na Plataforma.
          </p>
          <p className="body top-margin-s">
Além disso, os Dados de Identificação não são compartilhados nem vendidos a Parceiros, a menos que seja dado consentimento explícito. Os casos específicos em que o consentimento pode ser solicitado incluem:
          </p>

          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>
  Projetos e estudos especiais de pesquisa.
              </li>
              <li>
  Co-registro com um parceiro.
              </li>
              <li>
  Entrevistas de um membro na mídia.
              </li>
              <li>
  Participação em um programa de gestão de saúde ou grupo patrocinado na Florence.
              </li>
            </ul>
          </div>

          <p className="body top-margin-s">
A Florence, em alguns casos, permitirá que os Fornecedores tenham acesso aos Dados de Identificação com a finalidade de operar ou melhorar a Plataforma ou outras atividades e ofertas da Florence (como estudos de pesquisa e comunidades gerenciadas). A Florence investiga todos os fornecedores envolvidos para garantir que suas práticas de segurança e privacidade estejam em conformidade com os regulamentos relevantes e com os padrões da Florence. Exemplos específicos onde um Fornecedor pode ter acesso a Dados de Identificação incluem:
          </p>

          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>
  Se você fizer uma solicitação, a Florence poderá fornecer ao Fornecedor a quantidade mínima de Dados de Identificação necessários para atender à solicitação. Os exemplos incluem solicitar o recebimento do boletim informativo da empresa por e-mail, solicitar uma resposta por e-mail da equipe de suporte da Florence ou solicitar que uma camiseta seja enviada para seu endereço postal.
              </li>
              <li>
  Poderemos usar suas informações de identificação para excluí-lo de determinados anúncios da Florence ou para apresentar-lhe determinados anúncios, conteúdos ou oportunidades de participação.
              </li>
            </ul>
          </div>

          <p className="heading4 top-margin-s">
Como seus dados não-identificáveis são usados
          </p>
          <p className="body top-margin-s">
Os dados não identificáveis que você adiciona ao seu perfil (exceto os dados que você marcou apenas para visualização pessoal) são exibidos na comunidade por meio das páginas do seu perfil.
          </p>
          <p className="body top-margin-s">
Os dados agregados (por exemplo, contagens do número de Membros com uma determinada condição ou num tratamento específico) não são identificativos e são apresentados à Comunidade e compartilhados com os Parceiros. Os dados que um Membro marcou como reservados à sua Visão Pessoal podem ser incluídos nessas contagens.
          </p>
          <p className="body top-margin-s">
Além de atender às necessidades individuais de nossos membros, a Florence e seus parceiros estão interessados ​​em compreender melhor a experiência do paciente e em melhorar as opções de tratamento, o compartilhamento de informações e os resultados de saúde para todos. Por exemplo, podemos analisar questões como: “Certos tratamentos funcionam melhor para alguns tipos de pessoas do que para outros?” A Florence fornece Dados Não Identificadores, em formato individual e agregado, aos Parceiros para uso em pesquisas científicas, desenvolvimento de produtos, comunidades gerenciadas e pesquisas de mercado. Ao vender estas informações, a Florence remove os Dados de Identificação dos Membros (desidentificação) para reduzir a possibilidade de reidentificação e proíbe contratualmente os Parceiros de tentarem reidentificar os Membros.
          </p>
          <p className="body top-margin-s">
A Florence pode solicitar periodicamente aos Membros que preencham pesquisas sobre suas experiências (incluindo perguntas sobre produtos e serviços). As respostas da pesquisa (possivelmente em combinação com dados da Plataforma) são analisadas por pesquisadores da Florence. Os insights da análise podem ser compartilhados e/ou vendidos aos Parceiros de uma forma que não identifique nenhum entrevistado. A participação do Membro nestas pesquisas não é obrigatória e a recusa em fazê-lo não afetará a experiência do Membro da Florence.
          </p>
          <p className="body top-margin-s">
A Florence pode relatar eventos adversos individuais e informações de segurança de medicamentos a parceiros reguladores como ANVISA, bem como diretamente a parceiros farmacêuticos e outros parceiros. Ao relatar tais informações, a Florence não fornece Dados de Identificação, embora nos reservemos o direito de entrar em contato com os Membros para acompanhamento a pedido de agências ou Parceiros. Neste contexto, os dados que a Florence reporta podem incluir textos ou imagens livres nos fóruns ou avaliações.
          </p>
          <p className="body top-margin-s">
Finalmente, a Florence pode usar Dados Não Identificadores internamente ou enviá-los a Fornecedores que auxiliam na operação de nossos serviços. Por exemplo, podemos enviar informações sobre tratamento ou condição a um provedor de e-mail para que as informações possam ser incluídas nas mensagens que lhe enviamos. Além disso, alguns Fornecedores podem utilizar Dados Não Identificadores para melhorar seus próprios produtos e serviços.
          </p>
          <p className="body top-margin-s">
A Florence, como outras comunidades online, é um “fórum público”. Esteja ciente de que os Dados Não Identificadores, nas combinações corretas, podem ser usados ​​por outros membros da comunidade para identificá-lo. Por exemplo, ter uma doença muito rara pode facilitar a identificação de alguém quando o sexo e o estado de residência também são conhecidos.
          </p>
          <p className="body top-margin-s">
Para maior clareza, “fórum público” neste contexto NÃO significa que o conteúdo e os dados possam ser livremente utilizados por terceiros. Qualquer uso fora dos nossos Termos de Uso e desta Política de Privacidade é proibido.
          </p>
          <p className="heading4 top-margin-s">
Como os dados de uso da plataforma são utilizados
          </p>
          <p className="body top-margin-s">
Usamos Dados de Uso da Plataforma para diversas finalidades:
          </p>
          <p className="body top-margin-s">
<strong>Autenticação:</strong> Usamos Dados de Uso da Plataforma armazenados em cookies no seu computador para indicar que você fez login na sua conta da Florence e para permitir que você use determinadas partes da nossa Plataforma.
          </p>
          <p className="body top-margin-s">
<strong>Compreender nossos usuários:</strong> usamos dados de uso da plataforma para analisar tendências, rastrear os movimentos dos usuários na plataforma e coletar informações demográficas sobre nossa base de usuários como um todo. Isso nos permite determinar informações agregadas sobre nossa base de usuários e padrões de uso. Compreender como as pessoas utilizam a nossa Plataforma permite-nos torná-la melhor para todos. Também poderá ser utilizado pelos nossos Fornecedores para melhorar os seus produtos e serviços. Poderemos utilizar estas informações, possivelmente em coordenação com um dos nossos Parceiros, para fazer análises relevantes sobre o comportamento do utilizador ou resultados médicos. Não vendemos esses dados de uso a terceiros para fins publicitários ou de marketing. Às vezes, fornecemos aos nossos Parceiros dados de uso agregados de todos os indivíduos que eles referiram ao nosso site. Somente forneceremos seus Dados de Uso da Plataforma de identificação pessoal ou identificáveis ​​aos Parceiros com o seu consentimento expresso.
          </p>
          <p className="body top-margin-s">
<strong>Administrar a Plataforma:</strong> Usamos Dados de Uso da Plataforma para ajudar a administrar a Plataforma e o uso da Plataforma pelos Membros. Poderemos, em algumas circunstâncias, precisar revisar estes Dados de Uso da Plataforma em combinação com Dados de Identificação específicos para solucionar problemas de usuários individuais.
          </p>
          <p className="body top-margin-s">
<strong>Publicidade:</strong> Podemos usar cookies ou Dados de Uso da Plataforma para personalizar anúncios, para promover determinados conteúdos de anunciantes, oportunidades de participação ou para excluí-lo de notificações que não são relevantes para você, inclusive quando você estiver visitando outros sites ou plataformas. Além disso, poderemos usar as informações que coletamos de você para nos permitir exibir anúncios ao público-alvo de nossos anunciantes. Embora não divulguemos suas informações pessoais para esses fins sem o seu consentimento, se você clicar ou interagir de outra forma com um anúncio, o anunciante poderá presumir que você atende aos critérios de segmentação.
          </p>
          <p className="heading3 top-margin-s">
Desativando/excluindo sua conta
          </p>
          <p className="body top-margin-s">
Você é livre para parar de usar este serviço a qualquer momento. Você pode “desativar” sua conta, caso em que a Florence mantém seus dados armazenados para que você possa retornar e reativar sem perder esses dados. Se você optar por desativar sua conta, a Florence não exibirá nem compartilhará seus dados a partir da data da desativação. A Florence não utilizará seus dados em nenhuma pesquisa que comece após a data de sua desativação.
          </p>
          <p className="body top-margin-s">
Alternativamente, você pode “excluir” sua conta e dados; nesse caso, seus dados serão removidos permanentemente da Florence e não poderão ser recuperados.
          </p>
          <p className="body top-margin-s">
Observação: Caso você solicite a desativação ou exclusão, pesquisas que já estejam em andamento ou que tenham sido realizadas antes da sua solicitação ainda incluirão seus dados. Isto é importante para apoiar a revisão pelos pares da investigação e a replicação dos resultados – partes importantes do processo científico. 
          </p>

          <p className="heading4 top-margin-s">
Outros casos especiais
          </p>
          <p className="body top-margin-s">
Há casos, não cobertos acima, em que seus Dados Não Identificadores, Dados de Identificação e Dados de Uso da Plataforma podem ser usados ​​e divulgados, incluindo, entre outros, o seguinte:
          </p>

          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>
  A Florence pode usar seus dados no caso de uma emergência ou outra circunstância que determinemos que exija que um membro da equipe de gerenciamento entre em contato diretamente com o Membro (por exemplo, uma violação de dados que coloque os dados do Membro em risco).
              </li>
              <li>
  A Florence pode compartilhar ou divulgar seus dados quando necessário para cumprir solicitações legais de autoridades públicas, inclusive para solicitações de segurança nacional ou de aplicação da lei, para cumprir processos legais, para resolver disputas, para fazer cumprir nossos acordos (incluindo esta Política de Privacidade), ou se, a nosso critério razoável, o uso for necessário para proteger nossos direitos legais ou para proteger terceiros.
              </li>
              <li>
  A Florence pode transferir seus dados para qualquer sucessor de seus negócios como resultado de qualquer fusão, aquisição, venda de ativos, processo de falência ou transação ou evento semelhante. Os Membros serão informados de quaisquer alterações na Política de Privacidade atual que resultem de tal transferência.
              </li>
            </ul>
          </div>
          
          <p className="heading4 top-margin-s">
Outras questões de segurança
          </p>
          <p className="body top-margin-s">
A Florence não pode garantir a identidade de quaisquer Membros com quem você possa interagir durante a utilização da Plataforma ou que possam ter acesso aos seus dados exibidos. Além disso, não podemos garantir a autenticidade de quaisquer dados que os Membros possam fornecer sobre si próprios.
          </p>
          <p className="body top-margin-s">
A Florence toma precauções técnicas comercialmente razoáveis ​​para ajudar a manter os dados dos Membros seguros, consistentes com as leis aplicáveis. Tomamos essas precauções em um esforço para proteger suas informações contra violações de segurança. No entanto, isto não é uma garantia de que tais informações não possam ser acessadas, divulgadas, alteradas ou destruídas por violação de firewalls e software de servidor seguro. Ao utilizar nossa Plataforma, você reconhece que entende e concorda em assumir esses riscos.
          </p>
          <p className="body top-margin-s">
No caso de uma violação, a Florence notificará as autoridades reguladoras relevantes dentro de 72 horas após tomar conhecimento da violação. Notificaremos os Membros afetados o mais rápido possível depois disso.
          </p>
          
          <p className="heading4 top-margin-s">
Riscos e Benefícios
          </p>
          
          <p className="body top-margin-s">
Embora nosso objetivo seja ajudar os pacientes a melhorar os resultados de saúde, não há benefícios certos em usar este site. No entanto, acompanhar o bem-estar pessoal, os tratamentos e os sintomas demonstrou ser útil para melhorar a saúde geral.
          </p>
          <p className="body top-margin-s">
Existe a possibilidade de você se sentir desconfortável em compartilhar informações online. É possível que você seja identificado usando informações que você optou por exibir na Florence (e/ou em conjunto com outras fontes de dados). Você pode ser discriminado ou sofrer repercussões como resultado das informações que compartilha. Por exemplo, é possível que empregadores, companhias de seguros ou outros discriminem com base em informações de saúde.
          </p>
          <p className="body top-margin-s">
Você deve compreender que qualquer pessoa pode se registrar na Florence e visualizar os dados que você optou por compartilhar na Plataforma.
          </p>
          <p className="body top-margin-s">
Ao usar a Plataforma, você pode pular quaisquer perguntas não obrigatórias ou campos de dados que o deixem desconfortável.
          </p>

          <div className="top-margin-l">
          {(setShowPolicy) ?  
            <Button onClick={()=>{setShowPolicy(false)}} type="tertiary">
                Voltar para página de cadastro
            </Button>
            : 
            <Button link={true} to={'/registration'} type={'tertiary'}>Voltar para página de cadastro</Button>
          }
          </div>

        </section>
      </div>

      <Footer tabletBreakpoint={tabletBreakpoint} border="footer-no-right-border"/>
    </>
    
  )
}
