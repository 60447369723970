import React from 'react'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { ForumContext } from './ForumData'
import SearchBar from '../components/Forum/SearchBar'
import PostLikes from '../components/Forum/PostLikes'
import PostComments from '../components/Forum/PostComments'
import SavedPost from '../components/Forum/SavedPost'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import Author from '../components/Forum/Author'
import RelatedPosts from '../components/Forum/RelatedPosts'
import CommentarySection from '../components/Forum/CommentarySection'
import AuthContext from '../context/AuthProvider'
import { deleteDataFromApi } from '../api'

export default function Post() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const { id } = useParams()
  const { forumData, loadPost, setRefreshFeed, notifications, relatedPosts, getRelatedPosts } = React.useContext(ForumContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const [status, setStatus] = React.useState("idle");

  const location = useLocation()

  const navigate = useNavigate()

  React.useEffect(() => { 
    window.scrollTo(0, 0)

    const post = forumData.posts.filter((post) => post.id === +id)[0]
    if (!post) {
      // load post
      loadPost(id)
    }
  }, [])

  React.useEffect(() => {
    const post = forumData.posts.filter((post) => post.id === +id)[0]
    if (post) {
      getRelatedPosts(id)
    }
  }, [forumData])

  if (!forumData || forumData.posts.length < 1) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }

  const post = forumData.posts.filter((post) => post.id === +id)[0]

  if (!post) {
    // load post
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }


  async function deletePost() {
    setStatus("submitting")
    try {
      const data = await deleteDataFromApi({ id: +cookies.get("userId") }, `forum/${id}`, auth, cookies)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      }))
      setRefreshFeed(true)
      navigate('/forum') //, { state: { refresh: true } })
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      //console.log("data deleted")
      //loadPost(id) // true: user is loggedin
      //navigate('/forum')
      setStatus("idle")
    }
  }

  function handleDelete() {
    deletePost()
  }

  let imageUrl = null
  if (post.hasImage) {
    imageUrl = window.URL.createObjectURL(post.image)
  }

  let backTo = null
  if (location.state?.search) {
    if (location.state.search == "/profile") {
      backTo = location.state.search
    } else {
      backTo = `/forum?${location.state.search}`
    }
  }

  return (

    <div className="top-margin-s">

      <Helmet>
        <title>{(post.title !== "") ? `Fórum - ${post.title}` : `Fórum - ${post.content}`}</title>
        <meta 
          name="description" 
          content={`${post.content}`}/>
      </Helmet>

      <SearchBar tabletBreakpoint={tabletBreakpoint} notifications={notifications}/>
      <div className={(tabletBreakpoint) ? 
        "container-simple-tablet" : "container-simple"}>
        <div className="top-margin-s bottom-margin-s">
          <Return to={(backTo) ? `${backTo}` : '/forum'}/>
        </div>
        <div className="bottom-margin-s">
          <Author linkToFollow={true} followedBy={post.followed_by}>{post}</Author> 
        </div>
        <p className="heading4 bottom-margin-s">{post.title}</p>
        <p className="body content bottom-padding-s">{ post.content }</p>
        {(post.hasImage) ? 
          <img className="post-image" src={imageUrl} alt="Post image"/>
          : null 
        }
        <div className="post-stats bottom-padding-xs">
          <PostLikes postId={id} foundUseful={(cookies.get("userId") && post.voted_by) ? 
            post.voted_by.includes(cookies.get("userId")) : false} 
            qtyPeopleFoundUseful={(post.voted_by) ? post.voted_by.length : 0} />
          <PostComments qtyComments={post.comments.length} /> 
        </div>
        <div className="post-stats">
          <SavedPost postId={id} savedBy={post.saved_by} />
          {(cookies.get("userId") === post.author_id) ? 
            <Button type="tertiary" onClick={()=>handleDelete()}>
              { (status === "idle") ? "Excluir post": "Excluindo..." }
            </Button> 
            : null 
          }
        </div>
        <div className="top-padding-m">
          <CommentarySection postId={id} comments={post.comments} />
        </div>
        <div className="top-padding-s">
          <RelatedPosts posts={relatedPosts} />
        </div>
      </div>
    </div>
  )
}
