import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import Button from "../Button"
import Fisherman from '../../images/sport-fisherman.svg'
import { deleteDataFromApi, postRequestToApi, registerAction } from "../../api"
import AuthContext from "../../context/AuthProvider"
import EditableItemsCard2 from "../EditableItemsCard2"
import PlanThumbnail from "../Eating/PlanThumbnail"
import EatingPlanSuggestionsCard from "../Eating/EatingPlanSuggestionsCard"

export default function WeeklyPlanReview({ clickNext }) {

  const { auth, cookies } = React.useContext(AuthContext);
  const { eating, setEating } = React.useContext(PortalContext)

  const [addPlan, setAddPlan] = React.useState(false)
  
  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "weeklyPlanReviewPage", auth, cookies)
  }, [])

  const deletePlan = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'food/plan', auth, cookies)

    const updatedPlan = eating.plan.filter(item => item.id !== id)  
    setEating(prev => ({...prev, plan: updatedPlan}))
  }

  const planSuggestions = [
    {
      "title": "O que vou fazer quando der vontade de comer algo que não deveria",
      "content": "Quando tiver vontade de comer (algo que não deveria), vou..."
    },
    {
      "title": "Quais dias e horários vou me exercitar",
      "content": `Exercícios da semana:`
    },
    {
      "title": "O que vou fazer quando der vontade de não ir na academia",
      "content": `Quando der vontade de não ir, eu vou...`
    },
    {
      "title": "A cada atividade física que eu fizer, minha recompensa vai ser",
      "content": "Minha recompensa por fazer atividade física será..."
    },
  ]
  
  const clickToContinue = () => {
    // update objective check in flag
    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))

    registerAction(+cookies.get("userId"), "WeeklyReviewContinue", auth, cookies)
    clickNext()
  }
 
  return (
    <div className={"container-simple"}>
      <div className="top-margin-m icon-center">
        <img className="medium-logo" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4 bottom-margin-xs">Plano para a semana</p> 
        <p class="body bottom-margin-m">
          Hora de revisar e acompanhar seu plano para a semana
        </p>
      </div>

      <EditableItemsCard2 
        clsName="bottom-margin-m" 
        title="Plano semanal"
        icons={true}
        addCard={()=>setAddPlan(prev=>!prev)}
      >
        <div>
          {(addPlan) ?
              <EatingPlanSuggestionsCard suggestions={planSuggestions} setAddCard={()=>setAddPlan(prev=>!prev)} />
            : null}
          {
            (eating && eating.plan.length > 0) ? eating.plan.map((item, i) => (
              <PlanThumbnail item={item} i={i} deletePlan={deletePlan} />
            )) : null
          }
          { (eating && (!addPlan && eating.plan.length === 0)) ?
            (
              <div className="submit-column vertical-margin-m">
                <img className="submission-image" src={Fisherman} alt="No goals yet"/>
                <p className="body secondary-color top-margin-s">Sem planos por aqui</p>
              </div>
            ) : null
          }
        </div>
      </EditableItemsCard2>

      <div className="top-margin-m bottom-margin-l" style={{textAlign: 'right'}}>
        <Button type="primary" onClick={()=>{clickToContinue()}}>Próximo</Button>
      </div>
    </div>
  )
}
