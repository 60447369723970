import React from 'react'
import Button from "../Button"
import Attention from "../../images/sun.svg"
import { PortalContext } from '../../AuthenticatedUser'
import { postRequestToApi } from '../../api'
import HighlightedMessage from '../HighlightedMessage'

export default function EatingPlanSuggestionsCard({ setAddCard, suggestions }) {

  const { 
    auth, 
    cookies,
    eating,
    setEating 
  } = React.useContext(PortalContext);

  const [error, setError] = React.useState(null)
  const [formData, setFormData] = React.useState({
    title: ""
  })
  
  async function submitUpdatedData(bodyRequest) {
    await postRequestToApi(bodyRequest, '/food/plan', auth, cookies) 
  }

  const handleSubmit = (e) => {
    e.preventDefault()


    if (formData.title !== "") {
      setError(null)

      const updatedPlan = eating.plan
      updatedPlan.push({
        title: formData.title
      })
      setEating(prev => ({...prev, plan: updatedPlan}))
      
      submitUpdatedData({
        id: +cookies.get("userId"), 
        name: formData.title
      })

      setAddCard(false)
    } else {
      setError("Por favor, preencha todos os campos")
    }
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const selectSuggestion = (suggestion) => {
    setFormData({
      title: suggestion.content
    })
  }

  
  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
      <div className="btn-grid top-padding-xxs">
        { suggestions.map(suggestion => (
          <Button type="secondary button-content" onClick={()=>{selectSuggestion(suggestion)}}>
            {suggestion.title}
          </Button>
        ))}
      </div>
      {(error) ? <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null} 
      <form onSubmit={handleSubmit} className="top-margin-xs">
        <textarea name="title" className="profile-question-form form-full-width shorter-form comment-form" 
          onChange={handleChange} type="text" placeholder="Adicione um plano para atingir seus objetivos"
          value={formData.title}/> 
        <Button type="primary top-margin-xs">Criar</Button>
        <button type="button" className="btn-tertiary left-margin-s" onClick={()=>{setAddCard()}}>Cancelar</button>
      </form>

    </div>
  )
}
