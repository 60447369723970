import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ProductSection from "../components/ProductSection"
import Carousel from "../components/Carousel"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Button from "../components/Button"
import LoginBlock from "../components/LoginBlock"
import AuthContext from '../context/AuthProvider'
import HomeThumbnails from "../components/Forum/HomeThumbnails"

export default function Home() {
  
  const [sliderIndex, setSliderIndex] = React.useState(2)
  
  const { auth, cookies } = React.useContext(AuthContext)
  const navigate = useNavigate()

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })

  const images = [
    "health-okt.png",
    "familia-1.png", 
    "eating-okt-2.png", 
    "exercise-okt.png", 
    "forum-okt.png"]

  const buttonsList = [
    "Saúde",
    "Família",
    "Alimentação",
    "Exercício",
    "Comunidade"
  ]
  
  React.useEffect(() => {
    if (auth.accessToken || cookies.get("accessToken")) {
      if (cookies.get("userId")) {
        navigate("/profile")
      } else {
        navigate("family")
      }
    }
  }, [])
  
  //<div className="bottom-margin-s">
  //    <h1 className="intra-page-title">Fórum</h1>
  //    <p className="body vertical-margin-s">O Fórum da Florence é uma comunidade construída para a troca de experiência sobre saúde. 
  //  Nesse espaço você pode tirar dúvidas, contar sua experiência e aprender com outras pessoas.</p>
  //    <Button type="secondary bottom-margin-s" link={true} to={'./forum'}>Visite o Fórum</Button>
  //
  //    <HomeThumbnails />
  //</div>
  const img1 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[0]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img2 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[1]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img3 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[2]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img4 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[3]}`)} alt="Screenshot demonstrating functionality of the software"/> 
  const img5 = <img className={ tabletBreakpoint ? "carousel-screenshot img-larger top-margin-xs" : "carousel-screenshot top-margin-xs"} 
              src={require(`../images/${images[4]}`)} alt="Screenshot demonstrating functionality of the software"/>
  return (
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={false} menuShow={true} loggedIn={false}/>
      </div>

      <div className="container-simple">
        <section>
          <h1 className="florence-title top-margin-xl">O jeito fácil de construir melhores hábitos e cuidar da saúde</h1>
        </section>
      </div>

      <div className="top-margin-m bottom-margin-l">
        <div className={(tabletBreakpoint) ? 
        "colorful-background-tablet vertical-padding-m" : "colorful-background vertical-padding-m"}>
          <div className="container-simple">
            <LoginBlock />
          </div>
        </div>
      </div>

      <div className="container-simple">
        <section>

          <ProductSection 
            title="Alimentação e atividade física" 
            text="Acompanhe e desenvolva hábitos de alimentação e atividades físicas com a Florence. É simples, fácil, e se encaixa na sua rotina"
            img="habits-home.png"
            breakpoint={tabletBreakpoint}
          />

          <ProductSection 
            title="Portal de saúde" 
            text="Guarde seus dados de saúde e de sua família, acompanhe e tenha lembretes da tomada de medicação"
            img="health-home.png"
            breakpoint={tabletBreakpoint}
          />

          <ProductSection 
            title="Fórum" 
            text="Tire suas dúvidas sobre saúde e compartilhe sua experiência com outras pessoas"
            img="forum-home.png"
            breakpoint={tabletBreakpoint}
          />

          <Button type="primary bottom-margin-xxl" link={true} to='/registration'>Cadastre-se</Button>
        </section>
      </div>

      <Footer border="footer-no-right-border"/>
    </>
  )
}
