import React from 'react'
import EditIcon from '../images/edit.svg'
import Button from './Button'
import HighlightedMessage from './HighlightedMessage'
import Attention from "../images/sun.svg"

export default function EditableItemForCardDate({ 
  children, 
  edit=false,
  editUserField, 
  userField,
}) {

  const [ error, setError ] = React.useState(null)
  const [editing, setEditing] = React.useState(false)

  const [formData, setFormData] = React.useState({
    month: "", day: "", year: ""
  })

  function handleChange(e) {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()
    
    if (!(+formData.day)) {
      setError("Dia deve ser um número entre 1 e 31")
      return
    } else {
      if (+formData.day < 0) {
        setError("Dia deve ser um número entre 1 e 31")
        return
      }
      if (+formData.day > 31) {
        setError("Dia deve ser um número entre 1 e 31")
        return
      }
    }

    if (!(+formData.year)) {
      setError("Ano deve ser um número válido. Por exemplo: 1990")
      return
    } else {
      if (+formData.year < 1900 || +formData.year > 2100) {
        setError("Ano deve ser um número válido. Por exemplo: 1990")
        return
      }
    }

    editUserField(userField[0], `${formData.day}/${formData.month}/${formData.year}`)
    setEditing(false)
  }


  let editionForm = null
  if (edit) {
    editionForm = (
      <div className="row-select-birthdate">
        <select 
          className="birthday-input bottom-margin-xs"
          name="month"
          onChange={handleChange}
          value={formData.month}
        >
          <option name="month" value="">Mês</option>
          <option name="month" value="1">Janeiro</option>
          <option name="month" value="2">Fevereiro</option>
          <option name="month" value="3">Março</option>
          <option name="month" value="4">Abril</option>
          <option name="month" value="5">Maio</option>
          <option name="month" value="6">Junho</option>
          <option name="month" value="7">Julho</option>
          <option name="month" value="8">Agosto</option>
          <option name="month" value="9">Setembro</option>
          <option name="month" value="10">Outubro</option>
          <option name="month" value="11">Novembro</option>
          <option name="month" value="12">Dezembro</option>
        </select>

        <input
          className="birthday-input-text bottom-margin-xs"
          name="day"
          onChange={handleChange}
          type="text"
          placeholder="Dia"
          value={formData.day}
        />
        <input
          className="birthday-input-text bottom-margin-xs"
          name="year"
          onChange={handleChange}
          type="text"
          placeholder="Ano"
          value={formData.year}
        />
      </div>
    )
  }


  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">


      <div className='editable-item-for-card-header'>
        <div>{children}</div>
        <div className="row-svg-icons left-margin-xs">
          {(!editing && edit) ? 
              <button onClick={()=>{setEditing(prev=>!prev)}} className='carousel-btn'>
                <img className="" src={EditIcon}/>
              </button> : null }
        </div>
      </div>
  
      {(editing) ? 
      <form onSubmit={handleSubmit}>
        {(error) ?
          <div className="">
            <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
          </div>
          : null }
        <div className={`vertical-margin-xs`}>

          { editionForm } 
        </div>
        <div>
          <Button type="primary right-margin-s">Alterar</Button>
          <Button type="tertiary" onClick={()=>setEditing(false)}>Cancelar</Button> 
        </div>
      </form> : null}


    </div>
  )
}
