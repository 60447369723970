import React from 'react'
import Header from '../components/Header'
import RegistrationBlock from '../components/RegistrationBlock'

export default function AddFamilyMember() {

  return (

    <>
      <div className="container-simple">
        
        <Header tabletBreakpoint={false} menuItems="family" menuShow={true} loggedIn={false}/>
        
        <section>
          <h1 className="florence-title top-margin-xl">Adicionar novo membro à família</h1>
        </section>
      </div>

      <div className="colorful-background vertical-padding-m top-margin-m bottom-margin-l">
        <div className="container-simple">
          <RegistrationBlock newFamilyMember={true}/>
        </div>
      </div>
    </>
  )
}
