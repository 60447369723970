import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import Tag from '../Tag'
import PostLikes from './PostLikes'
import PostComments from './PostComments'
import SavedPost from './SavedPost'
import Author from './Author'
import CommentarySection from './CommentarySection'

import AuthContext from '../../context/AuthProvider'
import Button from '../Button'

export default function PostThumbnail({ post, id, searchTerm }) {

  const { cookies } = React.useContext(AuthContext);

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  let imageUrl = null
  if (post.hasImage) {
    imageUrl = window.URL.createObjectURL(post.image)
  }

  if (!post.content) {
    return null
  }

  return (
    <div className={(tabletBreakpoint) ? 
    "container-simple-tablet post-thumbnail top-padding-s bottom-padding-s":
    "container-simple post-thumbnail top-padding-s bottom-padding-s"}>
      <Author>{post}</Author>
      <Link className="thumbnail-link" to={`/forum/${id}`} state={{ search: searchTerm }} reloadDocument={true} >
        <p className="heading4 primary-color bottom-margin-xs">{post.title}</p>
        <p className="body primary-color content bottom-padding-xs">{(post.content.length > 1100) ? 
          post.content.slice(0,1100) + "..." : post.content }</p>
        {(post.hasImage) ? 
          <img className="post-image" src={imageUrl} alt="Post image"/>
          : null 
        }
      </Link>
      {(post.content.length > 1000) ? 
        <Button type="tertiary" link={true} to={`/forum/${id}`}>Continuar lendo</Button> :  null
      }
      {(post.tags.length > 0) ?
        <div className="tags-row bottom-padding-xs top-margin-xxs">
          {post.tags.map((tag, i) => <Tag key={i} color="blue">{tag}</Tag>)}
        </div> : null
      }
      <div className="post-stats bottom-padding-xs">
        <PostLikes postId={id} foundUseful={(cookies.get("userId") && post.voted_by) ? 
          post.voted_by.includes(cookies.get("userId")) : false} 
          qtyPeopleFoundUseful={(post.voted_by) ? post.voted_by.length : 0} />
        <PostComments qtyComments={post.comments_qty} /> 
      </div>

      <div className="top-margin-xs bottom-margin-xs">
        <CommentarySection postId={id} comments={post.comments} />
      </div>

      { (cookies.get("userId")) ?
      <SavedPost postId={id} savedBy={post.saved_by} /> : null
      }
    </div>
  )
}
