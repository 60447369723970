import React from 'react'
import PostThumbnail from './PostThumbnail'
import { ForumContext } from '../../forum/ForumData'
import AuthContext from '../../context/AuthProvider'

export default function Feed({ tabletBreakpoint, searchTerm, pageNumber, setPageNumber }) {

  const { 
    forumData, 
    loadForumData, 
    loading, 
    hasMore, 
    searchResults, 
    refreshFeed, 
    setRefreshFeed 
  } = React.useContext(ForumContext)
  const { cookies } = React.useContext(AuthContext);


  React.useEffect(()=>{
    if (refreshFeed) {
      setRefreshFeed(false)
      window.location.reload();
    }
  }, [refreshFeed])
  
  const observer = React.useRef()
  const lastPostElementRef = React.useCallback(node =>
    {
      if (loading) return
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1)
          console.log('visible!!!')
        }
      })
      if (node) observer.current.observe(node)

    }, [loading, hasMore])

  React.useEffect(()=>{
    if (!(cookies.get("userId"))) {
      loadForumData(false, pageNumber)
    } else {
      loadForumData(true, pageNumber)
    }
    //console.log(pageNumber)
  }, [pageNumber])

  if (!forumData) {
    return (
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <p>Carregando página...</p>
      </div>
    )
  }

  console.log(forumData)
  //console.log(hasMore)
  //

  const visibleItems = []
  const postsToRender = []

  forumData.posts.forEach(post => {
    if (visibleItems.includes(post.id)) {
      return null
    } else {
      visibleItems.push(post.id)
      postsToRender.push(post)
    }
  })

  if (postsToRender.length === 0) {
    return (
      <div className="icon-loading-partial top-margin-m">
        <img className="small-logo-nav loading-icon" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando post...</p> 
      </div>
    )
  }

  return (
    (!searchResults) ?
      postsToRender.map((post, index) => {
        if (postsToRender.length === index + 1) {
          return (
            <>
            <div ref={lastPostElementRef} key={index}>
              <PostThumbnail key={index} id={post.id} post={post} searchTerm={searchTerm}/>
            </div>
            {(loading) ? 
              <div className="icon-loading-partial top-margin-m">
                <img className="small-logo-nav loading-icon" 
                    src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
                <p>Carregando post...</p> 
              </div>
              : null
            }
            </>
          )
        } else {
          return <PostThumbnail key={index} id={post.id} post={post} searchTerm={searchTerm}/>
        }
      })
      :
      searchResults.map( post => 
        <PostThumbnail key={post.id} id={post.id} post={post} searchTerm={searchTerm}/>
      )
    )
}



