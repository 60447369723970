import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi, registerAction } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import { format, getDay, isToday } from "date-fns"
import SportBadminton from '../images/sport-badminton.svg'
import SportBasketball from '../images/sport-basketball.svg'
import SportBicycle from '../images/sport-bicycle.svg'
import SportBiker from '../images/sport-biker.svg'
import SportBodyboard from '../images/sport-bodyboard.svg'
import SportBowler from '../images/sport-bowler.svg'
import SportFootball from '../images/sport-football.svg'
import SportFrisbee from '../images/sport-frisbee.svg'
import SportGolf from '../images/sport-golf.svg'
import SportIceHockey from '../images/sport-ice-hockey.svg'
import SportIceSkater from '../images/sport-ice-skater.svg'
import SportLacrosse from '../images/sport-lacrosse.svg'
import SportPaddling from '../images/sport-paddling.svg'
import SportPingPong from '../images/sport-ping-pong.svg'
import SportRacket from '../images/sport-racket.svg'
import SportSkier from '../images/sport-skier.svg'
import SportSurfing from '../images/sport-surfing.svg'
import SportSwimmer from '../images/sport-swimmer.svg'
import SportTennis from '../images/sport-tennis.svg'
import SportVolleyball from '../images/sport-volleyball.svg'
import SportWaterpolo from '../images/sport-waterpolo.svg'
import MessageBroker from '../components/Submission/MessageBroker'
import ChooseDateBar from './ChooseDateBar'

export default function SubmitExercise() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const {
    exercise,
    setExercise,
    league,
    setLeague,
    sequenceLength,
    setExerciseHistory,
    setHighlightedExercise,
    evolutionBar,
    setEvolutionBar
  } = React.useContext(PortalContext)
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  
  const [imageIndex, setImageIndex] = React.useState(0)
  const [exerciseDate, setExerciseDate] = React.useState(null)
  const [dateOptions, setDateOptions] = React.useState(false)

  React.useEffect(() => {
    setImageIndex(Math.floor(Math.random() * 21))

    askForUpdatedMessage()
  
    if (location.state?.date) {
      setExerciseDate(location.state.date)
    } else {
      setExerciseDate(new Date())
    }
  }, [])

  const [formData, setFormData] = React.useState({description: ""})
  const [postImage, setPostImage] = React.useState(null)
  const [status, setStatus] = React.useState("idle")

  const [error, setError] = React.useState(null)

  const navigate = useNavigate()
  const location = useLocation()

  let backTo = '/profile'
  if (location.state?.prevPage) {
    backTo = location.state.prevPage
  }
  if (location.state?.search) {
    backTo = location.state.search;
  } 
  
  async function askForUpdatedMessage() {

    try {

      // call backend to get fresh message
      const requestBody = { id: +cookies.get("userId"), name: "exercise" }
      const data = await postRequestToApi(
        requestBody, 
        `/message`, 
        auth, 
        cookies
      )

      setExercise(prev => ({...prev, "message": data.message, "message_url": data.image_url }))
     
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

    } catch (err) {
      console.log("Error asking for updating message")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    }
  }

  async function submitPost() {

    if (formData.description === "" && postImage === null) {
      setError("É necessário adicionar uma imagem ou descrição")
      return
    }

    try {
      setStatus("submitting")
      
      const imageFormData = new FormData()
      imageFormData.append('exercise', postImage)

      let requestBody = null
      if (exerciseDate) {
        requestBody = {
          'description': formData.description,
          'date': exerciseDate //format(exerciseDate, "MM/dd/yyyy")
        }
      } else {
        requestBody = {
          'description': formData.description,
          'date': new Date()
        }
      }

      const data = await postRequestToApi(
        imageFormData, 
        `exercise/${cookies.get("userId")}`, 
        auth, 
        cookies, 
        true, 
        false, 
        JSON.stringify(requestBody)
      )

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
      
      if (postImage) {
        setHighlightedExercise({
          ...data, 
          created_at: requestBody.date,
          date: requestBody.date,
          hasImage: true,
          image: postImage
        })
        setExerciseHistory(prev => [...prev, {
          ...data, 
          created_at: requestBody.date,
          date: requestBody.date,
          hasImage: true,
          image: postImage
        }])
      } else {
        setHighlightedExercise({
          ...data, 
          created_at: requestBody.date, 
          date: requestBody.date,
          hasImage: false, 
          image: null
        })
        setExerciseHistory(prev => [...prev, {
          ...data, 
          created_at: requestBody.date, 
          date: requestBody.date,
          hasImage: false, 
          image: null
        }])

      }

      const updatedCount = evolutionBar[format(requestBody.date, "MM/dd/yyyy")]
      updatedCount.super_healthy += 1
      updatedCount.total += 1
      setEvolutionBar(prev => ({...prev, [format(requestBody.date, "MM/dd/yyyy")]: updatedCount }))

      const historyUpdated = exercise.history
      historyUpdated.push({date: format(requestBody.date, "MM/dd/yyyy"), id: data.id})
      setExercise(prev => ({...prev, history: historyUpdated}))

      let updatedLeague = league.league_rank
      
      if (league.is_in_current_league === true) {
        updatedLeague = updatedLeague.map(item => {
          if (item.user_id === +cookies.get("userId")) {
            item.score += 10
            return item
          } else {
            return item
          }
        })
      } else {
        updatedLeague.push({
          user_id: cookies.get("userId"),
          first_name: cookies.get("userName"),
          last_name: cookies.get("userLastName"),
          score: 10
        })
      }

      setLeague(prev => ({...prev, league_rank: updatedLeague, is_in_current_league: true, last_gain: 10}))

    } catch (err) {
      console.log("Error submitting exercise")
      setStatus(err)
      navigate('/profile', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    } finally {
      setStatus("complete")
      //navigate('/exercise/highlight', { state: { message: "Seu exercício físico foi submetida com sucesso, e em alguns instantes ele aparecerá nessa página" }})
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    //console.log('submitting')
    submitPost()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  function handleImageChange(e) {
    const target = e.target

    setPostImage(target.files[0])
  }
  
  let imageUrl = null
  if (postImage) {
    imageUrl = window.URL.createObjectURL(postImage)
  }

  React.useEffect(() => {
    if (!cookies.get("userId")) {
      navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
    }
    registerAction(+cookies.get("userId"), "exercisesubmit", auth, cookies)
  }, [])
  
  if (status !== "idle") {
    return (
      <MessageBroker status={status} submitionItem="exercício" itemDate={exerciseDate} />
    )
  }
  
  const toggleModifyDate = () => {
    setDateOptions(prev => !prev)
    registerAction(+cookies.get("userId"), "toggleModifyDate", auth, cookies)
  }
  
  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to={backTo}/>
      
      <div className="submit-column">
        <div className="top-margin-xl bottom-margin-l">
          <div style={{textAlign: "center"}}>
            {(exerciseDate) ?
              <p className="body"><strong>
                {`Adicionar exercício · ${(isToday(exerciseDate)) ? "Hoje" : format(exerciseDate, "dd/MM")}`}
              </strong> · <button className="body btn-tertiary" onClick={()=>{toggleModifyDate()}}>Alterar</button></p> :
              <p className="heading5"><strong>Adicionar exercício</strong></p>
            }

            {(dateOptions) ? 
              <ChooseDateBar activityDate={exerciseDate} setActivityDate={setExerciseDate} />
              : null
            }
          </div>

          {(error) ?
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null }
        </div> 
        
        <div>
          {(imageUrl) ? <img className="submit-image-preview" src={imageUrl} alt="Submission image"/> :
          (imageIndex === 0) ? <img className="submission-image" src={SportBicycle} alt="Submission image"/> :
          (imageIndex === 1) ? <img className="submission-image" src={SportBiker} alt="Submission image"/> :
          (imageIndex === 2) ? <img className="submission-image" src={SportBodyboard} alt="Submission image"/> :
          (imageIndex === 3) ? <img className="submission-image" src={SportBowler} alt="Submission image"/> :
          (imageIndex === 4) ? <img className="submission-image" src={SportFootball} alt="submission image"/> :
          (imageIndex === 5) ? <img className="submission-image" src={SportBadminton} alt="submission image"/> :
          (imageIndex === 6) ? <img className="submission-image" src={SportBasketball} alt="submission image"/> :
          (imageIndex === 7) ? <img className="submission-image" src={SportGolf} alt="submission image"/> :
          (imageIndex === 8) ? <img className="submission-image" src={SportSkier} alt="submission image"/> :
          (imageIndex === 9) ? <img className="submission-image" src={SportRacket} alt="submission image"/> :
          (imageIndex === 10) ? <img className="submission-image" src={SportTennis} alt="submission image"/> :
          (imageIndex === 11) ? <img className="submission-image" src={SportFrisbee} alt="submission image"/> :
          (imageIndex === 12) ? <img className="submission-image" src={SportSurfing} alt="submission image"/> :
          (imageIndex === 13) ? <img className="submission-image" src={SportSwimmer} alt="submission image"/> :
          (imageIndex === 14) ? <img className="submission-image" src={SportLacrosse} alt="submission image"/> :
          (imageIndex === 15) ? <img className="submission-image" src={SportPaddling} alt="submission image"/> :
          (imageIndex === 16) ? <img className="submission-image" src={SportPingPong} alt="submission image"/> :
          (imageIndex === 17) ? <img className="submission-image" src={SportIceHockey} alt="submission image"/> :
          (imageIndex === 18) ? <img className="submission-image" src={SportIceSkater} alt="submission image"/> :
          (imageIndex === 19) ? <img className="submission-image" src={SportWaterpolo} alt="submission image"/> :
          <img className="submission-image" src={SportVolleyball} alt="submission image"/> }
        </div>

        <form className="top-margin-l form-food-exercise-submit" onSubmit={handleSubmit}>
        
          <Button type="secondary bottom-margin-l" buttonType="button">
            <label for="upload-photo">Selecionar ou tirar foto</label>
          </Button>

          <div>
            <input type="file"
              className="bottom-margin-s"
              id="upload-photo" 
              name="image" 
              accept="image/png, image/gif, image/jpeg, image/jpg" 
              onChange={handleImageChange}
              />
          </div>
        
          <textarea className="submit-exercise-food-form" 
            type="text" 
            name="description" 
            placeholder="Ou você pode adicionar uma descrição (ou fazer os dois!)" 
            onChange={handleChange} 
            value={formData.description}/>
          
          <Button type="primary top-margin-s bottom-margin-m">{(status==="idle") ? "Enviar" : "Enviando..."}</Button>
        </form>
      </div>
    </div>
  )
}

//
