import React from 'react'
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import Return from '../Forum/Return'
import EnergyExpenditureCard from './EnergyExpenditureCard'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import EditableItemsCard2 from '../EditableItemsCard2'
import Button from '../Button'
import { postRequestToApi, registerAction } from '../../api'

export default function WeightLossObjective() {
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { 
    health,
    loadHealthDataFromAPI,
    loadEatingDataFromAPI,
    eating,
    setEating
  } = React.useContext(PortalContext);
  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [status, setStatus] = React.useState('idle')
  
  const navigate = useNavigate()
  
  React.useEffect(() => {
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/profile', { path: '/' })
      navigate("/family")
    } else {
      if (!health[+cookies.get("userid")]) {
        loadHealthDataFromAPI(+cookies.get("userId"))
      }
      if (!eating) {
        loadEatingDataFromAPI()
      }
    }
    registerAction(+cookies.get("userId"), `weightlossfoodobjectivepage`, auth, cookies)
  }, []);
  
  async function submitUpdatedData(bodyRequest) {
    try {
      setStatus("submitting")
      const data = await postRequestToApi(bodyRequest, '/food/goals', auth, cookies)

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      let prevGoals = eating.goals
      prevGoals = prevGoals.filter(item => (item.manually_added === true))
      const updatedGoals = prevGoals.concat(goals);
      setEating(prev => ({ ...prev, goals: updatedGoals }))

      registerAction(+cookies.get("userId"), `addweightlossobjective`, auth, cookies)
      setStatus("idle")
      navigate('/profile/objectives')
    }
  }

  const addObjectives = () => {
    const requestBody = {
        "proteinTitle": "Consumo diário de proteínas",
        "caloryTitle": "Consumo diário de calorias",
        "proteinContent": proteinsGoal,
        "caloryContent": tdeeGoal
    }

    //const goalsUpdated = eating.goals
    //goalsUpdated.push(...goals)
    //setEating(prev => ({...prev, goals: goalsUpdated})) 

    submitUpdatedData({
      id: +cookies.get("userId"), 
      exam_data: requestBody
    })

  }

  if (!health[+cookies.get("userId")]?.first_name || !eating?.sex) {
      return (
        <div className="icon-loading-middle-page">
          <img className="small-logo-nav loading-icon" 
              src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
          <p>Carregando página...</p> 
        </div>
      )
  }

  let tdeeGoal = null
  let proteinsGoal = null
  let goals = null

  let objectiveProposal = null
  if (health[+cookies.get("userId")]?.tdee || health[+cookies.get("userId")].tdee !== null) {
    const id = +cookies.get("userId")

    tdeeGoal = Math.round(0.8 * +health[id].tdee)

    const healthyWeightInKg = 25 * (+health[id].height) ** 2 // BMI of 25
    const healthyWeightInPounds = healthyWeightInKg * 2.205

    proteinsGoal = Math.round(healthyWeightInPounds)
    
    goals = [
      {
        "title": "Consumo diário de calorias",
        "content": `Até ${Math.round(0.8 * +health[id].tdee)} calorias`,
        "manually_added": false,
      },
      {
        "title": "Consumo diário de proteínas",
        "content": `Cerca de ${Math.round(healthyWeightInPounds)} gramas`,
        "manually_added": false,
      }
    ]

    objectiveProposal = (
      <div>
        <p className="body">
          <strong>Para perder peso, um bom início é consumir até </strong>{tdeeGoal} calorias por dia 
          (20% a menos do que você gasta)
        </p>
        <p className="secondary-text">
          Comparando, um prato de arroz com feijão tem cerca de 450 calorias
        </p>
        <p className="body top-margin-s">
          <strong>Uma boa ideia também é consumir cerca de </strong>{proteinsGoal} gramas de proteína diariamente
        </p>
        <p className="secondary-text bottom-margin-s">
          Proteínas são usadas na construção dos músculos, ajudam a ganhar massa magra, aceleram o metabolismo e dão sensação de saciatez
        </p>
        <EditableItemsCard2 
          clsName="bottom-margin-s" 
          title="Objetivos para perda de peso"
          icons={false}
        >
          {goals.map((item,i) => (
            <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
              <div className='editable-item-for-card-header'>
                <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p>
              </div>
            </div>
          ))}
        </EditableItemsCard2>

        <Button type="primary bottom-margin-xl" onClick={()=>{addObjectives()}}>
          {(status==="idle") ? "Adicionar objetivos" : "Adicionando..." }
        </Button>
      </div> 
    )
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to="/profile/objectives" />
      <p className="heading3 top-margin-m"><strong>Perda de peso</strong></p>
      <p className="body top-margin-s bottom-margin-s">
        Uma estratégia para perder peso é chamada <strong>déficit calórico: </strong>
        Consumindo menos calorias do que você gasta ao longo do dia, você emagrece.
      </p>

      <div className="bottom-margin-s">
        <EnergyExpenditureCard />
      </div>

      {(health[+cookies.get("userId")]?.tdee || health[+cookies.get("userId")].tdee !== null) ? 
        objectiveProposal : 
        null 
      }
    </div>
  )
}
