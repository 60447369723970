import React from 'react'
import CheckItem from '../images/check.svg'
import Button from './Button'

export default function ClickableItemForCard({ children, colour, button=false, onClick, listId}) {

  return (
    <div className={(colour) ? 
      `editable-item-for-card-${colour} top-margin-xs vertical-padding-xs left-padding-s right-padding-xs`:
      "editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"}>

      <div className='editable-item-for-card-header-center'>
        <div>{children}</div>
        {(button) ? 
          <Button type="secondary left-margin-xs right-margin-xs" onClick={()=>onClick(listId)}>
            Confirmar
          </Button>
        : null }
      </div>
  
    </div>
  )
}
          //<div className="confirm-button-header">
          //  <p className="body secondary-color">Confirmar</p>
          //  <img className="" src={CheckItem}/>
          //</div>
