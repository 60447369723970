import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import Header from "../components/Header"
import Button from '../components/Button';
import { PortalContext } from '../AuthenticatedUser'
import AuthContext from "../context/AuthProvider"
import Avatar from '../components/Avatar';
import { deleteDataFromApi } from '../api'

export default function ManageFamily() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { family, owner } = React.useContext(PortalContext)

  const { auth, setAuth, cookies } = React.useContext(AuthContext)

  const [deleteId, setDeleteId] = React.useState(null)

  const navigate = useNavigate()

  const onRemoveClick = (id) => {
    if (deleteId !== id) {
      setDeleteId(id)
    } else {
      setDeleteId(null)
    }
  }
  
  async function deleteUser(body) {
    try {
      console.log(body)
      //const data = await getDataFromApi('myprofile', auth, cookies)
      const data = await deleteDataFromApi(body, 'family', auth, cookies)
      setDeleteId(null)

      console.log("user deleted")
      window.location.reload();

      setAuth(prev => ({...prev, ...data.tokens}))
    } catch (err) {
      console.log("Could not delete user")
      console.log(err)
    }
  }

  const onConfirmDelete = (id) => {
    console.log("confirm delete")
    console.log(id)
    deleteUser({"id": id})
  }

  const accessUser = (id, user, lastName) => {
    cookies.set("userId", id, { path: '/' })
    cookies.set("userName", user, { path: '/' })
    cookies.set("userLastName", lastName, { path: '/' })
    navigate("/profile")
  }

  const familyMemberRows = (family) ? family.map(({id, name, last_name, role}) => {
    return (
      <div key={id} className="avatar-block">
        <div className="avatar-row vertical-padding-xs">
          <div className="avatar-icon-name">
          <Avatar user={name} size="small" color="avatar-blue" />
          <p className="body">{name}</p>
          </div>
          <div>
            {(owner && role !== "owner") ?
              <Button type="tertiary right-margin-s" onClick={() => {onRemoveClick(id)}} id={id}>
                {(deleteId) ? "Cancelar" : "Remover"}
              </Button> : null}
              <Button type="primary" onClick={()=>accessUser(id, name, last_name)}>Acessar</Button>
          </div>
        </div>
        { (deleteId === id) ?
          <div className="editable-card bottom-padding-s right-padding-s top-margin-xs bottom-margin-m">
            <div className="editable-card-title top-padding-s left-margin-s right-padding-xs">
              <p className="heading5">Você deseja remover esse membro da família?</p>
            </div>

            <div className="editable-item-for-card-red top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
              <div>
                  <p className="secondary-text bottom-margin-s">Essa ação não pode ser desfeita, e todas as informações de saúde referentes
                  a essa pessoa serão perdidas. Você deseja prosseguir?</p>
                  <Button type="tertiary" onClick={() => {onConfirmDelete(id)}}>Sim, quero remover esse membro da família</Button>
              </div>
            </div>
          </div>
          : null }
      </div>
    )
  }) : null

  const content = (
      <section>
        <h1 className="florence-title top-margin-xl bottom-margin-l">Gerenciar família</h1>

        <div>
          {familyMemberRows}
        </div>
        <Button type="secondary top-margin-xs" link={true} to="/family/add">Adicionar familiar</Button>
      </section>
  )

  return (
    <>
      <div className={ tabletBreakpoint ?  "container-simple-tablet" : "container-simple" }>
        <Header tabletBreakpoint={false} menuItems="family" menuShow={true} loggedIn={false}/>

        {content}

      </div>
    </>
  )
}
