import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import { useNavigate } from "react-router-dom"
import Button from "../Button"
import { deleteDataFromApi, postRequestToApi, registerAction } from "../../api"
import AuthContext from "../../context/AuthProvider"

export default function WeeklyPlanMessage({ clickNext }) {
  
  const navigate = useNavigate()

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { eating, setEating } = React.useContext(PortalContext)

  const [status, setStatus] = React.useState("idle")
  
  const [monday, setMonday] = React.useState(false)
  const [mondayMorning, setMondayMorning] = React.useState(false)
  const [mondayAfternoon, setMondayAfternoon] = React.useState(false)
  const [mondayEvening, setMondayEvening] = React.useState(false)

  const [tuesday, setTuesday] = React.useState(false)
  const [tuesdayMorning, setTuesdayMorning] = React.useState(false)
  const [tuesdayAfternoon, setTuesdayAfternoon] = React.useState(false)
  const [tuesdayEvening, setTuesdayEvening] = React.useState(false)

  const [wednesday, setWednesday] = React.useState(false)
  const [wednesdayMorning, setWednesdayMorning] = React.useState(false)
  const [wednesdayAfternoon, setWednesdayAfternoon] = React.useState(false)
  const [wednesdayEvening, setWednesdayEvening] = React.useState(false)

  const [thursday, setThursday] = React.useState(false)
  const [thursdayMorning, setThursdayMorning] = React.useState(false)
  const [thursdayAfternoon, setThursdayAfternoon] = React.useState(false)
  const [thursdayEvening, setThursdayEvening] = React.useState(false)

  const [friday, setFriday] = React.useState(false)
  const [fridayMorning, setFridayMorning] = React.useState(false)
  const [fridayAfternoon, setFridayAfternoon] = React.useState(false)
  const [fridayEvening, setFridayEvening] = React.useState(false)

  const [saturday, setSaturday] = React.useState(false)
  const [saturdayMorning, setSaturdayMorning] = React.useState(false)
  const [saturdayAfternoon, setSaturdayAfternoon] = React.useState(false)
  const [saturdayEvening, setSaturdayEvening] = React.useState(false)

  const [sunday, setSunday] = React.useState(false)
  const [sundayMorning, setSundayMorning] = React.useState(false)
  const [sundayAfternoon, setSundayAfternoon] = React.useState(false)
  const [sundayEvening, setSundayEvening] = React.useState(false)

  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "weeklyPlanMessagePage", auth, cookies)
  }, [])

  const createPlans = () => {

    const exercises = []
    if (monday) {
      if (mondayMorning) {
        if (eating.plan.filter(prev => prev.title === "Exercício segunda de manhã").length === 0) {
          exercises.push({title: "Exercício segunda de manhã"})
        }
      }
      if (mondayAfternoon) {
        if (eating.plan.filter(prev => prev.title === "Exercício segunda de tarde").length === 0) {
          exercises.push({title: "Exercício segunda de tarde"})
        }
      }
      if (mondayEvening) {
        if (eating.plan.filter(prev => prev.title === "Exercício segunda de noite").length === 0) {
          exercises.push({title: "Exercício segunda de noite"})
        }
      }
    }
    if (tuesday) {
      if (tuesdayMorning) {
        if (eating.plan.filter(prev => prev.title === "Exercício terça de manhã").length === 0) {
          exercises.push({title: "Exercício terça de manhã"})
        }
      }
      if (tuesdayAfternoon) {
        if (eating.plan.filter(prev => prev.title === "Exercício terça de tarde").length === 0) {
          exercises.push({title: "Exercício terça de tarde"})
        }
      }
      if (tuesdayEvening) {
        if (eating.plan.filter(prev => prev.title === "Exercício terça de noite").length === 0) {
          exercises.push({title: "Exercício terça de noite"})
        }
      }
    }
    if (wednesday) {
      if (wednesdayMorning) {
        if (eating.plan.filter(prev => prev.title === "Exercício quarta de manhã").length === 0) {
          exercises.push({title: "Exercício quarta de manhã"})
        }
      }
      if (wednesdayAfternoon) {
        if (eating.plan.filter(prev => prev.title === "Exercício quarta de tarde").length === 0) {
          exercises.push({title: "Exercício quarta de tarde"})
        }
      }
      if (wednesdayEvening) {
        if (eating.plan.filter(prev => prev.title === "Exercício quarta de noite").length === 0) {
          exercises.push({title: "Exercício quarta de noite"})
        }
      }
    }
    if (thursday) {
      if (thursdayMorning) {
        if (eating.plan.filter(prev => prev.title === "Exercício quinta de manhã").length === 0) {
          exercises.push({title: "Exercício quinta de manhã"})
        }
      }
      if (thursdayAfternoon) {
        if (eating.plan.filter(prev => prev.title === "Exercício quinta de tarde").length === 0) {
          exercises.push({title: "Exercício quinta de tarde"})
        }
      }
      if (thursdayEvening) {
        if (eating.plan.filter(prev => prev.title === "Exercício quinta de noite").length === 0) {
          exercises.push({title: "Exercício quinta de noite"})
        }
      }
    }
    if (friday) {
      if (fridayMorning) {
        if (eating.plan.filter(prev => prev.title === "Exercício sexta de manhã").length === 0) {
          exercises.push({title: "Exercício sexta de manhã"})
        }
      }
      if (fridayAfternoon) {
        if (eating.plan.filter(prev => prev.title === "Exercício sexta de tarde").length === 0) {
          exercises.push({title: "Exercício sexta de tarde"})
        }
      }
      if (fridayEvening) {
        if (eating.plan.filter(prev => prev.title === "Exercício sexta de noite").length === 0) {
          exercises.push({title: "Exercício sexta de noite"})
        }
      }
    }
    if (saturday) {
      if (saturdayMorning) {
        if (eating.plan.filter(prev => prev.title === "Exercício sábado de manhã").length === 0) {
          exercises.push({title: "Exercício sábado de manhã"})
        }
      }
      if (saturdayAfternoon) {
        if (eating.plan.filter(prev => prev.title === "Exercício sábado de tarde").length === 0) {
          exercises.push({title: "Exercício sábado de tarde"})
        }
      }
      if (saturdayEvening) {
        if (eating.plan.filter(prev => prev.title === "Exercício sábado de noite").length === 0) {
          exercises.push({title: "Exercício sábado de noite"})
        }
      }
    }
    if (sunday) {
      if (sundayMorning) {
        if (eating.plan.filter(prev => prev.title === "Exercício domingo de manhã").length === 0) {
          exercises.push({title: "Exercício domingo de manhã"})
        }
      }
      if (sundayAfternoon) {
        if (eating.plan.filter(prev => prev.title === "Exercício domingo de tarde").length === 0) {
          exercises.push({title: "Exercício domingo de tarde"})
        }
      }
      if (sundayEvening) {
        if (eating.plan.filter(prev => prev.title === "Exercício domingo de noite").length === 0) {
          exercises.push({title: "Exercício domingo de noite"})
        }
      }
    }
    if (exercises.length > 0) {

      let plansUpdated = eating.plan
      plansUpdated = plansUpdated.concat(exercises)
      
      setEating(prev => ({...prev, plan: plansUpdated}))
      
      const bodyRequest = { id: +cookies.get("userId"), exam_data: exercises }

      postListOfPlans(bodyRequest)

    } else {
      return
    }
  }

  async function postListOfPlans(bodyRequest) {

    try {

      setStatus("submitting")

      const data = await postRequestToApi(
        bodyRequest, 
        `/food/planlist`, 
        auth, 
        cookies
      )

      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

    } catch (err) {
      console.log("Error asking for updating message")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    } finally {
      setStatus("idle")
    }
  }

  const clickToContinue = () => {

    createPlans()

    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    registerAction(+cookies.get("userId"), "WeeklyPlanMessageContinue", auth, cookies)

    clickNext()
  }

  const clickToContinueWithoutAction = () => {
    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))

    registerAction(+cookies.get("userId"), "WeeklyPlanMessageContinueWithoutAction", auth, cookies)

    clickNext()
  }
 
  return (
    <div className={"container-simple"}>
      <div className="top-margin-m icon-center">
        <img className="medium-logo" 
            src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4 bottom-margin-xs">Qual é o plano?</p> 
        <p class="body bottom-margin-m">
          Quais dias você vai se exercitar essa semana?
        </p>
      </div>

      <div className="tags-vertical-menu top-margin-xs bottom-margin-s">
        <button className={(monday === true) ? "tag-selected":"tag-filter"}
          onClick={()=>setMonday(prev=>!prev)}>Segunda</button>
        {(monday) ?
          <div className="tags-horizontal-menu">
            <button className={(mondayMorning === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setMondayMorning(prev=>!prev)}>Manhã</button>
            <button className={(mondayAfternoon === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setMondayAfternoon(prev=>!prev)}>Tarde</button>
            <button className={(mondayEvening === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setMondayEvening(prev=>!prev)}>Noite</button>
          </div> : null
        }
      </div>
      <div className="tags-vertical-menu top-margin-xs bottom-margin-s">
        <button className={(tuesday === true) ? "tag-selected":"tag-filter"}
          onClick={()=>setTuesday(prev=>!prev)}>Terça</button>
        {(tuesday) ?
          <div className="tags-horizontal-menu">
            <button className={(tuesdayMorning === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setTuesdayMorning(prev=>!prev)}>Manhã</button>
            <button className={(tuesdayAfternoon === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setTuesdayAfternoon(prev=>!prev)}>Tarde</button>
            <button className={(tuesdayEvening === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setTuesdayEvening(prev=>!prev)}>Noite</button>
          </div> : null
        }
      </div>
      <div className="tags-vertical-menu top-margin-xs bottom-margin-s">
        <button className={(wednesday === true) ? "tag-selected":"tag-filter"}
          onClick={()=>setWednesday(prev=>!prev)}>Quarta</button>
        {(wednesday) ?
          <div className="tags-horizontal-menu">
            <button className={(wednesdayMorning === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setWednesdayMorning(prev=>!prev)}>Manhã</button>
            <button className={(wednesdayAfternoon === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setWednesdayAfternoon(prev=>!prev)}>Tarde</button>
            <button className={(wednesdayEvening === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setWednesdayEvening(prev=>!prev)}>Noite</button>
          </div> : null
        }
      </div>
      <div className="tags-vertical-menu top-margin-xs bottom-margin-s">
        <button className={(thursday === true) ? "tag-selected":"tag-filter"}
          onClick={()=>setThursday(prev=>!prev)}>Quinta</button>
        {(thursday) ?
          <div className="tags-horizontal-menu">
            <button className={(thursdayMorning === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setThursdayMorning(prev=>!prev)}>Manhã</button>
            <button className={(thursdayAfternoon === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setThursdayAfternoon(prev=>!prev)}>Tarde</button>
            <button className={(thursdayEvening === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setThursdayEvening(prev=>!prev)}>Noite</button>
          </div> : null
        }
      </div>
      <div className="tags-vertical-menu top-margin-xs bottom-margin-s">
        <button className={(friday === true) ? "tag-selected":"tag-filter"}
          onClick={()=>setFriday(prev=>!prev)}>Sexta</button>
        {(friday) ?
          <div className="tags-horizontal-menu">
            <button className={(fridayMorning === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setFridayMorning(prev=>!prev)}>Manhã</button>
            <button className={(fridayAfternoon === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setFridayAfternoon(prev=>!prev)}>Tarde</button>
            <button className={(fridayEvening === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setFridayEvening(prev=>!prev)}>Noite</button>
          </div> : null
        }
      </div>
      <div className="tags-vertical-menu top-margin-xs bottom-margin-s">
        <button className={(saturday === true) ? "tag-selected":"tag-filter"}
          onClick={()=>setSaturday(prev=>!prev)}>Sábado</button>
        {(saturday) ?
          <div className="tags-horizontal-menu">
            <button className={(saturdayMorning === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setSaturdayMorning(prev=>!prev)}>Manhã</button>
            <button className={(saturdayAfternoon === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setSaturdayAfternoon(prev=>!prev)}>Tarde</button>
            <button className={(saturdayEvening === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setSaturdayEvening(prev=>!prev)}>Noite</button>
          </div> : null
        }
      </div>
      <div className="tags-vertical-menu top-margin-xs bottom-margin-s">
        <button className={(sunday === true) ? "tag-selected":"tag-filter"}
          onClick={()=>setSunday(prev=>!prev)}>Domingo</button>
        {(sunday) ?
          <div className="tags-horizontal-menu">
            <button className={(sundayMorning === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setSundayMorning(prev=>!prev)}>Manhã</button>
            <button className={(sundayAfternoon === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setSundayAfternoon(prev=>!prev)}>Tarde</button>
            <button className={(sundayEvening === true) ? "tag-orange-selected":"tag-orange"}
              onClick={()=>setSundayEvening(prev=>!prev)}>Noite</button>
          </div> : null
        }
      </div>


      <div className="top-margin-m bottom-margin-l" style={{textAlign: 'right'}}>
        <Button type="primary" onClick={()=>{clickToContinue()}}>{(status==="idle") ? "Próximo" : "Enviando"}</Button>
        <Button type="tertiary left-margin-xs" onClick={()=>{clickToContinueWithoutAction()}}>Agora não</Button>
      </div>
    </div>
  )
}
