import React from 'react';

export default function Ranking({ title, participants, userId, userPosition, userScore, userFirstName, userLastName, icon }) {

  let userTopTen = false

  return(
    <div className="datepicker">
      <div className="ranking-header">
        <p className="body month-name">{title}</p>
        <img src={icon} alt="Pontuação de reputação"/>
      </div>
      {participants.map((participant,i) => {
        if (participant[0] === userId) {
          userTopTen = true
          return (
            <div className="ranking-row" id={participant[0]} key={participant[0]}>
              <div className="ranking-position-name">
                <p className={(i>2) ? "body ranking-pos month-name" : `body ranking-pos-${i}`}>
                  {i+1}
                </p>
                <p className="body month-name">
                  {`${participant[2]} ${participant[3]}`}  
                </p>
              </div>
              <p className="body month-name">
                {participant[1]}
              </p>
            </div>  
        )

        } else {
          return (
            <div className="ranking-row" id={participant[0]} key={participant[0]}>
              <div className="ranking-position-name">
                <p className={(i>2) ? "body ranking-pos" : `body ranking-pos-${i}`}>
                  {i+1}
                </p>
                <p className="body">
                  {`${participant[2]} ${participant[3]}`}  
                </p>
              </div>
              <p className="body">
                {participant[1]}
              </p>
            </div>  
        )
      }
      })}
      {(!userTopTen) ?
        <div className="ranking-row" id={userId} key={userId}>
          <div className="ranking-position-name">
            <p className="body ranking-pos month-name">
              {userPosition}
            </p>
            <p className="body month-name">
              {`${userFirstName} ${userLastName}`}  
            </p>
          </div>
          <p className="body month-name">
            {userScore}
          </p>
        </div>  
        : null
      }
    </div>
  )
}
